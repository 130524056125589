import { useState, useEffect, Fragment, useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Modal from "../components/Modal";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { getImageUrl } from "../components/utils";
import { MetaPayload, useAllMeta } from "../hooks/meta";
import CreateMeta from "../components/CreateMeta";
import EditMeta from "../components/EditMeta";

const defaultTypeOption = [
    "TREK",
    "ADVENTURE",
    "EXPEDITION",
    "HOLIDAY",
    "INDEX"
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Meta() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const [openCreateForm, setOpenCreateForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [getUploadTrekId, setUploadTrekId] = useState(0);

    const [getTrekId, setTrekId] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(8);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [metaEntries, setMetaEntries] = useState<MetaPayload[]>([]);
    const [typeOption, setTypeOption] = useState<string[]>([]);

    const [previewTrekId, setPreviewTrekId] = useState(0);

    const [editMeta, setEditMeta] = useState<MetaPayload>({
        id: 0,
        type: "",
        title: "",
        description: "",
        keywords: "",
        image: ""
    });

    const { data, isLoading, isError, error } = useAllMeta()

    useEffect(() => {
        if (data) {
            setMetaEntries(data.meta)
            const existingTypeOptions = data.meta.map((m: MetaPayload) => m.type)
            const newMetaTypeOptions: string[] = [];
            defaultTypeOption.map(type => {
                if (!existingTypeOptions.includes(type)) newMetaTypeOptions.push(type)
            })
            setTypeOption(newMetaTypeOptions)
        }
    }, [data])

    const openPreview = (imageUrl: string) => {
        setModal(true);
        setPreviewUrl(imageUrl)
    }

    const closePreview = () => {
        setModal(false);
        setPreviewUrl("")
    }

    const handleEditClick = (event: any, meta: MetaPayload) => {
        event.preventDefault();
        setOpenEditForm(true);
        const formValues = {
            id: meta.id,
            type: meta.type,
            title: meta.title,
            description: meta.description,
            keywords: meta.keywords,
            image: meta.image
        };
        setEditMeta(formValues);
    };

    if (isLoading) {
        return <span>Loading...</span>
    }

    if (isError) {
        return <span>error...</span>
    }

    return (<>
        <div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Meta</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Meta title, description and keywords for index pages
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => setOpenCreateForm(true)}
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Add Meta
                        </button>
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            <div className="group inline-flex">
                                                ID
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Type
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Title
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Description
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Keywords
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Image
                                            </div>
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {metaEntries && metaEntries.length > 0 && metaEntries.map((meta: MetaPayload) => (
                                        <Fragment key={meta.id}>
                                            <tr key={meta.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {meta.id}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{meta.type}</td>
                                                <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{meta.title}</td>
                                                <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{meta.description}</td>
                                                <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{meta.keywords}</td>
                                                <td className="relative whitespace-nowrap min-w-[100px] py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <div onClick={() => openPreview(meta.image)} className={classNames("text-black", meta.image ? "cursor-pointer" : "cursor-default")}>
                                                        {
                                                            meta.image ?
                                                                <img src={getImageUrl(meta.image)} alt={meta.type} className="h-12 w-12" />
                                                                : "No Image"
                                                        }
                                                        <span className="sr-only">, {meta.type}</span>
                                                    </div>
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a href="#" onClick={(event) => handleEditClick(event, meta)} className="text-indigo-600 hover:text-indigo-900">
                                                        Edit<span className="sr-only">, {meta.type}</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <Modal open={modal} setModal={closePreview}>
                                                {previewUrl && <a href={previewUrl} target="_blank"><img src={getImageUrl(previewUrl)} alt="preview img" /></a>}
                                            </Modal>
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                            {metaEntries && metaEntries.length === 0 && <div className="flex w-full align-center justify-center">
                                <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No meta entries found</p>
                            </div>}
                        </div>
                    </div>
                </div>
                <CreateMeta open={openCreateForm} setOpen={setOpenCreateForm} typeOption={typeOption}></CreateMeta>
                <EditMeta open={openEditForm} setOpen={setOpenEditForm} metaEntry={editMeta}></EditMeta>
            </div>
        </div>
    </>)
}