import { Route, Routes } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import AppLayout from "./components/AppLayout";
import Bookings from "./pages/Bookings";
import CustomizedTrek from "./pages/CustomisedTreks";
import Login from "./pages/Login"
import Schedules from "./pages/Schedules";
import Treks from "./pages/Treks";
import Users from "./pages/Users";
import Inventory from "./pages/Inventory";
import Featured from "./pages/Featured";
import FeaturedHolidays from "./pages/FeaturedHolidays";
import SocialFeeds from "./pages/SocialFeed";
import TravelCards from "./pages/TravelCards";
import Trainers from "./pages/Trainers";
import Enquiries from "./pages/Enquiries";
import TrekInventory from "./pages/TrekInventory";
import Coupons from "./pages/Coupons";
import LocationPrices from "./pages/LocationPrices";
import { AppLoader } from "./components/AppLoader";
import NotificationBanner from "./components/NotificationBanner";
import Certificates from "./pages/Certificates";
import Adventures from "./pages/Adventures";
import Expeditions from "./pages/Expeditions";
import Holidays from "./pages/Holidays";
import Meta from "./pages/Meta";

const Router = () => {
  return (
    <>
      <AppLoader />
      <NotificationBanner />
      <Routes>
        <Route element={<AppLayout />}>         
          <Route path="/login" element={<Login />} />          
        </Route>
        <Route path="/" element={<AdminLayout />}>
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/treks" element={<Treks />} />
          <Route path="/adventures" element={<Adventures />} />
          <Route path="/expeditions" element={<Expeditions />} />
          <Route path="/holidays" element={<Holidays />} />
          <Route path="/metaentries" element={<Meta />} />
          <Route path="/customisedtrek" element={<CustomizedTrek />} />
          <Route path="/users" element={<Users />} />
          <Route path="/schedules" element={<Schedules />} />
          <Route path="/trekinventory" element={<TrekInventory />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/featuredholidays" element={<FeaturedHolidays />} />
          <Route path="/socialfeeds" element={<SocialFeeds />} />
          <Route path="/travelcards" element={<TravelCards />} />
          <Route path="/trainers" element={<Trainers />} />
          <Route path="/enquiries" element={<Enquiries />} />
          <Route path="/locationprices" element={<LocationPrices />} />
          <Route path="/certificates" element={<Certificates />} />
        </Route>
      </Routes>
    </>
  )
}

export default Router;