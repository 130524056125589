import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveInventory, editInventory, InventoryEditPayload } from '../hooks/inventory';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import { archiveTrekInventory } from '../hooks/treksinventory';


export default function EditInventoryItem({ open, setOpen, inventoryData }: { inventoryData: any, open: boolean, setOpen: (arg: boolean) => void }) {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);
    const [removeModal, setRemoveModal] = useState(false);

    const editInventoryItemMutation = useMutation({
        mutationFn: (payload: InventoryEditPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return editInventory(payload)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['inventory']);
            setOpen(false);
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Inventory item edited!",
                    notificationMessage: "The inventory item was edited successfully."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while editing the inventory item."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const archiveTrekInventoryMutation = useMutation({
        mutationFn: () => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return archiveTrekInventory(inventoryData.id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['inventory']);
            setOpen(false);
            setRemoveModal(false);
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Inventory removed!",
                    notificationMessage: "The inventory was removed successfully."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while removing the inventory."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const archiveInventoryMutation = useMutation({
        mutationFn: () => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return archiveInventory(inventoryData.id)
        },
        onSuccess: () => {
            // queryClient.invalidateQueries(['inventory']);
            // setOpen(false);
            // setRemoveModal(false);
            archiveTrekInventoryMutation.mutateAsync(inventoryData.id)
            // notificationContext?.dispatch({
            //     type: "SHOW",
            //     payload: {
            //         notificationType: "SUCCESSFUL",
            //         notificationTitle: "Inventory removed!",
            //         notificationMessage: "The inventory was removed successfully."
            //     }
            // })
            // appLoaderContext?.dispatch({
            //     type: "HIDE"
            // })
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while removing the inventory."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    useEffect(() => {
        setInventoryItemForm(inventoryData);
    }, [inventoryData]);


    const [inventoryItemForm, setInventoryItemForm] = useState<InventoryEditPayload>({
        type: inventoryData.type,
        description: inventoryData.description,
        size: inventoryData.size,
        imageUrl: inventoryData.imageUrl,
        color: inventoryData.color,
        id: inventoryData.id,
    });

    const handleFormChange = (event: any) => {
        event.preventDefault();

        let fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;

        const newFormData: any = { ...inventoryItemForm };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;

        setInventoryItemForm(newFormData);

    };

    const handleEditSubmit = async (event: any) => {
        event.preventDefault();

        const editedInventory = {
            type: inventoryItemForm.type,
            description: inventoryItemForm.description,
            size: inventoryItemForm.size,
            imageUrl: inventoryItemForm.imageUrl,
            color: inventoryItemForm.color,
            id: inventoryItemForm.id,
        }

        console.log(editedInventory);
        const resp = await editInventoryItemMutation.mutate({ ...editedInventory });
        console.log(resp);
    };

    function removeInventory() {
        if (inventoryData && inventoryData.id) {
            archiveInventoryMutation.mutateAsync()
        }
    }

    return (<>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                        <div className="h-0 flex-1 overflow-y-auto">
                                            <div className="bg-slate-700 py-6 px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">Edit
                                                        Inventory Item</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-slate-700 text-slate-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-slate-300">
                                                        Edit below the selected inventory item information.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between">
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="inventory-type"
                                                                className="block text-sm font-medium text-gray-900">
                                                                Item Type
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="type" id="inventory-type"
                                                                    defaultValue={inventoryData?.type}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="description"
                                                                className="block text-sm font-medium text-gray-900">
                                                                Description
                                                            </label>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    id="description" name="description" rows={4}
                                                                    defaultValue={inventoryData?.description}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size"
                                                                className="block text-sm font-medium text-gray-900">
                                                                Size
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="size" id="size"
                                                                    defaultValue={inventoryData?.size}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="color"
                                                                className="block text-sm font-medium text-gray-900">
                                                                Color
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="color" id="color"
                                                                    defaultValue={inventoryData?.color}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                            <div className='flex flex-1'>
                                                <button
                                                    type="button"
                                                    className="rounded-md border bg-red-500 border-red-300 text-white py-2 px-4 text-sm font-medium shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2"
                                                    onClick={() => setRemoveModal(true)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                            <button
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={(event: any) => handleEditSubmit(event)}
                                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <Modal open={removeModal} setModal={setRemoveModal}>
            <>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                            >
                                Remove inventory item
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to remove this inventory?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                    <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
                  text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  m-2
                  "
                        onClick={removeInventory}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        onClick={() => setRemoveModal(false)}
                        className="inline-flex m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        No
                    </button>
                </div>
            </>
        </Modal>
    </>)
}
