import { useContext, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pagination from "../components/Pagination";
import { getAllSocialFeeds, useAllSocialFeeds, filteringParams } from "../hooks/socialfeed";
import { getAllReviews, useAllReviews, getReviewsByTrekId, toggleReview, archiveReview, updateReview } from "../hooks/reviews";
import Modal from "../components/Modal";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useAllTreks } from "../hooks/trek";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { Dialog, Switch } from "@headlessui/react";
import { NotificationContext } from "../context/NotificationContext";
import { getImageUrl } from "../components/utils";
import { useAllAdventures } from "../hooks/adventures";
import { useAllExpeditions } from "../hooks/expeditions";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function TravelCards() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [selectedTrekId, setSelectedTrekId] = useState(0);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("DESC");
    const [keyword, setKeyword] = useState("");
    const [selectedReviewFrontText, setSelectedReviewFrontText] = useState("");
    const [selectedReviewBackText, setSelectedReviewBackText] = useState("");
    const [reviewsData, setReviewsData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0)
    const [removeModal, setRemoveModal] = useState(false)
    const allUsersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllReviews(payload)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setReviewsData(data.reviews)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const trekReviewsMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getReviewsByTrekId(id)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setReviewsData(data.reviews)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const [modal, setModal] = useState(false)
    const handleUserMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload)
    }
    const trekData = useAllTreks({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    });
    const { data: advData } = useAllAdventures({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    const { data: expData } = useAllExpeditions({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    const allTreks = trekData?.data?.treks;
    const allAdventures = advData?.adventures;
    const allExpeditions = expData?.expeditions;

    const [selectedReview, setSelectedReview] = useState<any>();

    useEffect(() => {
        if (selectedReview) {
            setSelectedReviewFrontText(selectedReview.frontText)
            setSelectedReviewBackText(selectedReview.backText)
        } else {
            setSelectedReviewFrontText("")
            setSelectedReviewBackText("")
        }
    }, [selectedReview])

    const handleClose = () => {
        setModal(false)
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "DESC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllReviews({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if (data && selectedTrekId === 0) {
            setReviewsData(data.reviews)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (selectedTrekId === 0) {
            handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        } else {
            trekReviewsMutation.mutateAsync(selectedTrekId)
        }
    }, [selectedTrekId])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    function getTrekName(trekId: number): string {
        const allData = [...allTreks, ...allAdventures, ...allExpeditions]
        const trek = allData?.find((t: any) => t.id === trekId);
        return trek?.name;
    }

    const toggleReviewApprovalMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleReview(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['reviews'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const archiveReviewMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return archiveReview(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['reviews'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Travel card removed!",
                    notificationMessage: "The travel card was removed successfully."
                }
            })
            handleClose()
            setRemoveModal(false)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was some error while removing the travel card."
                }
            })
        }
    })

    const updateReviewMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return updateReview(id, {
                frontText: selectedReviewFrontText,
                backText: selectedReviewBackText,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['reviews'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Travel card updated!",
                    notificationMessage: "The travel card was updated successfully."
                }
            })
            handleClose()
            setRemoveModal(false)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was some error while removing the travel card."
                }
            })
        }
    })

    if (isLoading) {
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    function downloadImage(url: string, front: boolean = true) {
        fetch(url).then(response => response.blob()).then(blob => {
            const str = front ? "front" : "back"
            const blobURL = window.URL.createObjectURL(new Blob([blob]));
            const fileName = url.split('/').pop();
            let finalStr = `${selectedReview.userName.toLowerCase()} ${getTrekName(selectedReview.trekId).toLowerCase()} ${str} ${fileName}`;
            finalStr = finalStr.replace(/ /g, "_");
            const link = document.createElement('a');
            link.href = blobURL;
            link.setAttribute('download', finalStr!);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }

    function removeReview() {
        if (selectedReview && selectedReview.id) {
            archiveReviewMutation.mutateAsync(selectedReview.id)
        }
    }

    function submitUpdate() {
        if (selectedReview && selectedReview.id) {
            updateReviewMutation.mutateAsync(selectedReview.id)
        }
    }

    return (
        <>
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Travel Cards</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the travelcards in your system
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <label htmlFor="alltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Trek</label>
                            {/* <select id="alltreks" aria-label="alltreks" name="trekName" onChange={(e: any) => setSelectedTrekId(parseInt(e.target.value))} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                <option value={0}>All Treks</option>
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select> */}
                            <select
                                id="location"
                                name="location"
                                title="location"
                                onChange={(e: any) => setSelectedTrekId(parseInt(e.target.value))}
                                value={selectedTrekId}
                                className="mt-1 block  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value={0}>All treks</option>
                                {allTreks && allTreks.length > 0 && <option disabled>TREKS</option>}
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                {allExpeditions && allExpeditions.length > 0 && <option disabled>EXPEDITIONS</option>}
                                {allExpeditions?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                {allAdventures && allAdventures.length > 0 && <option disabled>ADVENTURES</option>}
                                {allAdventures?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Id
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("id")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    User Id
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("userId")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "userId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    User Name
                                                </div>
                                            </th>
                                            {selectedTrekId === 0 && <><th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Trek Id
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("trekId")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "trekId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Trek Name
                                                    </div>
                                                </th></>}
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Booking Id
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("bookingId")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "bookingId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Review card type
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("reviewCardType")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "reviewCardType" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Front image
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Front text
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Back text
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Back image
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Approved
                                                </div>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">View</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {reviewsData && reviewsData.length > 0 && reviewsData.map((review: any) => (
                                            <tr key={review.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {review.id}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{review.userId}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{review.userName}</td>
                                                {selectedTrekId === 0 && <><td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{review.trekId}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getTrekName(review.trekId)}</td></>}
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{review.bookingId}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{review.reviewCardType}</td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <div className="h-12 w-12 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${getImageUrl(review?.frontImageUrl)})` }}></div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 truncate max-w-[50px]">{review.frontText}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 truncate max-w-[50px]">{review.backText}</td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <div className="h-12 w-12 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${getImageUrl(review?.backImageUrl)})` }}></div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 truncate max-w-[50px]">
                                                    <Switch
                                                        checked={review.approved}
                                                        onChange={(e: any) => toggleReviewApprovalMutation.mutateAsync(review.id)}
                                                        className={classNames(
                                                            review.approved ? 'bg-indigo-600' : 'bg-gray-200',
                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                        )}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            className={classNames(
                                                                review.approved ? 'translate-x-5' : 'translate-x-0',
                                                                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    review.approved ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                    <path
                                                                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <span
                                                                className={classNames(
                                                                    review.approved ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </Switch>
                                                </td>
                                                <td onClick={() => { setSelectedReview(review); setModal(true) }} className="cursor-pointer relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-indigo-600 hover:text-indigo-900">
                                                    View
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {reviewsData && reviewsData.length === 0 &&
                                    <div>
                                        <span className="flex w-full align-center justify-center">
                                            <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No Travel Cards found</p>
                                        </span>
                                    </div>
                                }
                            </div>
                            <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />
                        </div>
                    </div>
                </div>

                <Modal open={modal} setModal={handleClose} minWidth="1000px">
                    {selectedReview && <>
                        <div className="border-1 row flex  gap-2 overflow-hidden rounded-lg border border-gray-300 p-2">
                            <div className="rounded-lg border-2 flex flex-col items-center justify-between">
                                <div className="h-[260px] min-w-[260px] max-w-[260px] bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${getImageUrl(selectedReview.frontImageUrl)})` }}></div>
                                <button className="m-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => downloadImage(selectedReview.frontImageUrl, true)}
                                >
                                    Download
                                </button>
                            </div>
                            <textarea cols={40} rows={5} value={selectedReviewFrontText} onChange={(e) => setSelectedReviewFrontText(e.target.value)} className="flex w-full flex-col p-4 text-justify items-center justify-center rounded-lg border-2 border-gray-100 focus-within:border-indigo-500 focus-within:ring-1" />
                            <textarea cols={40} rows={5} value={selectedReviewBackText} onChange={(e) => setSelectedReviewBackText(e.target.value)} className="flex w-full flex-col p-4 text-justify items-center justify-center rounded-lg border-2 border-gray-100 focus-within:border-indigo-500 focus-within:ring-1" />
                            {/* <div className="flex w-full flex-col p-4 text-justify items-center justify-center rounded-lg border-2 border-gray-100 focus-within:border-indigo-500 focus-within:ring-1">
                                {selectedReview.backText}
                            </div> */}
                            <div className="rounded-lg border-2 flex flex-col items-center justify-between">
                                <div className="h-[260px] min-w-[260px] max-w-[260px] bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${getImageUrl(selectedReview.backImageUrl)})` }}></div>
                                <button className="m-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => downloadImage(selectedReview.backImageUrl, false)}
                                >
                                    Download
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <button className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setRemoveModal(true)}
                            >
                                DELETE
                            </button>
                            <button disabled={(selectedReviewBackText === selectedReview.backText) && (selectedReviewFrontText === selectedReview.frontText)} className="mt-4 ml-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                                onClick={submitUpdate}
                            >
                                Save changes
                            </button>
                        </div>
                    </>}
                </Modal>
                <Modal open={removeModal} setModal={setRemoveModal}>
                    <>
                        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        Remove review
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to remove this review?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                            <button
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
                  text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  m-2
                  "
                                onClick={removeReview}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                onClick={() => setRemoveModal(false)}
                                className="inline-flex m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                No
                            </button>
                        </div>
                    </>
                </Modal>
            </div>
        </>
    )
}