import {Fragment, useContext, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {useMutation, useQueryClient} from '@tanstack/react-query';
import { createInventory, InventoryCreatePayload } from '../hooks/inventory'; 
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';

const defaultInventoryFormValues = {
    type: '',
    description: '',
    size: '',
    imageUrl: '',
    color: '',
};

export default function CreateinventoryItem({open, setOpen}: { open: boolean, setOpen: (arg: boolean) => void }) {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const [inventoryItemForm, setinventoryItemForm] = useState<InventoryCreatePayload>(defaultInventoryFormValues);

    const createInventoryItemMutation = useMutation({
        mutationFn: (payload: InventoryCreatePayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createInventory(payload)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['inventory'])
            setinventoryItemForm(defaultInventoryFormValues)
            setOpen(false)
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Inventory item created!",
                    notificationMessage: "The inventory item was created successfully."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error with creating the inventory item."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const handleFormChange = (event: any) => {
        event.preventDefault();

        let fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;

        const newFormData: any = { ...inventoryItemForm };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;

        setinventoryItemForm(newFormData);
    };

    const handleSubmit = async () => {

        console.log(inventoryItemForm);
        const resp = await createInventoryItemMutation.mutate({ ...inventoryItemForm });

        setOpen(false);
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0"/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                        <div className="h-0 flex-1 overflow-y-auto">
                                            <div className="bg-slate-700 py-6 px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">New
                                                        Inventory Item</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-slate-700 text-slate-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-slate-300">
                                                        Get started by filling in the information below to create your
                                                        new inventory Item.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between">
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="type"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Item Type
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="type" id="type"
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="description"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Description
                                                            </label>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    id="description" name="description" rows={4}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Size
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="size" id="size"
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="color"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Color
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="color" id="color"
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                            <button
                                                type="button"
                                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                            type='button'
                                                onClick={() => handleSubmit()}
                                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
