import { useContext, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pagination from "../components/Pagination";
import { getAllSocialFeeds, useAllSocialFeeds, unapproveSocialFeeds, filteringParams, approveSocialFeeds, SocialfeedApprovePayload, archiveSocialFeed } from "../hooks/socialfeed";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { Dialog, Switch } from "@headlessui/react";
import Modal from "../components/Modal";
import { NotificationContext } from "../context/NotificationContext";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function SocialFeeds() {

    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [socialFeedsData, setSocialFeedsData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0)
    const [selectedFeed, setSelectedFeed] = useState<any>(null)
    const [removeModal, setRemoveModal] = useState(false)
    const allUsersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllSocialFeeds(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleUserMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload).then(res => {
            setSocialFeedsData(res.socialFeeds)
            setDataCount(res.total)
        })
    };

    // const [approvesocialfeeds, setApproveSocialFeeds] = useState<SocialfeedApprovePayload>({
    //     socialFeedId: 0
    // });

    const archiveFeedMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return archiveSocialFeed(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['socialFeeds'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Post removed!",
                    notificationMessage: "The Post was removed successfully."
                }
            })
            handleClose()
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was some error while removing the post."
                }
            })
        }
    })

    const approveSocialFeedsMutation = useMutation({
        mutationFn: (payload: SocialfeedApprovePayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return approveSocialFeeds(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['socialFeeds'])
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const unapproveSocialFeedsMutation = useMutation({
        mutationFn: (payload: SocialfeedApprovePayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return unapproveSocialFeeds(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['socialFeeds'])
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleApproveClick = (id: number) => {
        // setApproveSocialFeeds( { ...approvesocialfeeds, ['socialFeedId']: id });

        const approvesocialfeeds = {
            socialFeedId: id
        };

        console.log(approvesocialfeeds);

        approveSocialFeedsMutation.mutate({ ...approvesocialfeeds });
    };

    const handleUnapproveClick = (id: number) => {
        const approvesocialfeeds = {
            socialFeedId: id
        };
        unapproveSocialFeedsMutation.mutate({ ...approvesocialfeeds });
    };

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if(orderBy === orderByString){
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllSocialFeeds({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if(data){
            setSocialFeedsData(data.socialFeeds)
            setDataCount(data.total)
        }
    },[data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void{
        setKeyword(e)
    }

    function handlePageChange(page: number): void{
        setSkip(take*(page-1))
        handleUserMutation({
            take,
            skip: take * (page-1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    if (isLoading) {
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                    <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                    <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    function removeSocialFeed(){
        if (selectedFeed && selectedFeed.id) {
            archiveFeedMutation.mutateAsync(selectedFeed.id)
        }
    }
    function handleClose() {
        setRemoveModal(false)
        setSelectedFeed(null)
    }

    return (
            <>
                <div>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Social Feed</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of all the socialfeed in your system
                                </p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                {/* <button
                                    type="button"
                                    onClick={() => setOpenCreateForm(true)}
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Add user
                                </button> */}
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    <div className="group inline-flex">
                                                        ID
                                                        <span onClick={() => handleOrderChange("id")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        SUBMISSION BY
                                                        <span onClick={() => handleOrderChange("submittedBy")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "submittedBy" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        LINK
                                                        <span onClick={() => handleOrderChange("linkurl")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "linkurl" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        TYPE
                                                        <span onClick={() => handleOrderChange("type")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "type" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        APPROVED
                                                        <span onClick={() => handleOrderChange("status")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "status" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Remove</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {socialFeedsData && socialFeedsData.length > 0 && socialFeedsData.map((socialFeed: any) => (
                                                <tr key={socialFeed.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {socialFeed.id}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{socialFeed.submittedBy}</td>
                                                    <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">
                                                        < a href={socialFeed.linkurl} target="_blank" rel="noreferrer" className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                            {socialFeed.linkurl}
                                                        </a>
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{socialFeed.type}</td>
                                                    {/* <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{socialFeed.status}</td> */}
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 flex justify-center">
                                                        <Switch
                                                            checked={socialFeed.status === "APPROVED"}
                                                            onChange={(e: any) => socialFeed.status === "APPROVED" ? unapproveSocialFeedsMutation.mutate({ socialFeedId: socialFeed.id }) : approveSocialFeedsMutation.mutate({ socialFeedId: socialFeed.id }) }
                                                            className={classNames(
                                                                socialFeed.status === "APPROVED" ? 'bg-indigo-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span
                                                                className={classNames(
                                                                    socialFeed.status === "APPROVED" ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        socialFeed.status === "APPROVED" ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                    )}
                                                                    aria-hidden="true"
                                                                >
                                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                        <path
                                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        socialFeed.status === "APPROVED" ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                    )}
                                                                    aria-hidden="true"
                                                                >
                                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div onClick={() => { setSelectedFeed(socialFeed); return setRemoveModal(true) }} className="text-red-500 cursor-pointer">REMOVE</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {socialFeedsData && socialFeedsData.length === 0 &&
                                             <div>
                                                <span className="flex w-full align-center justify-center">
                                                    <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No socialfeeds found</p>
                                                </span>
                                            </div>
                                        }
                                </div>
                                <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            <Modal open={removeModal} setModal={handleClose}>
                <>
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium text-gray-900"
                                >
                                    Remove post
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to remove this social feed post?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 text-right sm:px-6">
                        <button
                            type="button"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
                  text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  m-2
                  "
                            onClick={removeSocialFeed}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="inline-flex m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            No
                        </button>
                    </div>
                </>
            </Modal>
            </>
    )
}
