import { useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface MetaPayload {
  id?: number,
  type: string;
  title: string;
  description: string;
  keywords: string;
  image: string;
}

const getAllMeta = async () => {
  const url = '/meta'
  const resp = await apiInstance.get(url)
  return resp.data
}

const createMeta = async (payload: MetaPayload) => {
  const url = '/meta/create'
  return apiInstance.post(url, payload)
}

const useAllMeta = () => {
  return useQuery(['meta'], () => getAllMeta())
}

export { getAllMeta, createMeta, useAllMeta }
