import classNames from 'classnames'
import React from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

const Pagination = ({ activePage, dataCount, dataPerPage, handlePageChange, setActivePage }: { activePage: number, dataCount: number, dataPerPage: number, handlePageChange: (arg: number) => void, setActivePage: (arg: number) => void }) => {

    const dataNumber = []
    for (let i = 1; i <= Math.ceil(dataCount / dataPerPage); i++) {
        dataNumber.push(i)
    }
    function handlePreviousClick(): void {
        if (activePage !== 1) {
            setActivePage(activePage - 1)
            handlePageChange(activePage - 1)
        }
    }

    function handleNextClick(): void {
        if (activePage !== dataNumber.length) {
            setActivePage(activePage + 1)
            handlePageChange(activePage + 1)
        }
    }

    if (dataNumber.length > 1 && dataNumber.length <= 7) {
        return (
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <div
                        onClick={handlePreviousClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </div>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    {dataNumber?.map(n => <div key={n} onClick={() => { handlePageChange(n); setActivePage(n) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === n ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {n}
                    </div>)}
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <div
                        onClick={handleNextClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
            </nav>
        )
    } else if (dataNumber.length > 7 && (activePage <= 3 || activePage >= dataNumber.length-2)) {
        return (
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <div
                        onClick={handlePreviousClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </div>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    <div onClick={() => { handlePageChange(1); setActivePage(1) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === 1 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        1
                    </div>
                    <div onClick={() => { handlePageChange(2); setActivePage(2) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === 2 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        2
                    </div>
                    <div onClick={() => { handlePageChange(3); setActivePage(3) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === 3 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        3
                    </div>
                    <div className="inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer font-medium border-transparent text-gray-500">
                        ...
                    </div>
                    <div onClick={() => { handlePageChange(dataNumber.length - 2); setActivePage(dataNumber.length - 2) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === dataNumber.length - 2 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {dataNumber.length - 2}
                    </div>
                    <div onClick={() => { handlePageChange(dataNumber.length - 1); setActivePage(dataNumber.length - 1) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === dataNumber.length - 1 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {dataNumber.length - 1}
                    </div>
                    <div onClick={() => { handlePageChange(dataNumber.length); setActivePage(dataNumber.length) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === dataNumber.length ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {dataNumber.length}
                    </div>
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <div
                        onClick={handleNextClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
            </nav>
        )
    } else if (dataNumber.length > 7 && (activePage > 3 || activePage < dataNumber.length-2)) {
        return (
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <div
                        onClick={handlePreviousClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </div>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    <div onClick={() => { handlePageChange(1); setActivePage(1) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === 1 ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        1
                    </div>
                    <div className="inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer font-medium border-transparent text-gray-500">
                        ...
                    </div>
                    <div onClick={() => { handlePageChange(activePage-1); setActivePage(activePage-1) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {activePage-1}
                    </div>
                    <div onClick={() => { handlePageChange(activePage); setActivePage(activePage) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        "border-indigo-500 font-medium text-indigo-600"
                    )}>
                        {activePage}
                    </div>
                    <div onClick={() => { handlePageChange(activePage+1); setActivePage(activePage+1) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {activePage+1}
                    </div>
                    <div className="inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer font-medium border-transparent text-gray-500">
                        ...
                    </div>
                    <div onClick={() => { handlePageChange(dataNumber.length); setActivePage(dataNumber.length) }} className={classNames(
                        'inline-flex items-center border-t-2 px-4 pt-4 text-sm cursor-pointer',
                        activePage === dataNumber.length ? "border-indigo-500 font-medium text-indigo-600" : "font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    )}>
                        {dataNumber.length}
                    </div>
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <div
                        onClick={handleNextClick}
                        className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                    >
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
            </nav>
        )
    } else {
        return <></>

    }
}

export default Pagination