import React, { createContext, useReducer } from 'react';

interface contextType {
  showLoader: boolean;
  dispatch: (payload: any) => void;
}
export const AppLoaderContext = createContext<contextType | null>(null);


export const loaderReducer = (state: any, action: any) => {
  console.log(action);
  switch (action.type) {
    case 'SHOW':
      return { showLoader: true };
    case 'HIDE':
      return { showLoader: false };
    default:
      return state;
  }
};

export const AppLoaderContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const initialAppLoaderState = {
    showLoader: false
  };

  const [state, dispatch] = useReducer(loaderReducer, initialAppLoaderState);

  return (
    <AppLoaderContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AppLoaderContext.Provider>
  );
};