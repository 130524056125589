import { useNavigate } from "react-router-dom";
import apiInstance from "../api-service";

export interface LoginPayload {
    email: string;
    password: string;
}

export interface SignupPayload {
    email: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    password: string;
}

// const getUserProfile = async () => {
//     const url = BASE_API + '/user'
//     const accessToken = localStorage.getItem('accessToken')
//     return axios.get(url, { headers : { Authorization: `Bearer ${accessToken}`}})
// }

const login = async (payload: LoginPayload) => {
    return apiInstance.post('/login', payload)
}

const register = async (payload: SignupPayload) => {
    return apiInstance.post('/signup', payload)
}

const logout = async () => {
    localStorage.clear();
    window.location.reload();
}

export { login, logout, register } 
