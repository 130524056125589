import { AuthContextProvider } from './context/AuthContext';
import { AppLoaderContextProvider } from './context/AppLoaderContext';
import { NotificationContextProvider } from './context/NotificationContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Router from './routes';
import React from 'react';

const AppContainer = () => {
    const [queryClient] = React.useState(() => new QueryClient());

    return (
        <>
            <div className="h-full min-h-screen bg-white">
                <div className="h-full">
                    <QueryClientProvider client={queryClient}>
                        <AuthContextProvider>
                            <AppLoaderContextProvider>
                                <NotificationContextProvider>
                                    <Router />
                                </NotificationContextProvider>
                            </AppLoaderContextProvider>
                        </AuthContextProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </div>
            </div>
        </>
    )
}

export default AppContainer;