import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number,
  skip: number,
  orderBy: string,
  orderType: string,
  keyword: string
}

export interface ReviewsEditPayload {
  frontText: string;
  backText: string;
}

const getAllReviews = async (params?: filteringParams) => {
  const url = '/reviews/all'
  const resp = await apiInstance.get(url, { params })
  return resp.data;
}

const getReviewsByTrekId = async (id: number) => {
  const url = 'reviews/trek/' + id + '/all'
  const resp = await apiInstance.get(url)
  return resp.data;
}

const useAllReviews = (params?: filteringParams) => {
  return useQuery(['reviews'], () => getAllReviews(params))
}

const toggleReview = async (reviewId: number) => {
  const url = '/reviews/toggle/' + reviewId
  const resp = await apiInstance.put(url)
  return resp.data;
}

const archiveReview = async (reviewId: number) => {
  const url = '/reviews/archive/' + reviewId
  const resp = await apiInstance.put(url)
  return resp.data;
}

const updateReview = async (reviewId: number, payload: ReviewsEditPayload) => {
  const url = '/reviews/update/' + reviewId
  const resp = await apiInstance.put(url, payload)
  return resp.data;
}

export { getAllReviews, useAllReviews, getReviewsByTrekId, toggleReview, archiveReview, updateReview }
