import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentMagnifyingGlassIcon, LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editTrek, EditTrekPayload, archiveTrek } from '../hooks/trek';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';
import Modal from './Modal';

export default function EditTrek({ open, setOpen, trekData }: { trekData: any, open: boolean, setOpen: (arg: boolean) => void }) {
  const queryClient = useQueryClient();
  const appLoaderContext = useContext(AppLoaderContext);
  const notificationContext = useContext(NotificationContext);

  const editTrekMutation = useMutation({
    mutationFn: (payload: EditTrekPayload) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return editTrek(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['treks']);
      setOpen(false);
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Trek edited!",
          notificationMessage: "The trek info was edited successfully."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: "There was an error while editing the trek information."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  });

  const archiveTrekMutation = useMutation({
    mutationFn: () => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return archiveTrek(trekData.id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['treks']);
      setOpen(false);
      setRemoveModal(false);
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Trek removed!",
          notificationMessage: "The trek was removed successfully."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: "There was an error while removing the trek."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  });

  useEffect(() => {
    setTrekForm(trekData);
    setdifficultyLevel(trekData?.difficultyLevel);

    if (trekData.bestTime) {
      const bestTime = trekData.bestTime;
      let arr = [...bestTime];

      const newMonths = months;

      const updateSeason = (item: any, index: any) => {
        if (item != "0") {
          newMonths[index].checked = true;
        } else {
          newMonths[index].checked = false;
        }
      };

      arr.forEach(updateSeason);

    }
  }, [trekData]);

  const [getdifficultyLevel, setdifficultyLevel] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [months, setSeasons] = useState([
    { value: 'JANUARY', label: 'January', checked: false },
    { value: 'FEBRUARY', label: 'February', checked: false },
    { value: 'MARCH', label: 'March', checked: false },
    { value: 'APRIL', label: 'April', checked: false },
    { value: 'MAY', label: 'May', checked: false },
    { value: 'JUNE', label: 'June', checked: false },
    { value: 'JULY', label: 'July', checked: false },
    { value: 'AUGUST', label: 'August', checked: false },
    { value: 'SEPTEMBER', label: 'September', checked: false },
    { value: 'OCTOBER', label: 'October', checked: false },
    { value: 'NOVEMBER', label: 'November', checked: false },
    { value: 'DECEMBER', label: 'December', checked: false },
  ])

  const [trekForm, setTrekForm] = useState<EditTrekPayload>({
    id: trekData?.id,
    name: trekData?.name,
    description: trekData?.description,
    duration: trekData?.duration,
    defaultBookingAmount: trekData?.defaultBookingAmount,
    defaultTotalAmount: trekData?.defaultTotalAmount,
    defaultSlots: trekData?.defaultSlots,
    location: trekData?.location,
    altitude: trekData?.altitude,
    bestTime: trekData?.bestTime,
    pickupPoint: trekData?.pickupPoint,
    difficultyLevel: trekData?.difficultyLevel,
    state: trekData?.state,
    contactPersonName: trekData?.contactPersonName,
    contactPersonNumber: trekData?.contactPersonNumber,
    reportingTime: trekData?.reportingTime,
    reportingLocation: trekData?.reportingLocation,
    type: trekData?.type,
    totalTrekDistance: trekData?.totalTrekDistance,
    sortOrder: trekData?.sortOrder,
    videoUrl: trekData?.videoUrl,
    keywords: trekData?.keywords,
    title: trekData?.title
  });

  const handleFormChange = (event: any) => {
    event.preventDefault();

    console.log("test",event.target.getAttribute('id'))

    let fieldName = event.target.getAttribute('name');

    if (fieldName === 'difficultyLevel') {
      setdifficultyLevel(event.target.value);
    }

    const newFormData: any = { ...trekForm };
    if (fieldName === 'notification-method') {
      let fieldValue = event.target.id;
      newFormData['type'] = fieldValue;
      // setTrekForm(newFormData);
    } else if (["defaultTotalAmount", "defaultBookingAmount", "defaultSlots", "duration", "altitude", "sortOrder"].includes(fieldName)) {
      let fieldValue = parseInt(event.target.value);
      // const newFormData: any = { ...trekForm };
      newFormData[fieldName as keyof typeof newFormData] = fieldValue;
      // setTrekForm(newFormData);
    } else {
      let fieldValue = event.target.value?.trim();
      // const newFormData: any = { ...trekForm };
      newFormData[fieldName as keyof typeof newFormData] = fieldValue;
    }
    setTrekForm(newFormData);

  };

  const handleEditSubmit = (event: any) => {
    event.preventDefault();

    let bestTimeArr: string[] = [];
    months.map(a => {
      if (a.checked) {
        bestTimeArr.push(a.value[0])
      } else {
        bestTimeArr.push("0")
      }
    });

    const editedInventory = {
      id: trekForm?.id,
      name: trekForm?.name,
      description: trekForm?.description,
      duration: trekForm?.duration,
      defaultBookingAmount: trekForm?.defaultBookingAmount,
      defaultTotalAmount: trekForm?.defaultTotalAmount,
      defaultSlots: trekForm?.defaultSlots,
      location: trekForm?.location,
      altitude: trekForm?.altitude,
      bestTime: bestTimeArr.join(''),
      pickupPoint: trekForm?.pickupPoint,
      difficultyLevel: trekForm?.difficultyLevel,
      state: trekForm?.state,
      contactPersonName: trekForm?.contactPersonName,
      contactPersonNumber: trekForm?.contactPersonNumber,
      reportingTime: trekForm?.reportingTime,
      reportingLocation: trekForm?.reportingLocation,
      type: trekForm?.type,
      totalTrekDistance: trekForm?.totalTrekDistance,
      sortOrder: trekForm?.sortOrder,
      videoUrl: trekForm?.videoUrl,
      keywords: trekForm?.keywords,
      title: trekForm?.title
    };

    console.log("test",editedInventory);
    editTrekMutation.mutate({ ...editedInventory });
    // setOpen(false);
  };

  function handleCheck(e: any) {
    const newMonths = months;
    const idx = months.findIndex(s => s.value === e.target.name);
    newMonths[idx] = {
      ...newMonths[idx],
      checked: e.target.checked
    };
    console.log(newMonths);
    setSeasons(newMonths)
  }

  function removeTrek(){
    if(trekData && trekData.id){
      archiveTrekMutation.mutateAsync()
    }
  }


  return (<>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">

                          <Dialog.Title className="text-lg font-medium text-white">{trekForm?.name}</Dialog.Title>

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Edit the information below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                                Trek name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="name" id="name"
                                  defaultValue={trekForm?.name}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                Title
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="title" name="title" rows={4}
                                  defaultValue={trekForm?.title}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Description
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="description" name="description" rows={4}
                                  defaultValue={trekForm?.description}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="keywords" className="block text-sm font-medium text-gray-900">
                                Keywords
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="keywords" name="keywords" rows={4}
                                  defaultValue={trekForm?.keywords}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Video URL
                              </label>
                              <div className="mt-1">
                                <input
                                  id="videoUrl" name="videoUrl"
                                  type="text"
                                  value={trekForm?.videoUrl}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="location" className="block text-sm font-medium text-gray-900">
                                Location
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="location" id="location"
                                  defaultValue={trekForm?.location}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="duration" className="block text-sm font-medium text-gray-900">
                                Duration
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="duration" id="duration"
                                  defaultValue={trekForm?.duration}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="defaultBookingAmount" className="block text-sm font-medium text-gray-900">
                                Booking Amount
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="defaultBookingAmount" id="defaultBookingAmount"
                                  defaultValue={trekForm?.defaultBookingAmount}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="defaultTotalAmount" className="block text-sm font-medium text-gray-900">
                                Total Amount
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="defaultTotalAmount" id="defaultTotalAmount"
                                  defaultValue={trekForm?.defaultTotalAmount}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="altitude" className="block text-sm font-medium text-gray-900">
                                Altitude
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="altitude" id="altitude"
                                  defaultValue={trekForm?.altitude}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="bestTime" className="block text-sm font-medium text-gray-900">
                                Best Time
                              </label>
                              <div className="space-y-6">
                                {months.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-${option.value}-${optionIdx}`}
                                      name={`${option.value}`}
                                      type="checkbox"
                                      defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={handleCheck}
                                    />
                                    <label
                                      htmlFor={`filter-${option.value}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div>
                              <label htmlFor="pickupPoint" className="block text-sm font-medium text-gray-900">
                                Pickup Point
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="pickupPoint" id="pickupPoint"
                                  defaultValue={trekForm?.pickupPoint}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="contactPersonName" className="block text-sm font-medium text-gray-900">
                                Contact Person Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="contactPersonName" id="contactPersonName"
                                  defaultValue={trekForm?.contactPersonName}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="contactPersonNumber" className="block text-sm font-medium text-gray-900">
                                Contact Person Number
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" maxLength={10} name="contactPersonNumber" id="contactPersonNumber"
                                  defaultValue={trekForm?.contactPersonNumber}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="reportingTime" className="block text-sm font-medium text-gray-900">
                                Reporting Time
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="reportingTime" id="reportingTime"
                                  defaultValue={trekForm?.reportingTime}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="reportingLocation" className="block text-sm font-medium text-gray-900">
                                Reporting Location
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="reportingLocation" id="reportingLocation"
                                  defaultValue={trekForm?.reportingLocation}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="type" className="block text-sm font-medium text-gray-900">
                                Type
                              </label>
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id="TREK"
                                    name="notification-method"
                                    type="radio"
                                    onChange={handleFormChange}
                                    defaultChecked={trekForm.type === 'TREK'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="featured" className="ml-3 block text-sm font-medium text-gray-700">
                                    Trek
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="EXPEDITION"
                                    name="notification-method"
                                    type="radio"
                                    onChange={handleFormChange}
                                    defaultChecked={trekForm.type === 'EXPEDITION'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="footer" className="ml-3 block text-sm font-medium text-gray-700">
                                    Expedition
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="ADVENTURE"
                                    name="notification-method"
                                    type="radio"
                                    onChange={handleFormChange}
                                    defaultChecked={trekForm.type === 'ADVENTURE'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="footer" className="ml-3 block text-sm font-medium text-gray-700">
                                    Adventure
                                  </label>
                                </div>
                              </div>
                            </div> */}
                            <div>
                              <label htmlFor="totalTrekDistance" className="block text-sm font-medium text-gray-900">
                                Total Trek Distance
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="totalTrekDistance" id="totalTrekDistance"
                                  defaultValue={trekForm?.totalTrekDistance}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="sortOrder" className="block text-sm font-medium text-gray-900">
                                Sort order
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="sortOrder" id="sortOrder"
                                  defaultValue={trekForm?.sortOrder}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <fieldset>
                              <legend className="text-sm font-medium text-gray-900">Difficulty</legend>
                              <div className="mt-2 space-y-5">
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-5 items-center">
                                    <input
                                      id="easy"
                                      name="difficultyLevel"
                                      aria-describedby="privacy-public-description"
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      value={'EASY'}
                                      checked={getdifficultyLevel === 'EASY'}
                                      onChange={handleFormChange}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="easy" className="font-medium text-gray-900">
                                      Easy
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex h-5 items-center">
                                      <input
                                        id="moderate"
                                        name="difficultyLevel"
                                        aria-describedby="privacy-private-to-project-description"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        value={'MODERATE'}
                                        checked={getdifficultyLevel === 'MODERATE'}
                                        onChange={handleFormChange}
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label htmlFor="moderate" className="font-medium text-gray-900">
                                        Moderate
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex h-5 items-center">
                                      <input
                                        id="difficult"
                                        name="difficultyLevel"
                                        aria-describedby="privacy-private-to-project-description"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        value={'DIFFICULT'}
                                        checked={getdifficultyLevel === 'DIFFICULT'}
                                        onChange={handleFormChange}
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label htmlFor="difficult" className="font-medium text-gray-900">
                                        Difficult
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div>
                              <label htmlFor="defaultSlots" className="block text-sm font-medium text-gray-900">
                                Default Slots
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="defaultSlots" id="defaultSlots"
                                  value={trekForm?.defaultSlots}
                                  min={0}
                                  onChange={handleFormChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <div className='flex flex-1'>
                        <button
                          type="button"
                          className="rounded-md border bg-red-500 border-red-300 text-white py-2 px-4 text-sm font-medium shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2"
                          onClick={() => setRemoveModal(true)}
                        >
                          Remove
                        </button>
                      </div>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={(event: any) => handleEditSubmit(event)}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Modal open={removeModal} setModal={setRemoveModal}>
      <>
        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Remove trek
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to remove this trek?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 text-right sm:px-6">
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
                  text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  m-2
                  "
            onClick={removeTrek}
          >
            Yes
          </button>
          <button
            type="button"
            onClick={() => setRemoveModal(false)}
            className="inline-flex m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            No
          </button>
        </div>
      </>
    </Modal>
  </>)
}
