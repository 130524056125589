import { Fragment, useContext, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, CloudArrowUpIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pagination from "../components/Pagination";
import { filteringParams } from "../hooks/socialfeed";
import { getAllTrainers, TrainerEditPayload, uploadImage, useAllTrainers, toggleTrainer } from "../hooks/trainers";
import CreateTrainer from "../components/CreateTrainer";
import Modal from "../components/Modal";
import EditTrainer from "../components/EditTrainer";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { Switch } from "@headlessui/react";
import { getImageUrl } from "../components/utils";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Trainers() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const [openTrainerForm, setOpenTrainerForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [getUploadTrainerId, setUploadTrainerId] = useState(0);
    const [getEditTrainer, setEditTrainer] = useState<TrainerEditPayload>({
        id: 0,
        firstName: '',
        lastName: '',
        certificate: '',
        bio: '',
        age: 0,
        imageUrl: '',
        designation: '',
        sortOrder: 0,
    });

    const [imageFile, setImageFile] = useState<any>();
    const [previewTrainerId, setPreviewTrainerId] = useState(0);

    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [trainersData, setTrainersData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0);
    const allTrainersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllTrainers(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const uploadTrainerImageMutation = useMutation({
        mutationFn: (payload: any) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return uploadImage(payload, getUploadTrainerId)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Trainer image uploaded!",
                    notificationMessage: "The image for the trainer was uploaded successfully."
                }
            })
            queryClient.invalidateQueries(['trainers']);
            setModal(false);
            setImageFile(null)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while uploading the trainer image."
                }
            })
        }
    });

    const toggleTrainerMutation = useMutation({
        mutationFn: (trainerId: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleTrainer(trainerId)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['trainers']);
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const handleUserMutation = (payload: filteringParams): void => {
        allTrainersMutation.mutateAsync(payload).then(res => {
            setTrainersData(res.trainers)
            setDataCount(res.total)
        })
    };

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if(orderBy === orderByString){
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllTrainers({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if(data){
            setTrainersData(data.trainers)
            setDataCount(data.total)
        }
    },[data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void{
        setKeyword(e)
    }

    function handlePageChange(page: number): void{
        setSkip(take*(page-1))
        handleUserMutation({
            take,
            skip: take * (page-1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    const handleUploadClick = (event: any, trainerId: number) => {
        event.preventDefault();
        
        setUploadTrainerId(trainerId);
        setModal(true);
        setModalType('upload trainer image');
    };

    const handleFileUpload = () => {

        if (imageFile[0] != null) {
            const fileToUpload = imageFile[0];

            const fd = new FormData();
            fd.append('trainerImage', fileToUpload);
            console.log(fd);

            uploadTrainerImageMutation.mutateAsync(fd);
        }

        
    };

    const handlePreviewClick = (event: any, trainer: any) => {
        event.preventDefault();
        if(!trainer.imageUrl) return;
        setModal(true);
        setModalType('preview trainer image');

        setPreviewTrainerId(trainer.id);
    };

    const handleEditClick = (event: any, trainer: any) => {
        event.preventDefault();

        setOpenEditForm(true);

        const formValues = {
            id: trainer.id,
            firstName: trainer.firstName,
            lastName: trainer.lastName,
            certificate: trainer.certificate,
            bio: trainer.bio,
            age: trainer.age,
            imageUrl: trainer.imageUrl,
            designation: trainer.designation,
            sortOrder: trainer.sortOrder,
          };
      
        setEditTrainer(formValues);
    };



    if (isLoading) {
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                    <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                    <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    return (
            <>

        {modalType === 'upload trainer image' && (
            <Modal open={modal} setModal={setModal}>
                
                <div className="flex items-center justify-center w-full group relative flex-col overflow-hidden">
                    {!imageFile ? (
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <CloudArrowUpIcon aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" />
                                {/* <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg> */}
                                <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" onChange={(e) => {setImageFile(e.target.files);}} />
                        </label>
                    ) : (
                        <div className="flex flex-1 flex-col space-y-2 p-4">
                            <div className="flex justify-end">
                                <XCircleIcon
                                    className="h-6 w-6 cursor-pointer text-red-500"
                                    onClick={() => setImageFile(null)}
                                />
                            </div>
                            <img
                                alt="uploaded image"
                                src={URL.createObjectURL(imageFile[0])}
                                className="h-64 w-full rounded-lg border-2 border-solid border-gray-100"
                            />
                            <div className="flex justify-center">
                                <button
                                    title="Upload"
                                    type="button"
                                    onClick={() => handleFileUpload()}
                                    className="inline-flex w-32 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    )}
                    
                </div> 

            </Modal>
            ) 
        }
                <div>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Trainers</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of all the trainers in your system
                                </p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button
                                    type="button"
                                    onClick={() => setOpenTrainerForm(true)}
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Add Trainer
                                </button>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    <div className="group inline-flex">
                                                        ID
                                                        <span onClick={() => handleOrderChange("id")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Sort Order
                                                        <span onClick={() => handleOrderChange("sortOrder")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "sortOrder" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> }
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Name
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        BIO
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Certificate
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Age
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Designation
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        IMAGE
                                                    </div>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Active
                                                    </div>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {trainersData && trainersData.length > 0 && trainersData.map((trainer: any) => (
                                                <Fragment key={trainer.id}>
                                                    <tr key={trainer.id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {trainer.id}
                                                        </td>
                                                        <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{trainer.sortOrder}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{trainer.firstName + ' ' + trainer.lastName}</td>
                                                        <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{trainer.bio}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{trainer.certificate}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{trainer.age}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{trainer.designation}</td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <div onClick={ (event) => handlePreviewClick(event, trainer)} className={classNames("text-black", trainer.imageUrl ? "cursor-pointer" : "cursor-default")}>
                                                                    {
                                                                        trainer.imageUrl ?
                                                                        <img src={getImageUrl(trainer.imageUrl)} alt={trainer.firstName + ' ' + trainer.lastName} className="h-12 w-12" />
                                                                        : "No Image"
                                                                    }
                                                                    <span className="sr-only">, {trainer.firstName + ' ' + trainer.lastName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#" onClick={ (event) => handleUploadClick(event, trainer.id)} className="text-indigo-600 hover:text-indigo-900">
                                                                    Upload<span className="sr-only">, {trainer.firstName + ' ' + trainer.lastName}</span>
                                                            </a>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <Switch
                                                                checked={!trainer.disabled}
                                                                onChange={(e: any) => toggleTrainerMutation.mutateAsync(trainer.id)}
                                                                className={classNames(
                                                                    !trainer.disabled ? 'bg-indigo-600' : 'bg-gray-200',
                                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                                )}
                                                            >
                                                                <span className="sr-only">Use setting</span>
                                                                <span
                                                                    className={classNames(
                                                                        !trainer.disabled ? 'translate-x-5' : 'translate-x-0',
                                                                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            !trainer.disabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                            <path
                                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                                stroke="currentColor"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            !trainer.disabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </Switch>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#" onClick={(event) => handleEditClick(event, trainer)} className="text-indigo-600 hover:text-indigo-900">
                                                                    Edit<span className="sr-only">, {trainer.firstName + ' ' + trainer.lastName}</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    {   modalType === 'preview trainer image' && 
                                                            previewTrainerId === trainer.id && (
                                                                <Modal open={modal} setModal={setModal}>
                                                                    <div className="flex flex-1 flex-col space-y-2 p-4">
                                                                        <div className="flex justify-end">
                                                                            <XCircleIcon
                                                                                className="h-6 w-6 cursor-pointer text-red-500"
                                                                                onClick={() => setModal(false)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <a href={trainer.imageUrl} target="_blank"><img src={getImageUrl(trainer.imageUrl)} alt="preview img"/></a>
                                                                </Modal>
                                                        )
                                                    }

                                                </Fragment>
                                                
                                            ))}
                                        </tbody>
                                    </table>
                                    {trainersData && trainersData.length === 0 &&
                                             <div>
                                                <span className="flex w-full align-center justify-center">
                                                    <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No trainers found</p>
                                                </span>
                                            </div>
                                        }
                                </div>
                                <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage}/>
                            </div>
                        </div>
                    </div>

                    <CreateTrainer open={openTrainerForm} setOpen={setOpenTrainerForm}></CreateTrainer>
                    <EditTrainer open={openEditForm} setOpen={setOpenEditForm} trainerData={getEditTrainer}></EditTrainer>
                </div>
            </>
    )
}