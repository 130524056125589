import { useState, useEffect, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { XMarkIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import { getAllEnquiry, useAllEnquiry, filteringParams, EnquiryPayload } from "../hooks/enquiries";
import { format } from "date-fns";
import { AppLoaderContext } from "../context/AppLoaderContext";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Enquiries() {

    const appLoaderContext = useContext(AppLoaderContext);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("createdAt");
    const [orderType, setOrderType] = useState("DESC");
    const [keyword, setKeyword] = useState("");
    const [enquiriesData, setEnquiriesData] = useState<any>([]);
    const [searchTimer, setSearchTimer] = useState(0)

    const [enquiryInfoModal, setEnquiryInfoModal] = useState(false);

    const [enquiry, setEnquiry] = useState<EnquiryPayload>({
        name: '',
        email: '',
        phoneNumber: 0,
        title: '',
        description: '',
        trekName: '',
        trekId: 0
    });

    const allUsersMutation = useMutation({ 
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllEnquiry(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleUserMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload).then(res => {
            setEnquiriesData(res.enquiries)
            setDataCount(res.total)
        })
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "DESC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllEnquiry({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    });

    useEffect(() => {
        if (data) {
            setEnquiriesData(data.enquiries)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    function getDate(date: Date): string {
        let dt = new Date(date);
        return format(dt, "dd-MM-yyyy")
    }


    const handleEnquiryClick = (enquiryInfo: any) => {
        setEnquiry(enquiryInfo);

        setEnquiryInfoModal(true);
    }




    if (isLoading) {
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
            <span>Loading...</span>
            {/* </AdminLayout> */}
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
            <span>error...</span>
            {/* </AdminLayout> */}
        </>)
    }

    return (
        // <AdminLayout onChange={handleOnChange}>
        <>
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Enquiries</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the enquiries in your system
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Enquiry ID
                                                    <span onClick={() => handleOrderChange("id")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Trek ID
                                                    <span onClick={() => handleOrderChange("trekId")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "trekId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Trek Name
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Name
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Created at
                                                    <span onClick={() => handleOrderChange("createdAt")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "createdAt" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Email
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Phone Number
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Title
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Description
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {enquiriesData && enquiriesData.length > 0 && enquiriesData.map((enquiry: any) => (
                                            <tr key={enquiry?.id} className="hover:cursor-pointer" onClick={() => handleEnquiryClick(enquiry)}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {enquiry?.id}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {enquiry?.trekId}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{enquiry?.trekName}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{enquiry?.name}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getDate(enquiry?.createdAt)}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{enquiry?.email}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{enquiry?.phoneNumber}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{enquiry?.title}</td>
                                                <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{enquiry?.description}</td>
                                            </tr>
                                        ))}
                                        {enquiriesData && enquiriesData.length === 0 && <div className="flex w-full align-center justify-center">
                                            <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No enquiries found</p>
                                        </div>}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={enquiryInfoModal} setModal={setEnquiryInfoModal}>
                <>
                    <div className="flex flex-row align-center justify-between">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Enquiry Information</h3>
                        <XMarkIcon onClick={() => setEnquiryInfoModal(false)} className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer" aria-hidden="true" />
                    </div>
                    <div className="mt-5 border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Trek ID</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.trekId}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Trek name</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.trekName}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">User Name</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.name}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.email}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.phoneNumber}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Title</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.title}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Description</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{enquiry?.description}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </>
            </Modal>
        </>

    )
}