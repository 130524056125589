import { useContext, useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import {
    endOfWeek,
    startOfMonth,
    startOfToday,
    eachDayOfInterval,
    endOfMonth,
    format,
    isEqual,
    isToday,
    isSameMonth,
    parse,
    add,
    startOfWeek,
    isAfter
} from 'date-fns'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SchedulePayload, useSchedulesByTrekId, getBookingsByScheduleId } from "../hooks/bookings";
import { useAllTreks } from '../hooks/trek'
import Modal from "../components/Modal";
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';
import { PdfContainer } from '../components/PdfContainer';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Certificates() {
    const appLoaderContext = useContext(AppLoaderContext)
    const notificationContext = useContext(NotificationContext);
    const { data, isLoading, isError, error } = useAllTreks({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    let today = startOfToday();
    const [individualDay, setIndividualDay] = useState<Date | null>(null)
    const [removedDays, setRemovedDays] = useState<Date[]>([])
    const [bookingsForDate, setBookingsForDate] = useState<any[]>([])
    const [selectedDay, setSelectedDay] = useState(today)
    const [endSelectedDay, setEndSelectedDay] = useState(today)
    const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    const [settingDateType, setSettingDateType] = useState('2') // 0(start date), 1(end date) & 2(individual)
    const [trekId, setTrekId] = useState(0)
    const [selectedUserCert, setSelectedUserCert] = useState<any | null>(null)
    const [modal, setModal] = useState(false);
    const queryClient = useQueryClient();

    const scheduleData = useSchedulesByTrekId(trekId);
    const treks = data?.treks;
    const schedules = scheduleData?.data?.schedules;

    const getBookingsByScheduleIdMutation = useMutation({
        mutationFn: (scheduleId: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getBookingsByScheduleId(scheduleId)
        },
        onSuccess: (res) => {
            setBookingsForDate(res.bookings)
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            //   queryClient.invalidateQueries(['bookings'])
        },
        onError: (err: any) => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: err?.response?.data?.message
                }
            })
        }
    });

    useEffect(() => {
        if (individualDay) {
            const dayString = format(individualDay, 'yyyy-MM-dd')
            const schedule = schedules.find((sch: SchedulePayload) => sch.scheduleDate === dayString);
            if (schedule) {
                getBookingsByScheduleIdMutation.mutateAsync(schedule.id)
            }
        }
    }, [individualDay])


    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

    let newDays = eachDayOfInterval({
        start: startOfWeek(startOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 })
    })

    const previousMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const nextMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const selectTrek = (val: string) => {
        setTrekId(Number(val));
    }

    function resetDates(resetMonth: boolean = false) {
        setIndividualDay(null)
        setRemovedDays([])
        setSelectedDay(today)
        setEndSelectedDay(today)
        setSettingDateType('2')
        setBookingsForDate([])
        resetMonth && setCurrentMonth(format(today, 'MMM-yyyy'))
    }


    const isSelectedIndividualDates = (day: any) => {
        return isEqual(individualDay || new Date(), day)
    }

    const isPreSelected = (day: any, type: string = 'ON'): boolean => {
        let exists: boolean = false;
        schedules?.map((d: any) => {
            let temp = format(day, 'yyyy-MM-dd')
            if (temp === d.scheduleDate && d.scheduleType === type) {
                exists = true;
            }
        })
        return exists;
    }

    const checkFutureDate = (day: any): boolean => {
        return isAfter(day, today);
    }

    const setDay = (day: any) => {
        setIndividualDay(day)
    }

    function getDateStr(day: string) {
        return format(new Date(day), "E, MMM do, yyyy")
    }

    function getTrekName() {
        const trek = treks.find((t: { id: number }) => t.id === trekId);
        if(!trek) return "";
        console.log("test", trek.name.toUpperCase())
        return trek.name.toUpperCase().replace('TREK', '').trim()
    }

    function getTrekLocation() {
        const trek = treks.find((t: { id: number }) => t.id === trekId);
        if(!trek) return "";
        return trek.location.toUpperCase()
    }

    function getTrekHeight() {
        const trek = treks.find((t: { id: number }) => t.id === trekId);
        if(!trek) return "";
        return trek.altitude + ' FT'
    }

    function getDate(day: string) {
        return format(new Date(day), "do MMM yyyy").toUpperCase()
    }

    if (isLoading) {
        return (<>
            <span>Loading...</span>
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            <span>error...</span>
        </>)
    }

    return (
        <div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Certificates</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Generate certificates for completed treks
                        </p>
                    </div>
                </div>
                <div className='flex w-full items-start justify-between'>
                    <header className="flex items-center justify-between border-b border-gray-200 py-4">
                        <div className="flex items-center gap-4">
                            Select Trek
                            <select
                                id="location"
                                name="location"
                                title="location"
                                onChange={(evt) => {
                                    selectTrek(evt.target.value)
                                    resetDates(true);
                                }}
                                className="mt-1 block  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value={0}>Select</option>
                                {treks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                    </header>
                    {trekId !== 0 && <div className="flex w-8/12 flex-wrap-reverse gap-8">
                        <div className="grow p-4">
                            <div
                                className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
                                <section className="text-center">
                                    <div className='flex w-100 flex-1 justify-between'>
                                        <ChevronLeftIcon onClick={previousMonth}
                                            className="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                        <h2 className="font-semibold text-gray-900">{format(firstDayCurrentMonth, 'MMMM yyy')}</h2>
                                        <ChevronRightIcon onClick={nextMonth}
                                            className="h-5 w-5 cursor-pointer"
                                            aria-hidden="true" />
                                    </div>
                                    <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                                        <div>M</div>
                                        <div>T</div>
                                        <div>W</div>
                                        <div>T</div>
                                        <div>F</div>
                                        <div>S</div>
                                        <div>S</div>
                                    </div>
                                    <div
                                        className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                                        {newDays.map((day: Date, dayIdx: number) => (
                                            <button
                                                disabled={!Boolean(trekId) || checkFutureDate(day) || !isPreSelected(day, 'ON')}
                                                key={day.toISOString()}
                                                onClick={() => {
                                                    setDay(day)
                                                }}
                                                type="button"
                                                className={classNames(
                                                    'py-1.5 hover:bg-indigo-100 focus:z-10',
                                                    'bg-white',
                                                    ((isEqual(day, selectedDay) || isEqual(day, endSelectedDay)) || isToday(day)) && 'font-semibold',
                                                    (isEqual(day, selectedDay) || isEqual(day, endSelectedDay)) && !isToday(day) && 'text-black',
                                                    (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && isSameMonth(day, firstDayCurrentMonth) && isToday(day) && 'text-red-600',
                                                    (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && isSameMonth(day, firstDayCurrentMonth) && !isToday(day) && 'text-black',
                                                    (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && !isSameMonth(day, firstDayCurrentMonth) && !isToday(day) && 'text-gray-400',
                                                    !isToday(day) && (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && 'text-indigo-600',
                                                    dayIdx === 0 && 'rounded-tl-lg',
                                                    dayIdx === 6 && 'rounded-tr-lg',
                                                )}
                                            >
                                                <time
                                                    dateTime={format(day, 'yyyy-MM-dd')}
                                                    className={classNames(
                                                        isSelectedIndividualDates(day) && 'bg-blue-500 font-semibold text-white',
                                                        !isSelectedIndividualDates(day) && isPreSelected(day, 'ON') && !checkFutureDate(day) && 'bg-green-500 font-semibold text-white',
                                                        !isSelectedIndividualDates(day) && isPreSelected(day, 'ON') && checkFutureDate(day) && 'bg-green-200 font-semibold text-black',
                                                        'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                                                    )}
                                                >
                                                    {format(day, 'd')}
                                                </time>
                                            </button>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>}
                </div>
                {selectedDay && trekId !== 0 && <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            <div className="group inline-flex">
                                                ID
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <div className="group inline-flex">Name</div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <div className="group inline-flex">
                                                Booked on
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <div className="group inline-flex">
                                                Amount paid (status)
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            <div className="group inline-flex">
                                                Members
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">View</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {bookingsForDate &&
                                        bookingsForDate.length > 0 &&
                                        bookingsForDate.map((booking: any) => (
                                            <tr
                                                key={booking?.id}
                                            //   onClick={() => handleBookingClick(booking?.id)}
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {booking?.id}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    {booking?.user?.firstName} {booking?.user?.lastName}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    {getDateStr(booking?.createdAt)}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    ₹{booking?.bookingAmount} ({booking?.paymentStatus}) {booking.cancelled && <span className="text-red-500 font-bold">CANCELLED</span>}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    {/* {booking?.members.length} */}
                                                    {booking?.members?.map((m: any) => <div className='mt-2'>
                                                        {m.title} {m.name}
                                                    </div>)}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-indigo-400 text-right text-sm font-medium sm:pr-6">
                                                    {booking?.members?.map((m: any) => <div onClick={() => {setSelectedUserCert({
                                                        name: m.name.toUpperCase(),
                                                        trekName: getTrekName(),
                                                        date: getDate(booking?.scheduleDate),
                                                        location: getTrekLocation(),
                                                        height: getTrekHeight(),
                                                    }); return setModal(true)}}  className='mt-2 cursor-pointer hover:text-indigo-800'>
                                                        View certificate
                                                    </div>)}
                                                </td>
                                            </tr>
                                        ))}
                                    {bookingsForDate && bookingsForDate.length === 0 && (
                                        <tr className="flex w-full align-center justify-center">
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                No bookings found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
            <Modal open={modal} minWidth="750px" setModal={() => {setSelectedUserCert(null); return setModal(false)}}>
                {selectedUserCert && <PdfContainer name={selectedUserCert.name} trekName={selectedUserCert.trekName} date={selectedUserCert.date} location={selectedUserCert.location} height={selectedUserCert.height}></PdfContainer>}
            </Modal>
        </div>
    )
}