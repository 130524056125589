import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface TrekPayload {
  name: string;
  description: string;
  duration: string;
  price: number;
  location: string;
  altitude: string;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  state: string;
}

export interface filteringParams {
  take: number, 
  skip: number, 
  orderBy: string, 
  orderType: string, 
  keyword: string
}

const getAllUsers = async (params?: filteringParams) => {
  const url = '/users'
  const resp = await apiInstance.get(url, { params })
  // console.log(resp)
  return resp.data;
}

const getUsersByIds = async (payload: number[]) => {
  const url = '/users/byIds'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const useAllUsers = (params?: filteringParams) => {
  return useQuery(['users'], () => getAllUsers(params))
}

const useUsersByIds = (ids: number[]) => {
  return useQuery(['users', ids], () => getUsersByIds(ids))
}

export { getAllUsers, useAllUsers, useUsersByIds }
