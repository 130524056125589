import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number; 
  skip: number;
  orderBy: string;
  orderType: string;
  keyword: string;
}

export interface EnquiryPayload {
    name: string;
    email: string;
    phoneNumber: number;
    title: string;
    description: string;
    trekId: number;
    trekName: string;
}

const getAllEnquiry = async (params?: filteringParams) => {
  const url = '/enquiry'
  const resp = await apiInstance.get(url, { params })
  // console.log(resp)
  return resp.data;
}

const useAllEnquiry = (params?: filteringParams) => {
  return useQuery(['enquiry'], () => getAllEnquiry(params))
}

const createEnquiry = async (payload: EnquiryPayload) => {
  const url = '/enquiry/create'
  return apiInstance.post(url, payload)
}

export { getAllEnquiry, useAllEnquiry, createEnquiry }
