import React, { createContext, useReducer } from 'react';

interface contextType {
  showNotification: boolean;
  notificationType: string;
  notificationTitle: string;
  notificationMessage: string;
  dispatch: (payload: any) => void;
}
export const NotificationContext = createContext<contextType | null>(null);


export const notificationReducer = (state: any, action: any) => {
  console.log(action);
  switch (action.type) {
    case 'SHOW':
      return { showNotification: true, notificationType: action.payload.notificationType, notificationMessage: action.payload.notificationMessage, notificationTitle: action.payload.notificationTitle };
    case 'HIDE':
      return { showNotification: false, notificationType: "", notificationMessage: "", notificationTitle: "" };
    default:
      return state;
  }
};

export const NotificationContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const initialNotificationState = {
    showNotification: false,
    notificationType: "",
    notificationTitle: "",
    notificationMessage: "",
  };

  const [state, dispatch] = useReducer(notificationReducer, initialNotificationState);

  return (
    <NotificationContext.Provider value={{ ...state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};