import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface AdventurePayload {
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  activityDuration: string;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface EditAdventurePayload {
  id: number;
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  activityDuration: string;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface FeaturedPayload {
  adventureId: number;
  type: string;
}

const getAdventureById = async (adventureId: number) => {
  const url = '/adventures/' + adventureId
  const resp = await apiInstance.get(url)
  return resp.data
}

const getScheduleById = async (adventureId: number) => {
  const url = '/schedules/adventure/' + adventureId
  const resp = await apiInstance.get(url)
  return resp.data
}

const createAdventure = async (payload: AdventurePayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const editAdventure = async (payload: EditAdventurePayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const useAdventureById = (adventureId: number) => {
  return useQuery(['adventure', adventureId], () => getAdventureById(adventureId))
}

const useScheduleById = (adventureId: number) => {
  return useQuery(['schedule', adventureId], () => getScheduleById(adventureId))
}

const getAllAdventures = async (params?: filteringParams) => {
  const url = '/adventures';
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const useAllAdventures = (params?: filteringParams) => {
  return useQuery(['adventures'], () => getAllAdventures(params))
}

const uploadImage = async (payload: any, adventureId: number) => {
  const url = '/treks/uploadImage/' + adventureId;
  return apiInstance.post(url, payload);
}

const getFeaturedAdventures = async () => {
  const url = '/featured/active'
  const resp = await apiInstance.get(url)
  return resp.data
}

const getFeaturedAdventuresAll = async (params?: filteringParams) => {
  const url = '/featured'
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const createFeaturedAdventure = async (payload: FeaturedPayload[]) => {
  const url = '/featured/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data
}

const toggleFeatured = async (id: number) => {
  const url = '/featured/toggle';
  const resp = await apiInstance.post(url, id)
  return resp.data;
}

const archiveAdventure = async (id: number) => {
  const url = '/treks/archive/' + id;
  const resp = await apiInstance.put(url)
  return resp.data;
}

const useFeaturedAdventures = () => {
  return useQuery(['FeaturedAdventuresActive'], () => getFeaturedAdventures())
}

const useFeaturedAdventuresAll = (params?: filteringParams) => {
  return useQuery(['FeaturedAdventures'], () => getFeaturedAdventuresAll(params))
}

export { getAdventureById, useAdventureById, getAllAdventures, useAllAdventures, createAdventure, editAdventure, uploadImage, useScheduleById, useFeaturedAdventures, useFeaturedAdventuresAll, getFeaturedAdventures, toggleFeatured, createFeaturedAdventure, archiveAdventure, getFeaturedAdventuresAll }
