import { useContext, useState } from 'react'
import { useMutation, QueryClient } from '@tanstack/react-query'
import { LoginPayload, login } from '../hooks/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { AppLoaderContext } from '../context/AppLoaderContext'



const regex = {Email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, Password: /^().{8,20}$/}

const handleAuthToken = (data: any) => {
    const queryClient = new QueryClient();
    const { accessToken, user } = data
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('userDetails', JSON.stringify(user))
    queryClient.setQueryData(['auth'], (auth: any) => {
        return { ...auth, accessToken }
    }) 
}

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const { dispatch } = useAuthContext();
    const appLoaderContext = useContext(AppLoaderContext);
    const [showValidations, setShowValidation] = useState(false);
    const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState('')
    const errorMessage = ["• Email not valid", "• Password not valid, must be atleast 8 characters."]
    const validator = () => {
        return {email: regex.Email.test(email), password: regex.Password.test(password)};
    }
    const valid = validator();

    const loginMutation = useMutation({
        mutationFn: (payload: LoginPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return login(payload)
        }, 
        onSuccess: ({ data }) => {
            handleAuthToken(data)
            dispatch({ type: 'LOGIN', payload: { user: data.user, token: data.accessToken }, })
            if (data.user.roleId === 2) setFormSubmitErrorMessage('Please login at admin site!')           
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: ({response}) => {
            setFormSubmitErrorMessage(response.data.message);
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
          }

    })

    const loginSubmit = async () => {
        setShowValidation(true);
        (valid.email && valid.password) &&  loginMutation.mutate({email, password });
        
    }

    const listener = (event: any) => {

        if (event.code === "Enter" || event.code === "NumpadEnter") {
            loginSubmit()
            event.preventDefault();
        }
    };

    return (
        <div className='flex min-h-full w-full justify-between'>
           <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto  w-full max-w-sm lg:w-96">
                        
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    onKeyDown={listener}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    onChange={(evt: any) => {setShowValidation(false); setEmail(evt.target.value)}}
                                    required
                                    autoFocus
                                    placeholder='trekker@trekyaari.com'
                                />
                            
                            </div>

                        </div>
                        <div className="my-8">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onKeyDown={listener}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={(evt: any) => {setShowValidation(false); setPassword(evt.target.value)}}
                                    required
        
                                />
                            </div>
                        </div>

                        {showValidations && (!valid.email || !valid.password || formSubmitErrorMessage)? 
                        <div className='bg-red-100 border-red-400 border-2 p-4 text-red-800 rounded-md text-sm'> 
                        {!valid.email && <p> {errorMessage[0]} </p>}
                        {!valid.password && <p> {errorMessage[1]} </p>}
                        {formSubmitErrorMessage && <p> {"• " + formSubmitErrorMessage} </p>}
                        </div>: null}
                        <div className="my-4 flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className=""
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-12"
                                onClick={loginSubmit}
                            >
                                Log in
                            </button>
                        </div>
                        
                    
                </div>
                
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
            <img  src='https://images.unsplash.com/photo-1598524589996-78edc8ddba2e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=830&q=80' className='grow-1'></img>
            </div>
        </div>
    )
}





export default Login;