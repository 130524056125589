import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance, { apiInstanceCsv } from '../api-service';
import { filteringParams } from './user';
import axios from 'axios';

export interface SchedulePayload {
  id?: number;
  trekId: number;
  scheduleDate: string;
  scheduleType: string;
  totalAmount?: number;
  bookingAmount?: number;
}
export interface SlotPayload {
  id?: number;
  scheduleId?: number;
  availableSlots?: number;
  totalSlots: number;
}

export interface BookingsPayload {
  scheduleId: number;
  userId: number;
  amount: number;
  paymentStatus: string;
  tripId?: number;
  tripDate: string;
  razorpayOrderId?: string;
  couponId?: number;
  members?: any[];
}
export interface reportParams {
  startDate: string;
  endDate: string;
}

const getAllBookings = async (params?: filteringParams) => {
  const url = '/bookings';
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const getBookingById = async (bookingId: number) => {
  const url = '/bookings/' + bookingId;
  const resp = await apiInstance.get(url)
  return resp.data
}

const getBookingsByUser = async () => {
  const url = '/bookings/user/';
  const resp = await apiInstance.get(url)
  return resp.data
}

const getBookingsByScheduleId = async (scheduleId: number) => {
  const url = '/bookings/schedule/' + scheduleId;
  const resp = await apiInstance.get(url)
  return resp.data
}

const getCancelBooking = async (bookingId: number) => {
  const url = '/bookings/cancel';
  const resp = await apiInstance.post(url, { bookingId })
  return resp.data
}

const getBookingsCsv = async (params: reportParams) => {
  const url = '/reports';
  const resp = await apiInstanceCsv.get(url, { params })
  return resp
}

const getBookingsByUserId = async (payload: { id: number }) => {
  const url = '/bookings/userId';
  const resp = await apiInstance.post(url, payload)
  return resp.data
}

const getAllSchedules = async (params?: filteringParams) => {
  const url = '/schedules';
  const resp = await apiInstance.get(url, { params })
  return resp.data;
}

const getSchedulesByTrekId = async (trekId: number) => {
  const url = '/schedules/trek/' + trekId;
  const resp = await apiInstance.get(url)
  return resp.data;
}

const getSlotsFromSchedules = async (payload: number[]) => {
  const url = '/slots/byScheduleIds';
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const getAllSlots = async () => {
  const url = '/slots';
  const resp = await apiInstance.get(url)
  return resp.data;
}

const saveSlot = async (slot: SlotPayload) => {
  const url = '/slots/create';
  const resp = await apiInstance.post(url, slot)
  return resp.data;
}

const getScheduleByIds = async (payload: any) => {
  const url = '/schedules/byIds'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const useAllBookings = (params?: filteringParams) => {
  return useQuery(['bookings'], () => getAllBookings(params))
}

const useBookingById = (bookingId: number) => {
  return useQuery(['bookings', bookingId], () => getBookingById(bookingId))
}

const useBookingsByUser = () => {
  return useQuery(['user_bookings'], () => getBookingsByUser())
}

const useBookingsByUserId = (id: number) => {
  return useQuery(['user_bookings', id], () => getBookingsByUserId({ id }))
}

const useAllSchedules = (params?: filteringParams) => {
  return useQuery(['schedules'], () => getAllSchedules(params))
}

const useSchedulesByTrekId = (trekId: number) => {
  return useQuery(['schedules', trekId], () => getSchedulesByTrekId(trekId))
}

const useSchedulesByIds = (ids: number[]) => {
  return useQuery(['schedules', ids], () => getScheduleByIds(ids))
}

const useSlotsFromSchedules = (scheduleIds: number[]) => {
  return useQuery(['slots', scheduleIds], () => getSlotsFromSchedules(scheduleIds))
}

const useAllSlots = () => {
  return useQuery(['slots'], () => getAllSlots())
}

const createSlot = async (payload: SlotPayload) => {
  const url = '/slots/update'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const createSchedule = async (payload: any) => {
  const url = '/schedules/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const createBooking = async (payload: BookingsPayload) => {
  const url = '/bookings/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

export {  getCancelBooking, useAllBookings, useAllSchedules, getAllSchedules, getSchedulesByTrekId, useSchedulesByTrekId, createSchedule, getScheduleByIds, createSlot, useSchedulesByIds, useAllSlots, saveSlot ,getAllSlots, createBooking, useBookingById, getBookingById, getBookingsByUser, getBookingsByScheduleId, useBookingsByUser, getAllBookings, useSlotsFromSchedules, useBookingsByUserId, getBookingsByUserId, getBookingsCsv }
