import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentMagnifyingGlassIcon, LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editTrek, EditTrekPayload, archiveTrek } from '../hooks/trek';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import { MetaPayload, createMeta } from '../hooks/meta';

export default function EditMeta({ open, setOpen, metaEntry }: { metaEntry: MetaPayload, open: boolean, setOpen: (arg: boolean) => void }) {
  const queryClient = useQueryClient();
  const appLoaderContext = useContext(AppLoaderContext);
  const notificationContext = useContext(NotificationContext);

  const [metaForm, setMetaForm] = useState<MetaPayload>({
    id: metaEntry.id,
    type: metaEntry.type,
    title: metaEntry.title,
    description: metaEntry.description,
    keywords: metaEntry.keywords,
    image: metaEntry.image
  });

  const createMetaMutation = useMutation({
    mutationFn: (payload: MetaPayload) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return createMeta(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['meta'])
      setOpen(false)
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Meta edited!",
          notificationMessage: "The meta was edited successfully."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: "There was an error while editing the meta."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  })

  useEffect(() => {
    setMetaForm(metaEntry);
  }, [metaEntry]);

  const [getdifficultyLevel, setdifficultyLevel] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [months, setSeasons] = useState([
    { value: 'JANUARY', label: 'January', checked: false },
    { value: 'FEBRUARY', label: 'February', checked: false },
    { value: 'MARCH', label: 'March', checked: false },
    { value: 'APRIL', label: 'April', checked: false },
    { value: 'MAY', label: 'May', checked: false },
    { value: 'JUNE', label: 'June', checked: false },
    { value: 'JULY', label: 'July', checked: false },
    { value: 'AUGUST', label: 'August', checked: false },
    { value: 'SEPTEMBER', label: 'September', checked: false },
    { value: 'OCTOBER', label: 'October', checked: false },
    { value: 'NOVEMBER', label: 'November', checked: false },
    { value: 'DECEMBER', label: 'December', checked: false },
  ])

  const handleFormChange = ({ key, value }: { key: string, value: string }) => {
    setMetaForm({ ...metaForm, [key]: value })
  }

  const handleEditSubmit = (event: any) => {
    event.preventDefault();
    createMetaMutation.mutate({ ...metaForm });
    // setOpen(false);
  };

  return (<>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">{metaForm?.type}</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Edit the information below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                Title
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="title" id="title"
                                  defaultValue={metaForm?.title}
                                  onChange={(evt) => handleFormChange({ key: 'title', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Description
                              </label>
                              <div className="mt-1">
                                <textarea
                                  rows={4} name="description" id="description"
                                  defaultValue={metaForm?.description}
                                  onChange={(evt) => handleFormChange({ key: 'description', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="keywords" className="block text-sm font-medium text-gray-900">
                                Keywords
                              </label>
                              <div className="mt-1">
                                <textarea
                                  name="keywords" id="keywords" rows={4}
                                  defaultValue={metaForm?.keywords}
                                  onChange={(evt) => handleFormChange({ key: 'keywords', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="image" className="block text-sm font-medium text-gray-900">
                                Image URL
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="image" id="image"
                                  defaultValue={metaForm?.image}
                                  onChange={(evt) => handleFormChange({ key: 'image', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={handleEditSubmit}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </>)
}
