import { Fragment, useContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAdventure, AdventurePayload } from '../hooks/adventures';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';

const locationOption = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Nepal"
]

const defaultAdventureFormValues = {
  name: '',
  description: '',
  duration: 0,
  activityDuration: '',
  defaultBookingAmount: 0,
  defaultTotalAmount: 0,
  location: '',
  altitude: 0,
  bestTime: '',
  pickupPoint: 'none',
  difficultyLevel: '',
  state: 'ACTIVE',
  defaultSlots: 0,
  contactPersonName: "",
  contactPersonNumber: "",
  reportingTime: "none",
  reportingLocation: "none",
  type: "ADVENTURE",
  totalTrekDistance: "none",
  sortOrder: 0,
  videoUrl: "",
  keywords: '',
  title: ''
}

export default function CreateAdventure({ open, setOpen }: { open: boolean, setOpen: (arg: boolean) => void }) {
  const queryClient = useQueryClient();
  const appLoaderContext = useContext(AppLoaderContext);
  const notificationContext = useContext(NotificationContext);

  const [formError, setFormError] = useState<boolean>(false)
  const [months, setMonths] = useState([
    { value: 'JANUARY', label: 'January', checked: false },
    { value: 'FEBRUARY', label: 'February', checked: false },
    { value: 'MARCH', label: 'March', checked: false },
    { value: 'APRIL', label: 'April', checked: false },
    { value: 'MAY', label: 'May', checked: false },
    { value: 'JUNE', label: 'June', checked: false },
    { value: 'JULY', label: 'July', checked: false },
    { value: 'AUGUST', label: 'August', checked: false },
    { value: 'SEPTEMBER', label: 'September', checked: false },
    { value: 'OCTOBER', label: 'October', checked: false },
    { value: 'NOVEMBER', label: 'November', checked: false },
    { value: 'DECEMBER', label: 'December', checked: false },
  ])
  const [formErrorMessage, setFormErrorMessage] = useState<string>('')
  const [adventureForm, setAdventureForm] = useState<AdventurePayload>(defaultAdventureFormValues)
  const createAdventureMutation = useMutation({
    mutationFn: (payload: AdventurePayload) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return createAdventure(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['adventures'])
      setAdventureForm(defaultAdventureFormValues)
      setOpen(false)
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Adventure created!",
          notificationMessage: "The adventure was created successfully."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: "There was an error while creating the adventure."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  })

  const handleFormChange = ({ key, value }: { key: string, value: string }) => {
    if (["defaultTotalAmount", "defaultBookingAmount", "defaultSlots", "duration", "altitude", "sortOrder"].includes(key)) {
      setAdventureForm({ ...adventureForm, [key]: parseInt(value) })
    } else {
      setAdventureForm({ ...adventureForm, [key]: value })
    }
    if (formError) {
      setFormError(false);
      setFormErrorMessage('');
    }
  }

  const handleSubmit = async () => {
    if (adventureForm['defaultBookingAmount'] <= adventureForm['defaultTotalAmount']) {
      let bestTimeArr: string[] = []
      months.map(a => {
        if (a.checked) {
          bestTimeArr.push(a.value[0])
        } else {
          bestTimeArr.push("0")
        }
      })
      adventureForm.bestTime = bestTimeArr.join('');
      const resp = await createAdventureMutation.mutate({ ...adventureForm })
      console.log(resp)
      if (formError) {
        setFormError(false);
        setFormErrorMessage('');
      }
    } else {
      setFormError(true);
      setFormErrorMessage("Booking amount cannot be greater than total amount!")
    }
  }

  function handleCheck(e: any) {
    const newMonths = months;
    const idx = months.findIndex(s => s.value === e.target.name);
    newMonths[idx] = {
      ...newMonths[idx],
      checked: e.target.checked
    }
    setMonths(newMonths)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">New Adventure</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to create your new adventure.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                                Adventure name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="name" id="name"
                                  value={adventureForm?.name}
                                  onChange={(evt) => handleFormChange({ key: 'name', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                Title
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="title" name="title" rows={4}
                                  value={adventureForm?.title}
                                  onChange={(evt) => handleFormChange({ key: 'title', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Description
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="description" name="description" rows={4}
                                  value={adventureForm?.description}
                                  onChange={(evt) => handleFormChange({ key: 'description', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="keywords" className="block text-sm font-medium text-gray-900">
                                Keywords
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="keywords" name="keywords" rows={4}
                                  value={adventureForm?.description}
                                  onChange={(evt) => handleFormChange({ key: 'keywords', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="videoUrl" className="block text-sm font-medium text-gray-900">
                                Video URL
                              </label>
                              <div className="mt-1">
                                <input
                                  id="videoUrl" name="videoUrl"
                                  type="text"
                                  value={adventureForm?.videoUrl}
                                  onChange={(evt) => handleFormChange({ key: 'videoUrl', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                Location
                              </label>
                              <select
                                id="location"
                                name="location"
                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                defaultValue={adventureForm['location']}
                                onChange={(evt) => handleFormChange({ key: 'location', value: evt.target.value })}
                              >
                                <option value="">Select</option>
                                {locationOption?.map(l => <option key={l} value={l}>{l}</option>)}
                              </select>
                            </div> */}
                            <div>
                              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                Location
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="location" id="location"
                                  value={adventureForm?.location}
                                  onChange={(evt) => handleFormChange({ key: 'location', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="duration" className="block text-sm font-medium text-gray-900">
                                Duration
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="duration" id="duration"
                                  value={adventureForm?.duration}
                                  onChange={(evt) => handleFormChange({ key: 'duration', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            <div>
                              <label htmlFor="activityDuration" className="block text-sm font-medium text-gray-900">
                                Activity Duration
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="activityDuration" id="activityDuration"
                                  value={adventureForm?.activityDuration}
                                  onChange={(evt) => handleFormChange({ key: 'activityDuration', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="totalAmount" className="block text-sm font-medium text-gray-700">
                                Total Amount
                              </label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">₹</span>
                                </div>
                                <input
                                  type="text"
                                  name="totalAmount"
                                  id="totalAmount"
                                  className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="0.00"
                                  value={adventureForm?.defaultTotalAmount}
                                  onChange={(evt) => handleFormChange({ key: 'defaultTotalAmount', value: (evt.target.value) })}
                                  aria-describedby="price-currency"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="bookingAmount" className="block text-sm font-medium text-gray-700">
                                Booking Amount
                              </label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">₹</span>
                                </div>
                                <input
                                  type="text"
                                  name="bookingAmount"
                                  id="bookingAmount"
                                  className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="0.00"
                                  value={adventureForm?.defaultBookingAmount}
                                  onChange={(evt) => handleFormChange({ key: 'defaultBookingAmount', value: (evt.target.value) })}
                                  aria-describedby="price-currency"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="defaultSlots" className="block text-sm font-medium text-gray-900">
                                Default Slots
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number" name="defaultSlots" id="defaultSlots"
                                  value={adventureForm?.defaultSlots}
                                  min={0}
                                  onChange={(evt) => handleFormChange({ key: 'defaultSlots', value: (evt.target.value) })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="altitude" className="block text-sm font-medium text-gray-900">
                                Altitude
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="altitude" id="altitude"
                                  value={adventureForm?.altitude}
                                  onChange={(evt) => handleFormChange({ key: 'altitude', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            <div>
                              <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                                Best Time
                              </label>
                              <div className="space-y-6">
                                {months.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-${option.value}-${optionIdx}`}
                                      name={`${option.value}`}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={handleCheck}
                                    />
                                    <label
                                      htmlFor={`filter-${option.value}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                                Pickup Point
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="project-name" id="project-name"
                                  value={adventureForm?.pickupPoint}
                                  onChange={(evt) => handleFormChange({ key: 'pickupPoint', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            <div>
                              <label htmlFor="contactPersonName" className="block text-sm font-medium text-gray-900">
                                Contact Person Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="contactPersonName" id="contactPersonName"
                                  value={adventureForm?.contactPersonName}
                                  onChange={(evt) => handleFormChange({ key: 'contactPersonName', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="contactPersonNumber" className="block text-sm font-medium text-gray-900">
                                Contact Person Number
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="contactPersonNumber" id="contactPersonNumber"
                                  value={adventureForm?.contactPersonNumber}
                                  onChange={(evt) => handleFormChange({ key: 'contactPersonNumber', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label htmlFor="reportingTime" className="block text-sm font-medium text-gray-900">
                                Reporting Time
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="reportingTime" id="reportingTime"
                                  value={adventureForm?.reportingTime}
                                  onChange={(evt) => handleFormChange({ key: 'reportingTime', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            {/* <div>
                              <label htmlFor="reportingLocation" className="block text-sm font-medium text-gray-900">
                                Reporting Location
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="reportingLocation" id="reportingLocation"
                                  value={adventureForm?.reportingLocation}
                                  onChange={(evt) => handleFormChange({ key: 'reportingLocation', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            {/* <div>
                              <label htmlFor="type" className="block text-sm font-medium text-gray-900">
                                Type
                              </label>
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id="TREK"
                                    name="notification-method"
                                    type="radio"
                                    onChange={(e) => handleFormChange({ key: 'type', value: e.target.id })}
                                    defaultChecked={adventureForm.type === 'TREK'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="featured" className="ml-3 block text-sm font-medium text-gray-700">
                                    Trek
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="EXPEDITION"
                                    name="notification-method"
                                    type="radio"
                                    onChange={(e) => handleFormChange({ key: 'type', value: e.target.id })}
                                    defaultChecked={adventureForm.type === 'EXPEDITION'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="footer" className="ml-3 block text-sm font-medium text-gray-700">
                                    Expedition
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="ADVENTURE"
                                    name="notification-method"
                                    type="radio"
                                    onChange={(e) => handleFormChange({ key: 'type', value: e.target.id })}
                                    defaultChecked={adventureForm.type === 'ADVENTURE'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label htmlFor="footer" className="ml-3 block text-sm font-medium text-gray-700">
                                    Adventure
                                  </label>
                                </div>
                              </div>
                            </div> */}
                            {/* <div>
                              <label htmlFor="totalTrekDistance" className="block text-sm font-medium text-gray-900">
                                Total Adventure Distance
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="totalTrekDistance" id="totalTrekDistance"
                                  value={adventureForm?.totalTrekDistance}
                                  onChange={(evt) => handleFormChange({ key: 'totalTrekDistance', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                            <div>
                              <label htmlFor="sortOrder" className="block text-sm font-medium text-gray-900">
                                Sort order
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="sortOrder" id="sortOrder"
                                  value={adventureForm?.sortOrder}
                                  onChange={(evt) => handleFormChange({ key: 'sortOrder', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {formError && <div className='pr-2 text-xs font-medium text-red-700'>
                        {formErrorMessage}
                      </div>}
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleSubmit()}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
