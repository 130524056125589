
import React, { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

interface contextType {
    user: any
    dispatch: (payload: any) => void,
    isUserAuthenticated: () => boolean,
    isAdmin: () => boolean,
}
export const AuthContext = createContext<contextType | null>(null);

export const authReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'LOGIN':   
            return { user: action.payload.user, token: action.payload.token }
        case 'LOGOUT':
            return { user: null, token: null }
        default: 
            return state;
    }
}

export const AuthContextProvider = ({ children }: { children: React.ReactElement }) => {
    
    const initialAuthState = {
        user: localStorage.getItem('userDetails'),
        token: localStorage.getItem('accessToken'),
    }

    useEffect(() => {
        let localUserDetails = localStorage.getItem('userDetails')
        if(typeof localUserDetails === 'string'){
            localUserDetails = JSON.parse(localUserDetails)
        }
        // const userDetails = localUserDetails
        const accessToken = localStorage.getItem('accessToken')

        if(localUserDetails && accessToken) {
            // const savedUser = userDetails
            if(localUserDetails) dispatch({ type: 'LOGIN', payload: { user: localUserDetails, token: accessToken } })
        }
    }, [])
    
    const [state, dispatch] = useReducer(authReducer, initialAuthState)

    const isUserAuthenticated = () => {
        if (!state.token) {
          return false;
        }
        return true
    };

    const isAdmin = () => {
        if(!state.token) {
            return false;
        }
        if(typeof state.user === 'string') {
            const obj = JSON.parse(state.user)
            console.log("localUserDetails", obj)
            return obj.roleId === 2
        }
        return state.user.roleId === 2;
    }

    return (
        <AuthContext.Provider value={{ ...state, dispatch, isUserAuthenticated, isAdmin }}>
            {children}
        </AuthContext.Provider>
    )
}

// export const ProtectedRoute = ({ children }: any) => {
//     const userContext = useAuthContext();
//     const navigate = useNavigate();
//     useEffect(() => {
//         console.log(userContext)
//         if(!userContext.isUserAuthenticated() || (userContext.isUserAuthenticated() && !userContext.isAdmin())){
//             navigate('/login')
//         } else {
//             navigate('/')
//         }
//     },[])
//     return children;
// }