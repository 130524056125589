import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface TreksInventoryCreatePayload {
    inventoryId: number;
    trekIds: number[];
    disable: boolean;
    bookingAmount: number;
    totalAmount: number;
}

export interface TreksInventoryEditPayload {
    id: number;
    inventoryId: number;
    trekIds: number[];
    disable: boolean;
    bookingAmount: number;
    totalAmount: number;
}

const getAllTreksInventory = async (params?: filteringParams) => {
    const url = 'treks-inventory/';
    const resp = await apiInstance.get(url, { params })
    return resp.data;
};
  
const useAllTreksInventory = (params?: filteringParams) => {
    return useQuery(['trekinventories'], () => getAllTreksInventory(params))
};

const getTrekInventoryById = async (inventoryId: number) => {
    const url = '/treks-inventory/inventory/' + inventoryId;
    const resp = await apiInstance.get(url);
    return resp.data;
};

const getTrekInventoryByTrekId = async (trekId: number) => {
    const url = '/treks-inventory/trek/' + trekId + '/all';
    const resp = await apiInstance.get(url);
    return resp.data;
};

const archiveTrekInventory = async (id: number) => {
    const url = '/treks-inventory/archive/' + id;
    const resp = await apiInstance.put(url)
    return resp.data;
  }

const useTrekInventoryById = (inventoryId: number) => {
    return useQuery(['trek-inventory', inventoryId], () => getTrekInventoryById(inventoryId))
};

const createTrekInventory = async (payload: TreksInventoryCreatePayload) => {
    const url = '/treks-inventory/createOrUpdate';
    return apiInstance.post(url, payload);
};

const updateTrekInventory = async (payload: TreksInventoryEditPayload) => {
    const url = '/treks-inventory/createOrUpdate';
    return apiInstance.post(url, payload);
};

const toggleTrekInventory = async (inventoryId: number) => {
    const url = '/treks-inventory/toggle/' + inventoryId;
    return apiInstance.put(url);
};

export { getAllTreksInventory, useAllTreksInventory, getTrekInventoryById, useTrekInventoryById, createTrekInventory, updateTrekInventory, toggleTrekInventory, archiveTrekInventory, getTrekInventoryByTrekId };

