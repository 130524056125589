import { Fragment, useContext, useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, XMarkIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { Menu, Transition, Switch } from '@headlessui/react'
import {
    endOfWeek,
    startOfMonth,
    startOfToday,
    eachDayOfInterval,
    endOfMonth,
    format,
    isEqual,
    isToday,
    isSameMonth,
    parse,
    add,
    startOfWeek,
    addDays,
    isBefore
} from 'date-fns'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SlotPayload, getAllSchedules, useSchedulesByTrekId } from "../hooks/bookings";
import { useAllTreks } from '../hooks/trek'
import { createSchedule, SchedulePayload, useSlotsFromSchedules, createSlot } from '../hooks/bookings'
import Modal from "../components/Modal";
import { AppLoaderContext } from '../context/AppLoaderContext';
import { filteringParams } from '../hooks/user';
import Pagination from '../components/Pagination';
import { useAllAdventures } from '../hooks/adventures'
import { useAllExpeditions } from '../hooks/expeditions'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Schedules() {
    const appLoaderContext = useContext(AppLoaderContext)
    const { data, isLoading, isError, error } = useAllTreks({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    const { data: advData } = useAllAdventures({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    const { data: expData } = useAllExpeditions({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    })
    const defaultSchedule = {
        id: 0,
        trekId: 0,
        scheduleDate: "",
        scheduleType: "",
        totalAmount: 0,
        bookingAmount: 0
    }
    let today = startOfToday();
    let [individualDays, setIndividualDays] = useState<Date[]>([])
    let [removedDays, setRemovedDays] = useState<Date[]>([])
    let [schedulesTable, setSchedulesTable] = useState<any[]>([])
    let [selectedDay, setSelectedDay] = useState(today)
    let [endSelectedDay, setEndSelectedDay] = useState(today)
    let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    let [settingDateType, setSettingDateType] = useState('2') // 0(start date), 1(end date) & 2(individual)
    let [trekId, setTrekId] = useState(0)
    const [selectedScheduleType, setSelectedScheduleType] = useState(false)
    const [selectedScheduleTotalSlots, setSelectedScheduleTotalSlots] = useState(0)
    const [selectedScheduleTotalAmount, setSelectedScheduleTotalAmount] = useState(0)
    const [selectedScheduleBookingAmount, setSelectedScheduleBookingAmount] = useState(0)
    const [editSchedules, setEditSchedules] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [orderByAll, setOrderByAll] = useState("id");
    const [orderTypeAll, setOrderTypeAll] = useState("ASC");
    const [selectedSchedule, setSelectedSchedule] = useState<SchedulePayload>(defaultSchedule);
    const [allSchedules, setAllSchedules] = useState<SchedulePayload[]>([]);
    const [allSchedulesTotal, setAllSchedulesTotal] = useState<number>(0);
    const [modal, setModal] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [editSchedulePriceId, setEditSchedulePriceId] = useState<number | undefined>(0);
    const [editFormData, setEditFormData] = useState<SchedulePayload>({
        id: 0,
        trekId: 0,
        scheduleDate: '',
        totalAmount: 0,
        bookingAmount: 0,
        scheduleType: ''
    })
    const queryClient = useQueryClient();
    const createScheduleMutation = useMutation({
        mutationFn: (payload: SchedulePayload[]) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createSchedule(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['schedules'])
            if (trekId === 0) {
                getAllSchedulesMutation.mutateAsync({ take: 10, skip, orderBy: orderByAll, orderType: orderTypeAll, keyword: '' })
            }
            resetDates()
        }, onError: (err) => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            console.log(err)
            resetDates()
        }
    })
    const createSlotMutation = useMutation({
        mutationFn: (payload: SlotPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createSlot(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['slots'])
            queryClient.invalidateQueries(['schedules'])
            resetDates()
        }, onError: (err) => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            console.log(err)
            resetDates()
        }
    })
    const getAllSchedulesMutation = useMutation({
        mutationFn: (params: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllSchedules(params)
        },
        onSuccess: (data) => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setAllSchedules(data.schedules)
            setAllSchedulesTotal(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    // const allSchedules = useAllSchedules({ take: 5, skip: 0, orderBy: orderBy, orderType: orderType, keyword: '' })?.data?.schedules;
    // console.log("allSchedules:", allSchedules)
    const scheduleData = useSchedulesByTrekId(trekId);
    const treks = data?.treks;
    const adventures = advData?.adventures;
    const expeditions = expData?.expeditions;
    const schedules = scheduleData?.data?.schedules;
    const scheduleIds = schedules?.map((s: SchedulePayload) => s.id);
    const slots = useSlotsFromSchedules(scheduleIds)?.data?.slots;

    useEffect(() => {
        const orderedSchedules = getOrderedSchedules(schedules, orderBy, orderType)
        setSchedulesTable(orderedSchedules);
    }, [schedules])

    useEffect(() => {
        if (trekId === 0) {
            getAllSchedulesMutation.mutateAsync({ take: 10, skip, orderBy: orderByAll, orderType: orderTypeAll, keyword: '' })
        }
    }, [trekId])

    function getOrderedSchedules(schedules: any[], orderBy: string, orderType: string): any[] {
        if (orderType === "ASC") {
            return schedules?.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1)
        } else {
            return schedules?.sort((a, b) => a[orderBy] < b[orderBy] ? 1 : -1)
        }
    }

    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

    let newDays = eachDayOfInterval({
        start: startOfWeek(startOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 })
    })

    const previousMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const nextMonth = () => {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    const selectTrek = (val: string) => {
        setTrekId(Number(val));
    }

    function resetDates(resetMonth: boolean = false) {
        setIndividualDays([])
        setRemovedDays([])
        setSelectedDay(today)
        setEndSelectedDay(today)
        setSettingDateType('2')
        resetMonth && setCurrentMonth(format(today, 'MMM-yyyy'))
    }

    const handleCreateSchedule = () => {
        let arr: SchedulePayload[] = [];
        let arr2: SchedulePayload[] = [];
        let arr3: SchedulePayload[] = [];
        if (individualDays.length === 0 && removedDays.length === 0) return;
        individualDays.map(id => {
            arr.push({
                trekId,
                scheduleDate: format(id, 'yyyy-MM-dd'),
                scheduleType: "ON"
            })
        })
        arr = arr.filter(a => {
            let exists: boolean = false;
            schedules.map((d: any) => {
                if (a.scheduleDate === d.scheduleDate && d.scheduleType === a.scheduleType) {
                    exists = true;
                }
            })
            return !exists;
        })
        arr2 = removedDays.map(rd => schedules?.find((tr: any) => tr.scheduleDate === format(rd, 'yyyy-MM-dd')))
        arr2.map((a, i) => arr3.push({
            trekId: a.trekId,
            scheduleDate: a.scheduleDate,
            scheduleType: "OFF"
        }))
        createScheduleMutation.mutateAsync(arr.concat(arr3))
    }

    const isSelectedIndividualDates = (day: any) => {
        let exists: boolean = false;
        individualDays.map(d => {
            if (isEqual(d, day)) {
                exists = true;
            }
        })
        return exists;
    }

    const isPreSelected = (day: any, type: string = 'ON'): boolean => {
        let exists: boolean = false;
        schedules?.map((d: any) => {
            let temp = format(day, 'yyyy-MM-dd')
            if (temp === d.scheduleDate && d.scheduleType === type) {
                exists = true;
            }
        })
        return exists;
    }

    const isRemoved = (day: any): boolean => {
        let exists: boolean = false;
        removedDays.map((d: any) => {
            if (isEqual(d, day)) {
                exists = true;
            }
        })
        return exists;
    }

    const checkPastDate = (day: any): boolean => {
        return isBefore(day, today);
    }

    const setDay = (day: any) => {
        if (settingDateType === '0') {
            if (day > selectedDay) {
                setSelectedDay(day)
                setSettingDateType('1')
                setEndSelectedDay(day)
                setIndividualDays([...individualDays, day]);
            } else {
                setSelectedDay(day)
                setSettingDateType('1')
                setIndividualDays([...individualDays, day]);
            }
        } else if (settingDateType === '1') {
            if (day > selectedDay) {
                setEndSelectedDay(day)
                returnDays(selectedDay, day)
                setSettingDateType('2')
            }
        } else if (settingDateType === '2') {
            if (isSelectedIndividualDates(day)) {
                let foundIdx = -1;
                let arr: Date[] = [];
                let arr2: Date[] = [];
                individualDays.map((d, idx) => {
                    if (isEqual(d, day)) {
                        foundIdx = idx
                    }
                })
                if (foundIdx > -1) {
                    arr = individualDays;
                    arr2 = arr.splice(foundIdx, 1);
                    setIndividualDays([...arr]);
                }
            } else {
                isPreSelected(day) ? handleRemovedDays(day) : setIndividualDays([...individualDays, day]);
            }
        }
    }

    const removeDuplicates = (dateArray: Date[]) => {
        return dateArray.map(function (date) {
            return date.getTime()
        }).filter(function (date, i, array) {
            return array.indexOf(date) === i;
        }).map(function (time) {
            return new Date(time);
        });
    }

    const handleRemovedDays = (day: Date) => {
        let foundIdx = -1;
        let arr = [];
        let arr2: Date[] = [];
        removedDays.map((d, idx) => {
            if (isEqual(d, day)) {
                foundIdx = idx
            }
        })
        if (foundIdx > -1) {
            arr = removedDays;
            arr2 = arr.splice(foundIdx, 1);
            setRemovedDays([...arr]);
        } else {
            setRemovedDays([...removedDays, day]);
        }
    }

    const returnDays = (startDate: Date, endDate: Date) => {
        let newDays: Date[] = []
        for (let i = startDate; isBefore(i, addDays(endDate, 1)); i = addDays(i, 1)) {
            individualDays.map(d => {
                if (!isEqual(d, i)) {
                    return newDays.push(i)
                }
            })
        }
        let newArray = removeDuplicates(individualDays.concat(removeDuplicates(newDays)))
        newArray = newArray.filter(na => {
            let exists: boolean = false;
            schedules?.map((d: any) => {
                let temp = format(na, 'yyyy-MM-dd')
                if (temp === d.scheduleDate && d.scheduleType === 'ON') {
                    exists = true;
                }
            })
            return !exists;
        })
        setIndividualDays(newArray)
    }

    function handleEditClick(schedule: SchedulePayload) {
        setSelectedSchedule(schedule);
        setModal(true)
        setSelectedScheduleType(schedule.scheduleType === "ON")
        setSelectedScheduleTotalAmount(schedule.totalAmount || 0)
        setSelectedScheduleBookingAmount(schedule.bookingAmount || 0)
        setSelectedScheduleTotalSlots(getSlotsData(schedule.id || 0, "totalSlots"))
    }

    function handleClose() {
        setSelectedSchedule(defaultSchedule);
        setModal(false)
        setSelectedScheduleType(false)
        setSelectedScheduleTotalAmount(0)
        setSelectedScheduleBookingAmount(0)
        setSelectedScheduleTotalSlots(0)
        setSaveError(false)
        setSaveErrorMessage("")
    }

    const handleOrderChange = (orderByString: string): void => {
        let obStr = "ASC"
        let obyStr = ""
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? obStr = "DESC" : obStr = "ASC"
        } else {
            setOrderBy(orderByString)
        }
        obyStr = orderByString
        const orderedSchedules = getOrderedSchedules(schedules, obyStr, obStr)
        setSchedulesTable(orderedSchedules);
    }

    const handleOrderChangeAllSch = (orderByString: string): void => {
        let obStr = "ASC"
        let obyStr = ""
        if (orderByAll === orderByString) {
            orderTypeAll === "ASC" ? setOrderTypeAll("DESC") : setOrderTypeAll("ASC")
            orderTypeAll === "ASC" ? obStr = "DESC" : obStr = "ASC"
        } else {
            setOrderByAll(orderByString)
        }
        obyStr = orderByString
        getAllSchedulesMutation.mutateAsync({ take: 10, skip, orderBy: orderByString, orderType: obStr, keyword: '' })
    }

    function getSlotsData(scheduleId: number, type: string): number {
        const slot = slots?.find((s: SlotPayload) => s.scheduleId === scheduleId);
        return slot ? slot[type] : 0;
    }

    function checkDisabled(): boolean {
        if (selectedScheduleBookingAmount !== selectedSchedule.bookingAmount || selectedScheduleTotalAmount !== selectedSchedule.totalAmount || selectedScheduleTotalSlots !== getSlotsData(selectedSchedule.id || 0, "totalSlots") || (selectedScheduleType ? "ON" : "OFF") !== selectedSchedule.scheduleType) return false;
        else return true;
    }

    function handleClick(): void {
        if (selectedScheduleBookingAmount !== selectedSchedule.bookingAmount || selectedScheduleTotalAmount !== selectedSchedule.totalAmount || (selectedScheduleType ? "ON" : "OFF") !== selectedSchedule.scheduleType) editSchedule();
        if (selectedScheduleTotalSlots !== getSlotsData(selectedSchedule.id || 0, "totalSlots")) editSlot()
        handleClose()
    }

    function editSchedule() {
        const newSchedule: SchedulePayload = {
            ...selectedSchedule,
            "totalAmount": selectedScheduleTotalAmount,
            "bookingAmount": selectedScheduleBookingAmount,
            "scheduleType": selectedScheduleType ? "ON" : "OFF"
        }
        if ((newSchedule.bookingAmount || 0) > (newSchedule.totalAmount || 0)) {
            setSaveError(true)
            setSaveErrorMessage("Booking amount cannot be greater than total amount!")
        } else {
            createScheduleMutation.mutateAsync([newSchedule]);
        }
    }

    function editSlot() {
        const slot = slots?.find((s: SlotPayload) => s.scheduleId === selectedSchedule.id);
        const newSlot: SlotPayload = {
            ...slot,
            "totalSlots": selectedScheduleTotalSlots
        }
        createSlotMutation.mutateAsync(newSlot);
    }

    function pageReset(): void {
        setActivePage(1);
        setSkip(0);
    }

    function handlePageChange(page: number): void {
        setSkip(10 * (page - 1));
        getAllSchedulesMutation.mutateAsync({
            take: 10,
            skip: 10 * (page - 1),
            orderBy,
            orderType,
            keyword: "",
        });
    }

    if (isLoading) {
        return (<>
            <span>Loading...</span>
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            <span>error...</span>
        </>)
    }

    if (editSchedules) {
        return (<>
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center mb-6">
                        <div className="flex justify-left items-center sm:flex-auto">
                            <div onClick={() => setEditSchedules(false)} className='flex justify-left items-center border-2 rounded px-2 py-1 mr-2 cursor-pointer'>
                                <ChevronLeftIcon className="h-5 w-5 flex" aria-hidden="true" />
                                <p>Go back</p>
                            </div>
                            <div className="flex justify-center flex-1 items-center gap-3   ">
                                <h1 className="text-xl font-semibold text-gray-900">Edit schedules and slots</h1>
                                {treks?.filter((t: any) => t.id === trekId).map(
                                    (t: any) => <p key={t.id} className="text-lg font-semibold text-gray-900">({t.name})</p>)
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        <div className="group inline-flex">
                                            ID
                                            <span onClick={() => handleOrderChange("id")} className={classNames(
                                                'ml-2 flex-none rounded cursor-pointer',
                                                orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                            )}>
                                                {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Schedule Date
                                            <span onClick={() => handleOrderChange("scheduleDate")} className={classNames(
                                                'ml-2 flex-none rounded cursor-pointer',
                                                orderBy === "scheduleDate" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                            )}>
                                                {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Schedule Type
                                            <span onClick={() => handleOrderChange("scheduleType")} className={classNames(
                                                'ml-2 flex-none rounded cursor-pointer',
                                                orderBy === "scheduleType" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                            )}>
                                                {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Total amount
                                            <span onClick={() => handleOrderChange("totalAmount")} className={classNames(
                                                'ml-2 flex-none rounded cursor-pointer',
                                                orderBy === "totalAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                            )}>
                                                {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Booking amount
                                            <span onClick={() => handleOrderChange("bookingAmount")} className={classNames(
                                                'ml-2 flex-none rounded cursor-pointer',
                                                orderBy === "bookingAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                            )}>
                                                {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Total slots
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <div className="group inline-flex">
                                            Available slots
                                        </div>
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {schedulesTable && schedulesTable.length > 0 && schedulesTable.map((schedule: any) => {
                                    if (!checkPastDate(new Date(schedule.scheduleDate))) return (
                                        <tr key={schedule?.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{schedule?.id}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.scheduleDate}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.scheduleType}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.totalAmount}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.bookingAmount}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getSlotsData(schedule.id, "totalSlots") || "NA"}</td>
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getSlotsData(schedule.id, "availableSlots") || "NA"}</td>
                                            <td onClick={() => handleEditClick(schedule)} className="whitespace-nowrap py-4 px-3 text-sm text-indigo-500 cursor-pointer">Edit</td>
                                        </tr>
                                    )
                                })}
                                {schedulesTable && schedulesTable.length === 0 &&
                                    <div className="flex w-full align-center justify-center">
                                        <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">There are no Schedules for this Trek.</p>
                                    </div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal open={modal} setModal={handleClose}>
                <>
                    <div className="flex flex-row align-center justify-between">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Schedule and slot information</h3>
                        <XMarkIcon onClick={handleClose} className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer" aria-hidden="true" />
                    </div>
                    <div className="mt-5 border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule ID</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{selectedSchedule?.id}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule date</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{selectedSchedule?.scheduleDate}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule type</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow flex items-center">{selectedScheduleType ? "ON" : "OFF"}
                                        <Switch
                                            checked={selectedScheduleType}
                                            onChange={() => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleType(!selectedScheduleType) }}
                                            className={classNames(
                                                selectedScheduleType ? 'bg-indigo-600' : 'bg-gray-200',
                                                'ml-4 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                className={classNames(
                                                    selectedScheduleType ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            >
                                                <span
                                                    className={classNames(
                                                        selectedScheduleType ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                        <path
                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className={classNames(
                                                        selectedScheduleType ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Switch>
                                    </span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Total amount</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span className="text-gray-500 sm:text-sm">₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            min={0}
                                            name="totalAmount"
                                            id="totalAmount"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0.00"
                                            value={selectedScheduleTotalAmount}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleTotalAmount(parseInt(e.target.value)) }}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                INR
                                            </span>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Booking amount</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span className="text-gray-500 sm:text-sm">₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            min={0}
                                            name="bookingAmount"
                                            id="bookingAmount"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0.00"
                                            value={selectedScheduleBookingAmount}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleBookingAmount(parseInt(e.target.value)) }}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                INR
                                            </span>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Total slots</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <input
                                            type="number"
                                            min={0}
                                            name="totalSlots"
                                            id="totalSlots"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0"
                                            value={selectedScheduleTotalSlots}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleTotalSlots(parseInt(e.target.value)) }}
                                        />
                                    </div>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Available slots</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{getSlotsData(selectedSchedule?.id || 0, "availableSlots") || "NA"}</span>
                                </dd>
                            </div>
                            <div className='flex flex-col items-center justify-center pt-4'>
                                {saveError && <p className='text-sm mb-4 text-red-500'>{saveErrorMessage}</p>}
                                <button
                                    disabled={checkDisabled()}
                                    onClick={handleClick}
                                    type="button"
                                    className="w-1/3 inline-flex items-center justify-center rounded-md border border-transparent disabled:bg-indigo-300 enabled:bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm enabled:hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Save
                                </button>
                            </div>
                        </dl>
                    </div>
                </>
            </Modal>
        </>
        )
    } else {
        return (<>
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Schedules</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the schedules in your system
                            </p>
                        </div>
                    </div>
                    <div>
                        <header className="flex items-center justify-between border-b border-gray-200 py-4">
                            <div className="flex items-center gap-4">
                                Select Trek
                                <select
                                    id="location"
                                    name="location"
                                    title="location"
                                    onChange={(evt) => {
                                        selectTrek(evt.target.value)
                                        resetDates(true);
                                    }}
                                    value={trekId}
                                    className="mt-1 block  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value={0}>All</option>
                                    {treks && treks.length > 0 && <option disabled>TREKS</option>}
                                    {treks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                    {expeditions && expeditions.length > 0 && <option disabled>EXPEDITIONS</option>}
                                    {expeditions?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                    {adventures && adventures.length > 0 && <option disabled>ADVENTURES</option>}
                                    {adventures?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                </select>
                            </div>
                        </header>
                        {trekId !== 0 ? <div className="flex flex-wrap-reverse gap-8">
                            <div className="grow px-12 bg-gray-50 py-12">
                                <div className="flex row-auto py-10">
                                    <button
                                        type="button"
                                        onClick={() => setSettingDateType('0')}
                                        className={classNames(
                                            'grow ml-6 rounded-md border border-transparent  py-2 px-4 text-sm font-medium  shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                                            settingDateType == '0' || settingDateType == '1' ? 'bg-blue-600 text-white' : 'bg-white text-gray-900'
                                        )}
                                        disabled={!Boolean(trekId)}
                                    >
                                        Range
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setSettingDateType('2')}
                                        className={classNames(
                                            'grow ml-6 rounded-md border border-transparent  py-2 px-4 text-sm font-medium  shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                                            settingDateType == '2' ? 'bg-blue-600 text-white' : 'bg-white text-gray-900'
                                        )}
                                        disabled={!Boolean(trekId)}
                                    >
                                        Individual Date
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCreateSchedule}
                                        className="grow ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-300"
                                        disabled={!Boolean(trekId)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setEditSchedules(true)}
                                        className="grow ml-6 rounded-md border border-[rgb(79,70,229)] py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-slate-300"
                                        disabled={!Boolean(trekId)}
                                    >
                                        Edit Schedules
                                    </button>
                                </div>
                                <div
                                    className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                                    <section className="text-center">
                                        <div className='flex w-100 flex-1 justify-between'>
                                            <ChevronLeftIcon onClick={() => previousMonth()}
                                                className="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                            <h2 className="font-semibold text-gray-900">{format(firstDayCurrentMonth, 'MMMM yyy')}</h2>
                                            <ChevronRightIcon onClick={() => nextMonth()}
                                                className="h-5 w-5 cursor-pointer"
                                                aria-hidden="true" />
                                        </div>
                                        <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                                            <div>M</div>
                                            <div>T</div>
                                            <div>W</div>
                                            <div>T</div>
                                            <div>F</div>
                                            <div>S</div>
                                            <div>S</div>
                                        </div>
                                        <div
                                            className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                                            {newDays.map((day: Date, dayIdx: number) => (
                                                <button
                                                    disabled={!Boolean(trekId) || checkPastDate(day)}
                                                    key={day.toISOString()}
                                                    onClick={() => {
                                                        setDay(day)
                                                    }}
                                                    type="button"
                                                    className={classNames(
                                                        'py-1.5 hover:bg-indigo-100 focus:z-10',
                                                        'bg-white',
                                                        ((isEqual(day, selectedDay) || isEqual(day, endSelectedDay)) || isToday(day)) && 'font-semibold',
                                                        (isEqual(day, selectedDay) || isEqual(day, endSelectedDay)) && !isToday(day) && 'text-black',
                                                        (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && isSameMonth(day, firstDayCurrentMonth) && isToday(day) && 'text-red-600',
                                                        (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && isSameMonth(day, firstDayCurrentMonth) && !isToday(day) && 'text-black',
                                                        (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && !isSameMonth(day, firstDayCurrentMonth) && !isToday(day) && 'text-gray-400',
                                                        !isToday(day) && (!isEqual(day, selectedDay) || !isEqual(day, endSelectedDay)) && 'text-indigo-600',
                                                        dayIdx === 0 && 'rounded-tl-lg',
                                                        dayIdx === 6 && 'rounded-tr-lg',
                                                    )}
                                                >
                                                    <time
                                                        dateTime={format(day, 'yyyy-MM-dd')}
                                                        className={classNames(
                                                            isSelectedIndividualDates(day) && 'bg-blue-500 font-semibold text-white',
                                                            isPreSelected(day, 'ON') && !isRemoved(day) && 'bg-green-500 font-semibold text-white',
                                                            isPreSelected(day, 'ON') && isRemoved(day) && 'bg-red-500 font-semibold text-white',
                                                            isPreSelected(day, 'ON') && !isRemoved(day) && checkPastDate(day) && 'bg-green-200 font-semibold text-black',
                                                            'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                                                        )}
                                                    >
                                                        {format(day, 'd')}
                                                    </time>
                                                </button>
                                            ))}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div> : <div>
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            <div className="group inline-flex">
                                                ID
                                                <span onClick={() => handleOrderChangeAllSch("id")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Schedule Date
                                                <span onClick={() => handleOrderChangeAllSch("scheduleDate")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "scheduleDate" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Trek Name
                                                <span onClick={() => handleOrderChangeAllSch("trekId")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "trekId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Schedule Type
                                                <span onClick={() => handleOrderChangeAllSch("scheduleType")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "scheduleType" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Total amount
                                                <span onClick={() => handleOrderChangeAllSch("totalAmount")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "totalAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Booking amount
                                                <span onClick={() => handleOrderChangeAllSch("bookingAmount")} className={classNames(
                                                    'ml-2 flex-none rounded cursor-pointer',
                                                    orderByAll === "bookingAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                )}>
                                                    {orderTypeAll === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </div>
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Total slots
                                            </div>
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            <div className="group inline-flex">
                                                Available slots
                                            </div>
                                        </th> */}
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {allSchedules && allSchedules.length > 0 && allSchedules.map((schedule: any) => <tr key={schedule?.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{schedule?.id}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.scheduleDate}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.trek?.name}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.scheduleType}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.totalAmount}</td>
                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{schedule.bookingAmount}</td>
                                        {/* <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getSlotsData(schedule.id, "totalSlots") || "NA"}</td> */}
                                        {/* <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getSlotsData(schedule.id, "availableSlots") || "NA"}</td> */}
                                        <td onClick={() => handleEditClick(schedule)} className="whitespace-nowrap py-4 px-3 text-sm text-indigo-500 cursor-pointer">Edit</td>
                                    </tr>)}
                                    {allSchedules && allSchedules.length === 0 &&
                                        <tr className="flex w-full align-center justify-center">
                                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">There are no schedules present.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>}
                        {trekId === 0 && <Pagination
                            dataCount={allSchedulesTotal}
                            activePage={activePage}
                            dataPerPage={10}
                            handlePageChange={handlePageChange}
                            setActivePage={setActivePage}
                        />}
                    </div>
                </div>
            </div>
            <Modal open={modal} setModal={handleClose}>
                <>
                    <div className="flex flex-row align-center justify-between">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Schedule and slot information</h3>
                        <XMarkIcon onClick={handleClose} className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer" aria-hidden="true" />
                    </div>
                    <div className="mt-5 border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule ID</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{selectedSchedule?.id}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule date</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{selectedSchedule?.scheduleDate}</span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Schedule type</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow flex items-center">{selectedScheduleType ? "ON" : "OFF"}
                                        <Switch
                                            checked={selectedScheduleType}
                                            onChange={() => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleType(!selectedScheduleType) }}
                                            className={classNames(
                                                selectedScheduleType ? 'bg-indigo-600' : 'bg-gray-200',
                                                'ml-4 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                className={classNames(
                                                    selectedScheduleType ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            >
                                                <span
                                                    className={classNames(
                                                        selectedScheduleType ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                        <path
                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className={classNames(
                                                        selectedScheduleType ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Switch>
                                    </span>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Total amount</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span className="text-gray-500 sm:text-sm">₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            min={0}
                                            name="totalAmount"
                                            id="totalAmount"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0.00"
                                            value={selectedScheduleTotalAmount}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleTotalAmount(parseInt(e.target.value)) }}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                INR
                                            </span>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Booking amount</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span className="text-gray-500 sm:text-sm">₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            min={0}
                                            name="bookingAmount"
                                            id="bookingAmount"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0.00"
                                            value={selectedScheduleBookingAmount}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleBookingAmount(parseInt(e.target.value)) }}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                INR
                                            </span>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 flex items-center">Total slots</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <input
                                            type="number"
                                            min={0}
                                            name="totalSlots"
                                            id="totalSlots"
                                            className="block w-full rounded-md border-gray-300 pl-7 pr-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="0"
                                            value={selectedScheduleTotalSlots}
                                            onChange={(e: any) => { setSaveError(false); setSaveErrorMessage(""); return setSelectedScheduleTotalSlots(parseInt(e.target.value)) }}
                                        />
                                    </div>
                                </dd>
                            </div> */}
                            {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Available slots</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <span className="flex-grow">{getSlotsData(selectedSchedule?.id || 0, "availableSlots") || "NA"}</span>
                                </dd>
                            </div> */}
                            <div className='flex flex-col items-center justify-center pt-4'>
                                {saveError && <p className='text-sm mb-4 text-red-500'>{saveErrorMessage}</p>}
                                <button
                                    disabled={checkDisabled()}
                                    onClick={handleClick}
                                    type="button"
                                    className="w-1/3 inline-flex items-center justify-center rounded-md border border-transparent disabled:bg-indigo-300 enabled:bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm enabled:hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Save
                                </button>
                            </div>
                        </dl>
                    </div>
                </>
            </Modal>
        </>)
    }
}