import { useState, useEffect, Fragment, useContext } from "react";
import { ChevronDownIcon, ChevronUpIcon, CloudArrowUpIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CreateAdventure from "../components/CreateAdventure";
import EditAdventure from "../components/EditAdventure";
import AdminLayout from "../components/AdminLayout";
import Pagination from "../components/Pagination";
import { useAllAdventures, getAllAdventures, EditAdventurePayload, uploadImage } from "../hooks/adventures";
import { filteringParams } from "../hooks/user";
import Modal from "../components/Modal";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { getImageUrl } from "../components/utils";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Adventures() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const [openCreateForm, setOpenCreateForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [getUploadAdventureId, setUploadAdventureId] = useState(0);

    const [getAdventureId, setAdventureId] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(8);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [adventuresData, setAdventuresData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0);

    const [imageFile, setImageFile] = useState<any>();
    const [previewAdventureId, setPreviewAdventureId] = useState(0);

    const [getEditAdventure, setEditAdventure] = useState<EditAdventurePayload>({
        id: 0,
        name: '',
        state: 'ACTIVE',
        defaultBookingAmount: 0,
        defaultTotalAmount: 0,
        location: '',
        duration: 0,
        activityDuration: '',
        description: '',
        altitude: 0,
        bestTime: '',
        pickupPoint: 'none',
        difficultyLevel: '',
        defaultSlots: 0,
        contactPersonName: "",
        contactPersonNumber: "",
        reportingTime: "none",
        reportingLocation: "none",
        type: "",
        totalTrekDistance: "none",
        sortOrder: 0,
        videoUrl: "",
        keywords: '',
        title: '',
    });

    const uploadAdventureImageMutation = useMutation({
        mutationFn: (payload: any) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return uploadImage(payload, getUploadAdventureId)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Adventure image uploaded!",
                    notificationMessage: "The image for the adventure was uploaded successfully."
                }
            })
            queryClient.invalidateQueries(['adventureImage']);
            setModal(false);
            setImageFile(null);
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while uploading the adventure image."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const allAdventuresMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllAdventures(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleUserMutation = (payload: filteringParams): void => {
        allAdventuresMutation.mutateAsync(payload).then(res => {
            setAdventuresData(res.adventures)
            setDataCount(res.total)
        })
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllAdventures({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if (data) {
            setAdventuresData(data.adventures)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    const handleUploadClick = (event: any, adventureId: number) => {
        event.preventDefault();
        
        setUploadAdventureId(adventureId);
        setModal(true);
        setModalType('upload adventure image');
    };

    const handleFileUpload = () => {

        if (imageFile[0] != null) {
            const fileToUpload = imageFile[0];

            const fd = new FormData();
            fd.append('trekImage', fileToUpload);

            uploadAdventureImageMutation.mutateAsync(fd);
        }  
    };

    const handlePreviewClick = (event: any, adventure: any) => {
        event.preventDefault();

        setModal(true);
        setModalType('preview adventure image');

        setPreviewAdventureId(adventure.id);
    };

    const handleEditClick = (event: any, adventure: any) => {
        event.preventDefault();

        setOpenEditForm(true);

        const formValues = {
            id: adventure.id,
            name: adventure.name,
            state: adventure.state,
            defaultBookingAmount: adventure.defaultBookingAmount,
            defaultTotalAmount: adventure.defaultTotalAmount,
            location: adventure.location,
            duration: adventure.duration,
            activityDuration: adventure.activityDuration,
            description: adventure.description,
            altitude: adventure.altitude,
            bestTime: adventure.bestTime,
            pickupPoint: adventure.pickupPoint,
            difficultyLevel: adventure.difficultyLevel,
            defaultSlots: adventure.defaultSlots,
            contactPersonName: adventure.contactPersonName,
            contactPersonNumber: adventure.contactPersonNumber,
            reportingTime: adventure.reportingTime,
            reportingLocation: adventure.reportingLocation,
            type: adventure.type,
            totalTrekDistance: adventure.totalTrekDistance,
            sortOrder: adventure.sortOrder,
            videoUrl: adventure.videoUrl,
            keywords: adventure.keywords,
            title: adventure.title
          };
      
        setEditAdventure(formValues);
    };



    if (isLoading) {
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
            {/* </AdminLayout> */}
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
                <span>error...</span>
            {/* </AdminLayout> */}
        </>)
    }

    return (
        // <AdminLayout onChange={handleOnChange}>
            <>

        {modalType === 'upload adventure image' && (
            <Modal open={modal} setModal={setModal}>
                
                <div className="flex items-center justify-center w-full group relative flex-col overflow-hidden">
                    {!imageFile ? (
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <CloudArrowUpIcon aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" />
                                {/* <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg> */}
                                <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" onChange={(e) => {setImageFile(e.target.files);}} />
                        </label>
                    ) : (
                        <div className="flex flex-1 flex-col space-y-2 p-4">
                            <div className="flex justify-end">
                                <XCircleIcon
                                    className="h-6 w-6 cursor-pointer text-red-500"
                                    onClick={() => setImageFile(null)}
                                />
                            </div>
                            <img
                                alt="uploaded image"
                                src={URL.createObjectURL(imageFile[0])}
                                className="h-64 w-full rounded-lg border-2 border-solid border-gray-100"
                            />
                            <div className="flex justify-center">
                                <button
                                    title="Upload"
                                    type="button"
                                    onClick={() => handleFileUpload()}
                                    className="inline-flex w-32 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    )}
                    
                </div> 

            </Modal>
        ) 
        }

                <div>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Adventures</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of all the adventures in your system
                                </p>
                            </div>
                        <div className="relative hidden items-center mr-4 md:flex">
                            <div
                                className="pointer-events-none absolute inset-y-0 left-2 flex items-center">
                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search-field"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Search"
                                type="search"
                                name="search"
                                value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                            />
                        </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button
                                    type="button"
                                    onClick={() => setOpenCreateForm(true)}
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Add adventure
                                </button>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    <div className="group inline-flex">
                                                        ID
                                                        <span onClick={() => handleOrderChange("id")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Sort order
                                                        <span onClick={() => handleOrderChange("sortOrder")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "sortOrder" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Name
                                                        <span onClick={() => handleOrderChange("name")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "name" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Title
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Description
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Keywords
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Activity Duration
                                                        <span onClick={() => handleOrderChange("duration")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "duration" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Booking Amount
                                                        <span onClick={() => handleOrderChange("defaultBookingAmount")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "defaultBookingAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Total Amount
                                                        <span onClick={() => handleOrderChange("defaultTotalAmount")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "defaultTotalAmount" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Preview</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Upload</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {adventuresData && adventuresData.length > 0 && adventuresData.map((adventure: any) => (
                                                <Fragment key={adventure.id}>
                                                    <tr key={adventure.id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {adventure.id}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{adventure.sortOrder}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{adventure.name}</td>
                                                        <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{adventure.title}</td>
                                                        <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{adventure.description}</td>
                                                        <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{adventure.keywords}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{adventure.activityDuration}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{adventure.defaultBookingAmount}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{adventure.defaultTotalAmount}</td>
                                                        {adventure.videoUrl ? <td className="relative whitespace-nowrap min-w-[100px] py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <div onClick={(event) => handlePreviewClick(event, adventure)} className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                                                                Preview Video
                                                                <span className="sr-only">, {adventure.name}</span>
                                                            </div>
                                                        </td> : <td className="relative whitespace-nowrap min-w-[100px] py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <div onClick={(event) => handlePreviewClick(event, adventure)} className={classNames("text-black", adventure.imageUrl ? "cursor-pointer" : "cursor-default")}>
                                                                {
                                                                    adventure.imageUrl ?
                                                                        <img src={getImageUrl(adventure.imageUrl)} alt={adventure.name} className="h-12 w-12" />
                                                                        : "No Image"
                                                                }
                                                                <span className="sr-only">, {adventure.name}</span>
                                                            </div>
                                                        </td>}
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#" onClick={ (event) => handleUploadClick(event, adventure.id)} className="text-indigo-600 hover:text-indigo-900">
                                                                    Upload<span className="sr-only">, {adventure.name}</span>
                                                            </a>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#" onClick={(event) => handleEditClick(event, adventure)} className="text-indigo-600 hover:text-indigo-900">
                                                                Edit<span className="sr-only">, {adventure.name}</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    {modalType === 'preview adventure image' &&
                                                        previewAdventureId === adventure.id && (
                                                            <Modal open={modal} setModal={setModal}>
                                                                {adventure.videoUrl ? <iframe
                                                                    src={adventure.videoUrl}
                                                                    width="100%"
                                                                    height="700px"
                                                                    title="adventure video"
                                                                ></iframe> : <a href={adventure.imageUrl} target="_blank"><img src={getImageUrl(adventure.imageUrl)} alt="preview img" /></a>}
                                                            </Modal>
                                                        )}
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                    {adventuresData && adventuresData.length === 0 && <div className="flex w-full align-center justify-center">
                                        <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No adventures found</p>
                                    </div>}
                                </div>
                                <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />
                            </div>
                        </div>
                        <CreateAdventure open={openCreateForm} setOpen={setOpenCreateForm}></CreateAdventure>
                        <EditAdventure open={openEditForm} setOpen={setOpenEditForm} adventureData={getEditAdventure}></EditAdventure>
                    </div>
                </div>
            </>
        // </AdminLayout>
    )
}