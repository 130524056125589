import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number, 
  skip: number, 
  orderBy: string, 
  orderType: string, 
  keyword: string
}

export interface CreateCouponPayload {
    id?: number;
    amount: number;
    couponCode: string;
    trekIds: number[];
    isValid: boolean;
}

export interface UpdateCouponPayload {
    id?: number;
    amount: number;
    couponCode: string;
    trekIds: number[];
    isValid: boolean;
}

const getAllCoupons = async () => {
  const url = '/coupon/bycode'
  const resp = await apiInstance.get(url)
  // console.log(resp)
  return resp.data;
}

const useAllCoupons = () => {
  return useQuery(['coupons'], () => getAllCoupons())
}

const createCoupon = async (payload: CreateCouponPayload) => {
    const url = '/coupon/createOrUpdate';
    return apiInstance.post(url, payload);
  }
  
  const editCoupon = async (payload: UpdateCouponPayload) => {
    const url = '/coupon/createOrUpdate';
    return apiInstance.post(url, payload);
  }

  const enableCoupons = async () => {
    const url = '/coupon/all/enable';
    return apiInstance.put(url);
  }

  const disableCoupons = async () => {
    const url = '/coupon/all/disable';
    return apiInstance.put(url);
  }

  const toggleByCouponId = async (couponId: number) => {
    const url = '/coupon/toggle/' + couponId
    const resp = await apiInstance.put(url)
    return resp.data;
  }

  const toggleByCouponCode = async (couponCode: string, trekIds: number[]) => {
    const url = '/coupon/toggle/name/' + couponCode
    const resp = await apiInstance.post(url, { trekIds })
    return resp.data;
  }

  const getByTrekId = async (trekId: number) => {
    const url = '/coupon/trek/' + trekId
    const resp = await apiInstance.get(url)
    return resp.data;
  }


export { getAllCoupons, useAllCoupons, createCoupon, editCoupon, enableCoupons, disableCoupons, toggleByCouponId, toggleByCouponCode, getByTrekId }
