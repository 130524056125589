import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number, 
  skip: number, 
  orderBy: string, 
  orderType: string, 
  keyword: string
}

export interface TrainerCreatePayload {
  firstName: string;
  lastName: string;
  certificate: string;
  bio: string;
  age: number;
  imageUrl: string;
  designation: string;
  sortOrder: number;
}
export interface TrainerEditPayload {
  id?: number;
  firstName: string;
  lastName: string;
  certificate: string;
  bio: string;
  age: number;
  imageUrl: string;
  designation: string;
  sortOrder: number;
}

const getAllTrainers = async (params?: filteringParams) => {
  const url = '/trainers/all'
  const resp = await apiInstance.get(url, { params })
  return resp.data;
}

const useAllTrainers = (params?: filteringParams) => {
  return useQuery(['trainers'], () => getAllTrainers(params))
}

const createTrainer = async (payload: TrainerCreatePayload) => {
  const url = '/trainers/create';
  return apiInstance.post(url, payload);
}

const editTrainer = async (payload: TrainerEditPayload) => {
  const url = '/trainers/create';
  return apiInstance.post(url, payload);
};

const uploadImage = async (payload: any, trainerId: number) => {
  const url = 'trainers/uploadImage/' + trainerId;
  return apiInstance.post(url, payload);
}

const toggleTrainer = async (trainerId: number) => {
  const url = 'trainers/toggle/' + trainerId;
  return apiInstance.put(url);
}

export { getAllTrainers, useAllTrainers, createTrainer, editTrainer, uploadImage, toggleTrainer }
