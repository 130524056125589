import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number, 
  skip: number, 
  orderBy: string, 
  orderType: string, 
  keyword: string
}

export interface locationPricesPayload {
  id?: number;
  trekId: number;
  name: string;
  priceDifferenceTotal: number;
  priceDifferenceBooking: number;
  disabled?: boolean;
}

const getAllLocations = async (params?: filteringParams) => {
  const url = 'location-prices'
  const resp = await apiInstance.get(url, { params })
  return resp.data;
}

const getLocationsByTrekId = async (trekId: number) => {
  const url = 'location-prices/trek/'+trekId
  const resp = await apiInstance.get(url)
  return resp.data;
}

const toggleLocation = async (id: number) => {
  const url = 'location-prices/toggle/'+id
  const resp = await apiInstance.put(url)
  return resp.data;
}

const createLocationPrice = async (payload: locationPricesPayload) => {
  const url = 'location-prices/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data;
}

const useAllLocation = (params?: filteringParams) => {
  return useQuery(['LocationPrices'], () => getAllLocations(params))
}

export { useAllLocation, getAllLocations, getLocationsByTrekId, createLocationPrice, toggleLocation }
