import React, {Fragment, useState} from 'react'

import { Navigate, useOutlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

interface NavType {
    name: string;
    path: string;
    icon: any;
}

const userNavigation = [
    {name: 'Your Profile', href: '#'},
    {name: 'Settings', href: '#'},
    {name: 'Sign out', href: '#'},
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function AppLayout() {
    const userContext = useAuthContext();
    const outlet = useOutlet();

    if ((userContext.isUserAuthenticated() && userContext.isAdmin()) ) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <div>
                <main>
                    <div className="p-6">
                        <main>{outlet}</main>
                    </div>
                </main>
            </div>
        </>
    )
}
