import { useState, useEffect, Fragment, useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAllInventory } from "../hooks/inventory";
import Modal from "../components/Modal";
import { useAllTreks } from "../hooks/trek";
import { createTrekInventory, getAllTreksInventory, getTrekInventoryById, TreksInventoryCreatePayload, TreksInventoryEditPayload, updateTrekInventory, useAllTreksInventory, toggleTrekInventory, getTrekInventoryByTrekId } from "../hooks/treksinventory";
import { filteringParams } from "../hooks/user";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { Switch } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function TrekInventory() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const createTrekInventoryMutation = useMutation({
        mutationFn: (payload: TreksInventoryCreatePayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createTrekInventory(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Trek inventory created!",
                    notificationMessage: "The inventory for the trek was created successfully."
                }
            })
            queryClient.invalidateQueries(['trekinventories']);
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while creating the trek inventory."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const editTrekInventoryMutation = useMutation({
        mutationFn: (payload: TreksInventoryEditPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return updateTrekInventory(payload)
        },
        onSuccess: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Trek inventory edited!",
                    notificationMessage: "The inventory for the trek was edited successfully."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })

            queryClient.invalidateQueries(['trekinventories']);
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while editing the trek inventory."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const trekdata = useAllTreks({
        take: 0,
        skip: 0,
        orderBy: 'id',
        orderType: 'ASC',
        keyword: ''
    });
    const allTreks = trekdata?.data?.treks;

    const [selectedTrekId, setSelectedTrekId] = useState(0);
    const [selectedTrekIdEdit, setSelectedTrekIdEdit] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [trekId, setTrekId] = useState(0)
    const [disableCheck, setDisableCheck] = useState(false);
    const [trekInventory, setTrekInventory] = useState<TreksInventoryCreatePayload>({
        inventoryId: 0,
        trekIds: [],
        disable: false,
        bookingAmount: 0,
        totalAmount: 0
    });
    const [editTrekInventory, setEditTrekInventory] = useState<TreksInventoryEditPayload>({
        id: 0,
        inventoryId: 0,
        trekIds: [],
        disable: false,
        bookingAmount: 0,
        totalAmount: 0
    });
    const [getUpdateInventoryId, setUpdateInventoryId] = useState(0);
    const [inventorySelect, setInventorySelect] = useState(0);

    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [inventoryData, setInventoryData] = useState([]);
    const [getTrekInventories, setTrekInventories] = useState([]);

    const allTrekInventoryMutation = useMutation({
        mutationFn: (params: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllTreksInventory(params)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setTrekInventories(data.trek)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const trekInventoryByIdsMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getTrekInventoryById(id)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setTrekInventories(data.trek)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const trekInventoryByTrekIdMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getTrekInventoryByTrekId(id)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setTrekInventories(data.trek)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    // const trekInventories = useTrekInventoryById(inventorySelect)?.data?.trek;

    useEffect(() => {
        if (inventorySelect !== 0) {
            trekInventoryByIdsMutation.mutateAsync(inventorySelect);
        } else {
            allTrekInventoryMutation.mutateAsync({
                take: 0,
                skip: 0,
                orderBy: 'id',
                orderType: "ASC",
                keyword: ''
            })
        }
    }, [inventorySelect]);

    useEffect(() => {
        if (trekId === 0) {
            allTrekInventoryMutation.mutateAsync({
                take: 0,
                skip: 0,
                orderBy: 'id',
                orderType: "ASC",
                keyword: ''
            })
        } else {
            trekInventoryByTrekIdMutation.mutateAsync(trekId)
        }
    }, [trekId])
    // console.log(trekInventories);

    getTrekInventories?.map((item: any) => {
        const trek = allTreks?.find((trek: any) => trek.id === item.trekId);
        const inventory: any = inventoryData?.find((inventory: any) => inventory.id === item.inventoryId);
        item.trekName = trek?.name;
        item.inventoryName = inventory?.type;
        item.inventorySize = inventory?.size;
        item.inventoryColor = inventory?.color;
    });
    const invIds = getTrekInventories?.map((i: any) => i.inventoryId)
    const newInvIds = invIds?.filter((item, index) => invIds.indexOf(item) === index);

    const inventoriesData = useAllInventory({
        take: 0,
        skip: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    });
    console.log(inventoriesData);

    useEffect(() => {
        if (inventoriesData) {
            setInventoryData(inventoriesData?.data?.Inventory);
        }
    }, [inventoriesData]);

    const { data, isLoading, isError, error } = useAllTreksInventory({
        take: 0,
        skip: 0,
        orderBy: 'id',
        orderType: 'ASC',
        keyword: ''
    });

    useEffect(() => {
        if (data && inventorySelect === 0) {
            setTrekInventories(data.trek);
            setDataCount(data.total);
        }
    }, [data]);

    const handleInventoryItemSelect = (event: any) => {
        setTrekId(0)
        setInventorySelect(parseInt(event.target.value));
    };

    const handleAddInventoryClick = () => {
        setModal(true);
        setModalType('add inventory');
    };

    const handleEditClick = (event: any, inventory: any) => {
        event.preventDefault();

        setModal(true);

        setModalType('update inventory');
        const inv: any = getTrekInventories?.filter((ti: any) => ti.inventoryId === inventory)
        const invTrekIds: any = inv.map((i: any) => i.trekId)
        setUpdateInventoryId(inventory);

        const formValues = {
            id: inv[0].id,
            inventoryId: inv[0].inventoryId,
            trekIds: invTrekIds,
            disable: inv[0].disable,
            bookingAmount: inv[0].bookingAmount,
            totalAmount: inv[0].totalAmount,
        };

        setEditTrekInventory(formValues);
    };

    const handleInventorySelect = (event: any) => {
        const id = parseInt(event.target.value);
        trekInventory.trekIds = [];
        setSelectedTrekId(0)
        const newFormData: any = { ...trekInventory };
        newFormData['inventoryId'] = id;
        setTrekInventory(newFormData);
    };

    const handleTrekSelect = (event: any) => {
        const newFormData: any = { ...trekInventory };
        const trekId = parseInt(event.target.value);
        setSelectedTrekId(0)
        if (trekId === 0) return;
        else if (trekId === -1) {
            const treks = allTreks?.map((trek: any) => trek.id);
            const newTreks = treks.filter((t: any) => !checkExists(t))
            newFormData['trekIds'] = newTreks;
        } else {
            let arr: number[] = newFormData['trekIds']
            const found = arr.findIndex((t: number) => t === trekId)
            if (found > -1) {
                arr.splice(found, 1);
                newFormData['trekIds'] = arr;
            } else {
                arr.push(trekId)
                newFormData['trekIds'] = arr;
            }
        }
        setTrekInventory(newFormData);
    };

    function removeTrek(trekId: number) {
        const newFormData: any = { ...trekInventory };
        let arr: number[] = newFormData['trekIds']
        const found = arr.findIndex((t: number) => t === trekId)
        if (found > -1) {
            arr.splice(found, 1);
            newFormData['trekIds'] = arr;
        } else {
            arr.push(trekId)
            newFormData['trekIds'] = arr;
        }
        setTrekInventory(newFormData);
    }

    const toggleChange = (e: any) => {
        setDisableCheck(e.target.checked);
        const newFormData: any = { ...trekInventory };
        newFormData['disable'] = e.target.checked;
        setTrekInventory(newFormData)
    };

    const handleFormChange = (event: { preventDefault: () => void; target: { getAttribute: (arg0: string) => any; value: any; }; }) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = parseInt(event.target.value);

        const newFormData: any = { ...trekInventory };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;
        setTrekInventory(newFormData);
    };

    const handleFormSubmit = (event: any) => {
        event.preventDefault();
        console.log(trekInventory);
        createTrekInventoryMutation.mutate({ ...trekInventory });
        setModal(false);

        const newFormData: any = { ...trekInventory };

        for (let item of Object.keys(newFormData)) {
            if (typeof newFormData[item] == "number") {
                newFormData[item] = 0;
            }
        };
        newFormData['disable'] = false;

        setTrekInventory(newFormData);
        setDisableCheck(false);
    };

    const handleEditInventorySelect = (event: any) => {
        const newFormData: any = { ...editTrekInventory };
        newFormData['inventoryId'] = parseInt(event.target.value);
        setEditTrekInventory(newFormData);
    };

    const handleEditTrekSelect = (event: any) => {
        const newFormData: any = { ...editTrekInventory };
        const trekId = parseInt(event.target.value);
        setSelectedTrekIdEdit(0)
        if (trekId === 0) return;
        else if (trekId === -1) {
            const treks = allTreks?.map((trek: any) => trek.id);
            newFormData['trekIds'] = treks;
        } else {
            let arr: number[] = newFormData['trekIds']
            const found = arr.findIndex((t: number) => t === trekId)
            if (found > -1) {
                arr.splice(found, 1);
                newFormData['trekIds'] = arr;
            } else {
                arr.push(trekId)
                newFormData['trekIds'] = arr;
            }
        }
        setEditTrekInventory(newFormData);
        // const newFormData: any = { ...editTrekInventory };
        // newFormData['trekId'] = parseInt(event.target.value);
        // setEditTrekInventory(newFormData);
    };


    function removeTrekFromEdit(trekId: number) {
        const newFormData: any = { ...editTrekInventory };
        let arr: number[] = newFormData['trekIds']
        const found = arr.findIndex((t: number) => t === trekId)
        if (found > -1) {
            arr.splice(found, 1);
            newFormData['trekIds'] = arr;
        } else {
            arr.push(trekId)
            newFormData['trekIds'] = arr;
        }
        setEditTrekInventory(newFormData);
    }

    const toggleEditChange = (e: any) => {
        setDisableCheck(e.target.checked);
        const newFormData: any = { ...editTrekInventory };
        newFormData['disable'] = e.target.checked;
        setEditTrekInventory(newFormData)
    };

    const handleEditFormChange = (event: any) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldValue = parseInt(event.target.value);

        const newFormData: any = { ...editTrekInventory };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;

        setEditTrekInventory(newFormData);
    };

    const handleEditFormSubmit = (event: any) => {
        event.preventDefault();

        const editedInventory: any = {
            id: editTrekInventory.id,
            inventoryId: editTrekInventory.inventoryId,
            trekIds: editTrekInventory.trekIds,
            disable: editTrekInventory.disable,
            bookingAmount: editTrekInventory.bookingAmount,
            totalAmount: editTrekInventory.totalAmount,
        };

        console.log(editedInventory);

        editTrekInventoryMutation.mutate(editedInventory);

        setUpdateInventoryId(0);
        setModal(false);
    };


    const toggleTrekInventoryMutation = useMutation({
        mutationFn: (inventoryId: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleTrekInventory(inventoryId)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['trekinventories'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    function getTrekName(trekId: number): string {
        const trek = allTreks.find((t: any) => t.id === trekId)
        return trek?.name || ""
    }

    function getTreksNames(invId: number): string {
        const inv: any = getTrekInventories.filter((ti: any) => ti.inventoryId === invId)
        const trekIds = inv?.map((i: any) => i.trekId)
        let str = ""
        trekIds.map((tid: number, idx: number) => {
            const trek = allTreks?.find((t: any) => t.id === tid)
            str += `${trek?.name || ''}`
            if (idx !== trekIds.length - 1) {
                str += ', '
            }
        })
        return str
    }

    function checkExists(trekId: number): boolean {
        const idx = getTrekInventories.findIndex((ti: any) => (ti.inventoryId === trekInventory['inventoryId']) && (ti.trekId === trekId))
        return idx > -1;
    }

    function getInventoryName(id: number): string {
        const inv: any = getTrekInventories.find((ti: any) => ti.inventoryId === id)
        if (!inv) return ""
        else return `${inv.inventoryName} - ${inv.inventorySize} - ${inv.inventoryColor}`
    }

    function getInventoryAmount(id: number, total: boolean) {
        const inv: any = getTrekInventories.find((ti: any) => ti.inventoryId === id)
        if (!inv) return 0
        else return total ? inv.totalAmount : inv.bookingAmount;
    }

    function getInventoryDisabled(id: number) {
        const inv: any = getTrekInventories.find((ti: any) => ti.inventoryId === id)
        if (!inv) return false
        else return inv.disable
    }

    const handleInvByTrek = (event: any) => {
        setInventorySelect(0)
        setTrekId(parseInt(event.target.value))
    }

    if (isLoading) {
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
            <span>Loading...</span>
            {/* </AdminLayout> */}
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
            <span>error...</span>
            {/* </AdminLayout> */}
        </>)
    }

    console.log("this", editTrekInventory)

    return (
        // <AdminLayout onChange={handleOnChange}>
        <>
            {modalType === 'add inventory' && (
                <Modal open={modal} setModal={setModal}>
                    <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                        <h5 className="text-xl font-bold text-gray-900 mb-7">Add your Inventory</h5>
                        <form onSubmit={handleFormSubmit}>
                            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                                <div className="sm:col-span-2">
                                    <label htmlFor="inventoryitem" className="block mb-2 text-sm font-medium text-gray-900 ">Select Inventory Item</label>
                                    <select id="inventoryitem" aria-label="inventoryitem" name="inventoryName" onChange={e => handleInventorySelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                        <option value={0}>Select Item</option>
                                        {inventoryData?.map((t: any) => <option key={t.id} value={t.id}>{t.type} - {t.size} - {t.color}</option>)}
                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="alltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Treks</label>
                                    <select disabled={trekInventory['inventoryId'] === 0} value={selectedTrekId} id="alltreks" aria-label="alltreks" name="trekName" onChange={e => handleTrekSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                        <option value={0}>Select Treks</option>
                                        <option value={-1}>All Treks</option>
                                        {allTreks?.map((t: any) => <option key={t.id} value={t.id} disabled={checkExists(t.id)}>{t.name}</option>)}
                                    </select>
                                </div>
                                {trekInventory.trekIds.length > 0 && <div className="flex flex-wrap col-span-2">
                                    {trekInventory.trekIds.map(t => <p onClick={() => removeTrek(t)} key={t} className="flex items-center p-2 bg-slate-100 rounded-lg m-1 text-xs cursor-pointer hover:shadow">{getTrekName(t)} <XCircleIcon className="h-4 w-4 ml-2" /></p>)}
                                </div>}
                                <label className="relative sm:col-span-2 inline-flex items-center mb-2 cursor-pointer">
                                    <input type="checkbox" checked={disableCheck} onChange={e => toggleChange(e)} className="sr-only peer" />
                                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Disable this inventory!!</span>
                                </label>
                                <div className="sm:col-span-2">
                                    <label htmlFor="bookingAmount" className="block mb-2 text-sm font-medium text-gray-900 ">Booking Amount</label>
                                    <input type="text" name="bookingAmount" id="bookingAmount" onChange={handleFormChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="booking amount..." />
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="totalAmount" className="block mb-2 text-sm font-medium text-gray-900 ">Total Amount</label>
                                    <input type="text" name="totalAmount" id="totalAmount" onChange={handleFormChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="total amount..." />
                                </div>
                            </div>
                            <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                Add Inventory
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Treks Inventory</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all inventory items associated with the treks.
                            </p>
                        </div>
                        <div className="flex items-center gap-4">
                            <select
                                id="alltreks"
                                aria-label="alltreks"
                                name="trekName"
                                value={trekId}
                                onChange={handleInvByTrek}
                                className="mt-1 block  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value={0}>All Treks</option>
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <select id="selectinventoryitem" value={inventorySelect} aria-label="selectinventoryitem" name="selectinventoryitem" onChange={e => handleInventoryItemSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ">
                                <option value={0}>All Inventories</option>
                                {inventoryData?.map((t: any) => <option key={t.id} value={t.id}>{t.type} - {t.size} - {t.color}</option>)}
                            </select>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={handleAddInventoryClick}
                            >
                                Add Inventory
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Inventory Id
                                                </div>
                                            </th>
                                            {inventorySelect === 0 &&
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Inventory Name
                                                    </div>
                                                </th>
                                            }
                                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Trek Id
                                                </div>
                                            </th> */}
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Treks
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Booking Amount
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Total Amount
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Active
                                                </div>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Update</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {newInvIds && newInvIds.length > 0 && newInvIds.map((item: any) => (
                                            <Fragment key={item}>
                                                <tr>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item}</td>
                                                    {inventorySelect === 0 && <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getInventoryName(item)}</td>}
                                                    <td className="whitespace-normal py-4 px-3 text-sm text-gray-500 max-w-52 overflow-scroll">{getTreksNames(item)}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getInventoryAmount(item, false)}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getInventoryAmount(item, true)}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                        <Switch
                                                            checked={!getInventoryDisabled(item)}
                                                            onChange={(e: any) => toggleTrekInventoryMutation.mutateAsync(item)}
                                                            className={classNames(
                                                                !getInventoryDisabled(item) ? 'bg-indigo-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span
                                                                className={classNames(
                                                                    !getInventoryDisabled(item) ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        !getInventoryDisabled(item) ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                    )}
                                                                    aria-hidden="true"
                                                                >
                                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                        <path
                                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                            stroke="currentColor"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        !getInventoryDisabled(item) ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                    )}
                                                                    aria-hidden="true"
                                                                >
                                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" onClick={(event) => handleEditClick(event, item)} className="text-indigo-600 hover:text-indigo-900">
                                                            Update<span className="sr-only">, {item}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                {modalType === 'update inventory' &&
                                                    getUpdateInventoryId === item && (
                                                        <Modal open={modal} setModal={setModal}>
                                                            <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                                                                <h5 className="text-xl font-bold text-gray-900 mb-7">Edit Inventory</h5>
                                                                <form onSubmit={handleEditFormSubmit}>
                                                                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                                                                        <div className="sm:col-span-2">
                                                                            <label htmlFor="editinventoryitem" className="block mb-2 text-sm font-medium text-gray-900 ">Select Inventory Item</label>
                                                                            <select disabled id="editinventoryitem" aria-label="inventoryitem" name="inventoryName" onChange={e => handleEditInventorySelect(e)} defaultValue={item} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                                                                <option value={0}>Select Item</option>
                                                                                {inventoryData?.map((t: any) => <option key={t.id} value={t.id}>{t.type} - {t.size} - {t.color}</option>)}
                                                                            </select>
                                                                        </div>
                                                                        <div className="sm:col-span-2">
                                                                            <label htmlFor="editalltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Trek</label>
                                                                            <select id="editalltreks" aria-label="alltreks" value={selectedTrekIdEdit} name="trekName" onChange={e => handleEditTrekSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                                                                <option value={0}>Select Trek</option>
                                                                                <option value={-1}>All Treks</option>
                                                                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                                                            </select>
                                                                        {/* <label htmlFor="alltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Treks</label>
                                                                        <select disabled={trekInventory['inventoryId'] === 0} value={selectedTrekId} id="alltreks" aria-label="alltreks" name="trekName" onChange={e => handleTrekSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                                                            <option value={0}>Select Treks</option>
                                                                            <option value={-1}>All Treks</option>
                                                                            {allTreks?.map((t: any) => <option key={t.id} value={t.id} disabled={checkExists(t.id)}>{t.name}</option>)}
                                                                        </select> */}
                                                                        </div>
                                                                        {editTrekInventory.trekIds.length > 0 && <div className="flex flex-wrap col-span-2">
                                                                            {editTrekInventory.trekIds.map(t => <p onClick={() => removeTrekFromEdit(t)} key={t} className="flex items-center p-2 bg-slate-100 rounded-lg m-1 text-xs cursor-pointer hover:shadow">{getTrekName(t)} <XCircleIcon className="h-4 w-4 ml-2" /></p>)}
                                                                        </div>}
                                                                        <label className="relative sm:col-span-2 inline-flex items-center mb-2 cursor-pointer">
                                                                            <input type="checkbox" checked={disableCheck} onChange={e => toggleEditChange(e)} defaultValue={getInventoryDisabled(item)} className="sr-only peer" />
                                                                            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Disable this inventory!!</span>
                                                                        </label>
                                                                        <div className="sm:col-span-2">
                                                                            <label htmlFor="editbookingAmount" className="block mb-2 text-sm font-medium text-gray-900 ">Booking Amount</label>
                                                                            <input type="text" name="bookingAmount" id="editbookingAmount" onChange={handleEditFormChange} required defaultValue={getInventoryAmount(item, false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="booking amount..." />
                                                                        </div>
                                                                        <div className="sm:col-span-2">
                                                                            <label htmlFor="edittotalAmount" className="block mb-2 text-sm font-medium text-gray-900 ">Total Amount</label>
                                                                            <input type="text" name="totalAmount" id="edittotalAmount" onChange={handleEditFormChange} required defaultValue={getInventoryAmount(item, true)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="total amount..." />
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                                                        Save
                                                                    </button>
                                                                </form>
                                                            </div>
                                                        </Modal>
                                                    )
                                                }
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                {newInvIds && newInvIds.length === 0 && <div className="flex w-full align-center justify-center">
                                    <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No items found</p>
                                </div>}
                                {/* {getTrekInventories && getTrekInventories.length === 0 && <div className="flex w-full align-center justify-center">
                                    <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No items found</p>
                                </div>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // </AdminLayout>
    )
}