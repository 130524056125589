import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';

export interface filteringParams {
  take: number, 
  skip: number, 
  orderBy: string, 
  orderType: string, 
  keyword: string
}

export interface SocialfeedApprovePayload {
  socialFeedId: number,
}

const getAllSocialFeeds = async (params?: filteringParams) => {
  const url = '/socialfeeds'
  const resp = await apiInstance.get(url, { params })
  // console.log(resp)
  return resp.data;
}

const useAllSocialFeeds = (params?: filteringParams) => {
  return useQuery(['socialFeeds'], () => getAllSocialFeeds(params))
}

const approveSocialFeeds = async (payload: SocialfeedApprovePayload) => {
  const url = '/socialfeeds/approve'
  const resp = await apiInstance.put(url, payload)
  return resp.data;
}

const unapproveSocialFeeds = async (payload: SocialfeedApprovePayload) => {
  const url = '/socialfeeds/unapprove'
  const resp = await apiInstance.put(url, payload)
  return resp.data;
}

const archiveSocialFeed = async (socialFeedId: number) => {
  const url = '/socialfeeds/archive/' + socialFeedId
  const resp = await apiInstance.put(url)
  return resp.data;
}

export { getAllSocialFeeds, useAllSocialFeeds, unapproveSocialFeeds, approveSocialFeeds, archiveSocialFeed }
