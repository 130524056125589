import { useState, useEffect, useContext, Fragment } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import { filteringParams, useUsersByIds } from "../hooks/user";
import {
  useAllBookings,
  getBookingById,
  getAllBookings,
  useSchedulesByIds,
  SchedulePayload,
  getCancelBooking,
  getBookingsCsv
} from "../hooks/bookings";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { Dialog } from "@headlessui/react";
import { format } from "date-fns";
import { NotificationContext } from "../context/NotificationContext";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const bookingTypes = [
  { id: 'SUCCESSFUL', title: 'Successful' },
  { id: 'NOTATTEMPTED', title: 'Not attempted' },
  { id: 'CANCELED', title: 'Cancelled' },
]

export default function Bookings() {
  const appLoaderContext = useContext(AppLoaderContext);
  const notificationContext = useContext(NotificationContext);
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [bookingInfoModal, setBookingInfoModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [cancelInfoModal, setCancelInfoModal] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [bookingInfo, setBookingInfo] = useState<any>(null);
  const [dataCount, setDataCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("SUCCESSFUL");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderType, setOrderType] = useState("DESC");
  const [keyword, setKeyword] = useState("");
  const [bookingsData, setBookingsData] = useState<any>([]);
  const [searchTimer, setSearchTimer] = useState(0);
  const queryClient = useQueryClient();

  const allUsersMutation = useMutation({
    mutationFn: (payload: filteringParams) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return getAllBookings(payload)
    },
    onSuccess: () => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  });

  const cancelBookingMutation = useMutation({
    mutationFn: (payload: any) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return getCancelBooking(payload)
    },
    onSuccess: () => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
      setCancelInfoModal(false)
      setBookingInfoModal(false)
      queryClient.invalidateQueries(['bookings'])
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Booking cancelled!",
          notificationMessage: "The booking was cancelled successfully."
        }
      })
    },
    onError: (err: any) => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: err?.response?.data?.message
        }
      })
    }
  });

  const downloadCsvMutation = useMutation({
    mutationFn: (payload: any) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return getBookingsCsv(payload)
    },
    onSuccess: (res) => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
      console.log(res)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      const startDateStr = format(new Date(startDate), "dd-MM-yyyy")
      const endDateStr = format(new Date(endDate), "dd-MM-yyyy")
      link.setAttribute('download', `${paymentStatus.toLowerCase()} bookings from ${startDateStr} to ${endDateStr}.csv`)
      link.click()
      setStartDate("")
      setEndDate("")
      setReportModal(false)
      setPaymentStatus("SUCCESSFUL");
    },
    onError: (err: any) => {
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: err?.response?.data?.message || "There was some error in getting the CSV"
        }
      })
    }
  });

  const handleCancelBooking = (bookingId: number) => {
    console.log(bookingId, "asa")
    if (bookingId) {
      cancelBookingMutation.mutateAsync(bookingId).then((res) => {
        console.log(res)
      });
    }
  }

  const handleUserMutation = (payload: filteringParams): void => {
    allUsersMutation.mutateAsync(payload).then((res) => {
      setBookingsData(res.bookings);
      setDataCount(res.total);
    });
  };

  const handleOrderChange = (orderByString: string): void => {
    let newOT = "ASC";
    let newOB = orderByString;
    if (orderBy === orderByString) {
      orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC");
      orderType === "ASC" ? (newOT = "DESC") : (newOT = "ASC");
    } else {
      setOrderBy(orderByString);
      newOB = orderByString;
    }
    handleUserMutation({
      take,
      skip,
      orderBy: newOB,
      orderType: newOT,
      keyword,
    });
  };

  const { data, isLoading, isError, error } = useAllBookings({
    take,
    skip,
    orderBy,
    orderType,
    keyword,
  });
  const scheduleIds = data?.bookings?.map((b: any) => b.scheduleId);
  const userIds = data?.bookings?.map((b: any) => b.userId);
  const uniqueScheduleIds = scheduleIds?.filter(
    (si: any, idx: any) => scheduleIds.indexOf(si) === idx
  );
  const scheduleData = useSchedulesByIds(uniqueScheduleIds)?.data?.schedule;

  useEffect(() => {
    if (data) {
      setBookingsData(data.bookings);
      setDataCount(data.total);
    }
  }, [data]);

  useEffect(() => {
    if (searchTimer > 0) {
      clearTimeout(searchTimer);
    }
  }, [keyword]);

  function handleOnChange(e: string): void {
    setKeyword(e);
  }

  function handlePageChange(page: number): void {
    setSkip(take * (page - 1));
    handleUserMutation({
      take,
      skip: take * (page - 1),
      orderBy,
      orderType,
      keyword,
    });
  }

  function pageReset(): void {
    setActivePage(1);
    setSkip(0);
  }

  if (isLoading) {
    return (
      <>
        <span>Loading...</span>
      </>
    );
  }

  if (isError) {
    console.log(error);
    return (
      <>
        <span>error...</span>
      </>
    );
  }

  const getTrekName = (scheduleId: number): String => {
    if (!scheduleId) return "";
    const sch = scheduleData?.find((s: SchedulePayload) => s.id === scheduleId);
    return sch?.trek?.name;
  };

  const handleBookingClick = async (bookingId: number) => {
    if (!bookingId) return;
    appLoaderContext?.dispatch({
      type: "SHOW"
    })
    const resp = await getBookingById(bookingId);
    appLoaderContext?.dispatch({
      type: "HIDE"
    })
    if (!resp) return;
    // const user = usersData?.find((u: any) => u.id === resp.booking.userId);
    setBookingInfo({ ...resp.booking });
    setBookingInfoModal(true);
  };

  function getDateStr(day: string) {
    return format(new Date(day), "E, MMM do, yyyy")
  }

  function downloadCsv(): void {
    const params = {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      status: paymentStatus
    }
    downloadCsvMutation.mutateAsync(params);
  }

  return (
    // <AdminLayout onChange={handleOnChange}>
    <>
      <div>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Bookings</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the bookings in your system
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={() => setReportModal(true)}
                className=" mr-4 inline-flex items-center justify-center rounded-md border border-transparent border-indigo-600 px-4 py-2 text-sm font-medium text-indigo-700 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Get report
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <div className="group inline-flex">
                          ID
                          <span
                            onClick={() => handleOrderChange("id")}
                            className={classNames(
                              "ml-2 flex-none rounded cursor-pointer",
                              orderBy === "id"
                                ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                : "invisible text-gray-400 group-hover:visible group-focus:visible"
                            )}
                          >
                            {orderType === "ASC" ? (
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Name</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Trek</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Total members</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Mobile number</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Email Id</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">
                          Booked on
                          <span
                            onClick={() => handleOrderChange("createdAt")}
                            className={classNames(
                              "ml-2 flex-none rounded cursor-pointer",
                              orderBy === "createdAt"
                                ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                : "invisible text-gray-400 group-hover:visible group-focus:visible"
                            )}
                          >
                            {orderType === "ASC" ? (
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Trip on</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Pending amount</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">
                          Booking amount
                          <span
                            onClick={() => handleOrderChange("paymentStatus")}
                            className={classNames(
                              "ml-2 flex-none rounded cursor-pointer",
                              orderBy === "paymentStatus"
                                ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                                : "invisible text-gray-400 group-hover:visible group-focus:visible"
                            )}
                          >
                            {orderType === "ASC" ? (
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Offer cost</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Offers booking</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Coupon</div>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="group inline-flex">Comment</div>
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {bookingsData &&
                      bookingsData.length > 0 &&
                      bookingsData.map((booking: any) => (
                        <tr
                          key={booking?.id}
                          className="hover:cursor-pointer"
                          onClick={() => handleBookingClick(booking?.id)}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {booking?.id}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.user?.firstName} {booking?.user?.lastName}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.trek?.name}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.members?.length}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.user?.phoneNumber}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.user?.email}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {getDateStr(booking?.createdAt)}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {getDateStr(booking?.scheduleDate)}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            ₹{booking?.remainingBalance}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            ₹{booking?.amountPaid} ({booking?.paymentStatus}) {booking.cancelled && <span className="text-red-500 font-bold">CANCELLED</span>}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            ₹{booking?.offers?.total}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            ₹{booking?.offers?.booking}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            ₹{booking?.coupon}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {booking?.remark}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="#"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              View
                              <span className="sr-only">, {booking.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    {bookingsData && bookingsData.length === 0 && (
                      <div className="flex w-full align-center justify-center">
                        <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                          No bookings found
                        </p>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                dataCount={dataCount}
                activePage={activePage}
                dataPerPage={take}
                handlePageChange={handlePageChange}
                setActivePage={setActivePage}
              />
            </div>
          </div>
          <Modal open={bookingInfoModal} setModal={setBookingInfoModal}>
            {bookingInfo && <>
              <div className="flex flex-row align-center justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Booking Information
                </h3>
                <XMarkIcon
                  onClick={() => setBookingInfoModal(false)}
                  className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-5 border-t border-gray-200">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Booking ID
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{bookingInfo?.id}</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Full name
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {bookingInfo?.user.firstName} {bookingInfo?.user.lastName}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {bookingInfo?.user?.email}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Phone Number
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {bookingInfo?.user?.phoneNumber}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">Trek</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {bookingInfo?.trek?.name}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Trip Date
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {bookingInfo?.scheduleDate}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Paid
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.amountPaid}</span>
                      <span className="ml-4 flex-shrink-0 text-sm text-gray-500">
                        ({bookingInfo?.paymentStatus})
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Trek Booking Amount
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.schedule?.bookingAmount * bookingInfo.members.length}</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Trek Total Amount
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.schedule?.totalAmount * bookingInfo.members.length}</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Offer Booking Amount
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.offers?.booking}</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Offer Total Amount
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.offers?.total}</span>
                    </dd>
                  </div>
                  {bookingInfo?.locationPrice && <>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Location Booking Amount
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span className="flex-grow">₹{bookingInfo?.locationPrice?.priceDifferenceBooking * bookingInfo.members.length}</span>
                      </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Location Total Amount
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span className="flex-grow">₹{bookingInfo?.locationPrice?.priceDifferenceTotal * bookingInfo.members.length}</span>
                      </dd>
                    </div>
                  </>}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Pending payment
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">₹{bookingInfo?.remainingBalance}</span>
                    </dd>
                  </div>
                  {bookingInfo.razorpayOrderId && (
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500">
                        Razorpay Order ID
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span className="flex-grow">
                          {bookingInfo.razorpayOrderId}
                        </span>
                      </dd>
                    </div>
                  )}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Members
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 rounded-md border border-gray-200"
                      >
                        {bookingInfo?.members?.map((member: any) => (
                          <Fragment key={member.id}>
                            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                              <div className="flex w-0 flex-1 items-center">
                                <span className="ml-2 w-0 flex-1 truncate">
                                  {member.title} {member.name} ({member.age})
                                </span>
                              </div>
                            </li>
                          </Fragment>
                        ))}
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="px-4 py-3 text-left sm:px-6">
                <button
                  type="button"
                  disabled={bookingInfo?.cancelled}
                  onClick={() => setCancelInfoModal(true)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-red-300"
                >
                  Cancel Booking
                </button>
              </div>
            </>}
          </Modal>

          <Modal open={cancelInfoModal} setModal={setCancelInfoModal}>
            <>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Cancel Booking
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to cancel this booking?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 text-right sm:px-6">
                <button
                  type="button"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
                  text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  m-2
                  "
                  onClick={
                    () => {
                      handleCancelBooking(bookingInfo.id);
                      setCancelInfoModal(false);
                      setBookingInfoModal(false);
                    }
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={() => setCancelInfoModal(false)}
                  className="inline-flex m-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  No
                </button>
              </div>
            </>
          </Modal>

          <Modal open={reportModal} setModal={setReportModal}>
            <div className="flex flex-col items-center justify-between">
              <h1 className="text-black font-semibold text-lg mb-4">Create CSV for bookings</h1>
              <div className="flex items-center justify-evenly w-full">
                <div>
                  <label htmlFor="fromDate" className="block text-center text-sm font-medium leading-6 text-gray-900">
                    From
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      value={startDate} onChange={(e) => setStartDate(format(new Date(e.target.value), "yyyy-MM-dd"))}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="endDate" className="block text-center text-sm font-medium leading-6 text-gray-900">
                    To
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      disabled={startDate === ''}
                      min={startDate}
                      value={endDate} onChange={(e) => setEndDate(format(new Date(e.target.value), "yyyy-MM-dd"))}
                      className="block w-full disabled:opacity-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="text-base font-semibold text-gray-900">Payment status</label>
                <fieldset className="mt-4">
                  <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    {bookingTypes.map((type) => (
                      <div key={type.id} className="flex items-center">
                        <input
                          id={type.id}
                          name="notification-method"
                          type="radio"
                          onChange={(e) => setPaymentStatus(e.target.id)}
                          defaultChecked={type.id === 'SUCCESSFUL'}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor={type.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          {type.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <button
                type="button"
                onClick={downloadCsv}
                className="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Download CSV
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
