import React from 'react'
import { jsPDF } from 'jspdf';
import CertificateBackground from '../assets/CertificateBackground.jpg';
import classNames from 'classnames';

interface props {
    name: string,
    trekName: string,
    date: string,
    location: string,
    height: string
}

export const PdfContainer = (data: props) => {
    const { name, trekName, date, location, height } = data;
    const pdfDownload = (e: any) => {
        e.preventDefault()
        let pdf: jsPDF = new jsPDF('l', 'pt', 'a4');
        let pWidth = pdf.internal.pageSize.width; // 595.28 is the width of a4
        let pHeight = pdf.internal.pageSize.height; // 595.28 is the width of a4
        let srcWidth = document?.getElementById('pdf-view')?.scrollWidth || 1;
        let srcHeight = document?.getElementById('pdf-view')?.scrollHeight || 1;
        let margin = 22; // narrow margin - 1.27 cm (36);
        let scale = ((pWidth + pHeight)) / (srcWidth + srcHeight);
        pdf.html((document.getElementById('pdf-view') || ""), {
            x: margin,
            y: margin,
            html2canvas: {
                scale: scale * 0.9,
            },
            callback: function () {
                const userName = name.replaceAll(" ", '-');
                const trekNameFile = trekName.replaceAll(" ", '-');
                const dateFile = date.replaceAll(" ", '-');
                const locationFile = location.replaceAll(" ", '-');
                const fileName = userName + '-' + dateFile + '-' + trekNameFile + '-' + locationFile
                // window.open(pdf.output('bloburl'));
                pdf.save(`${fileName.trim()}.pdf`);
            }
        });
        // let doc: any = new jsPDF("portrait", 'pt', 'A4');
        // doc.html(document.getElementById('pdf-view'), {
        //   callback: () => {
        //     doc.save('test.pdf');
        //   }
        // });
    }

    return <>
        <button className='absolute z-10 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700'
            onClick={pdfDownload}>Download PDF</button>
        <div id="pdf-view">
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative -top-24 left-0 text-4xl font-bold'>{trekName}</p>
            </div>
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative -top-6 -left-16 text-xl font-bold'>{name}</p>
            </div>
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative top-4 left-[4.5rem] text-lg font-bold'>{trekName}</p>
            </div>
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative top-16 left-2 text-xl font-bold'>{date}</p>
            </div>
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative top-28 left-0 text-xl font-bold'>{location}</p>
            </div>
            <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'>
                <p className='relative -bottom-40 right-36 text-lg font-bold'>{height}</p>
            </div>
            <img src={CertificateBackground} />
        </div>
    </>
}