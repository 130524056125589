import { useContext, useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { useMutation } from "@tanstack/react-query";
import { BookingsPayload, getBookingsByUserId } from "../hooks/bookings";
import { useAllUsers, filteringParams, getAllUsers } from "../hooks/user";
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'
import { AppLoaderContext } from "../context/AppLoaderContext";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Users() {
    const appLoaderContext = useContext(AppLoaderContext);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [selectedUserBookings, setSelectedUserBookings] = useState<BookingsPayload[]>([]);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0)
    const allUsersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllUsers(payload)
        },
        onSuccess(data, variables, context) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const getUserBookingsMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getBookingsByUserId({ id })
        },
        onSuccess(data, variables, context) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setSelectedUserBookings(data.bookings)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleUserMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload).then(res => {
            setUserData(res.users)
            setDataCount(res.total)
        })
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllUsers({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if (data) {
            setUserData(data.users)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (selectedUserId !== 0) {
            const user: any = userData.find((u: any) => u.id === selectedUserId);
            setSelectedUserData(user)
            getUserBookingsMutation.mutateAsync(selectedUserId)
        }
    }, [selectedUserId])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    if (isLoading) {
        return (
            <>
                <span>Loading...</span>
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                <span>Loading...</span>
            </>
        )
    }

    return (
        <>
            <div>
                {selectedUserId === 0 ? <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your system
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    ID
                                                    <span onClick={() => handleOrderChange("id")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Name
                                                    <span onClick={() => handleOrderChange("firstName")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "firstName" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Email
                                                    <span onClick={() => handleOrderChange("email")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "email" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Phone Number
                                                    <span onClick={() => handleOrderChange("phoneNumber")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "phoneNumber" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">View</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {userData && userData.length > 0 && userData.map((user: any) => (
                                            <tr key={user.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {user.id}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.firstName + ' ' + user.lastName}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.email}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.phoneNumber}</td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <div onClick={() => setSelectedUserId(user.id)} className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                                                        View<span className="sr-only">, {user.name}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {userData && userData.length === 0 &&
                                    <div>
                                        <span className="flex w-full align-center justify-center">
                                            <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No users found</p>
                                        </span>
                                    </div>
                                }
                            </div>
                            <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />
                        </div>
                    </div>
                </div> : <>
                    <div onClick={() => { setSelectedUserId(0); setSelectedUserBookings([]); return setSelectedUserData(null) }} className='w-[120px] flex justify-center items-center border-2 rounded px-2 py-1 mb-8 cursor-pointer'>
                        <ChevronLeftIcon className="h-5 w-5 flex" aria-hidden="true" />
                        <p>Go back</p>
                    </div>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">User Information</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and bookings.</p>
                        </div>
                        {selectedUserData && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedUserData['firstName'] + ' ' + selectedUserData['lastName']}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedUserData['email']}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Phone number</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedUserData['phoneNumber']}</dd>
                                </div>
                                <div className="py-4 px-6">
                                    <dt className="text-sm font-medium text-gray-500">Bookings</dt>
                                    <div className="inline-block min-w-full py-2 align-middle">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        <div className="group inline-flex">
                                                            ID
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        <div className="group inline-flex">
                                                            Trek
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        <div className="group inline-flex">
                                                            Date
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        <div className="group inline-flex">
                                                            Price (status)
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        <div className="group inline-flex">
                                                            Members
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {selectedUserBookings && selectedUserBookings.length > 0 && selectedUserBookings.map((booking: any) => (
                                                    <tr key={booking?.id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 align-baseline">
                                                            {booking?.id}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 align-baseline">{booking?.trek?.name}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 align-baseline">{booking?.scheduleDate}</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 align-baseline">₹{booking?.bookingAmount} ({booking?.paymentStatus})</td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 flex flex-col">
                                                            {booking?.members?.map((m: any) => <span>{m.title} {m.name}</span>)}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {selectedUserBookings && selectedUserBookings.length === 0 && <div className="flex w-full align-center justify-center">
                                                    <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No bookings found</p>
                                                </div>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </dl>
                        </div>}
                    </div>
                </>}
            </div>
        </>
    )
}