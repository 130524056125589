import { useState, useEffect, Fragment, useContext } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AdminLayout from "../components/AdminLayout";
import CreateinventoryItem from "../components/CreateInventoryItem";
import Pagination from "../components/Pagination";
import { useAllInventory, getAllInventory, InventoryCreatePayload, InventoryEditPayload, uploadImage, ImageUpload } from "../hooks/inventory";
import { filteringParams } from "../hooks/user";
import EditInventoryItem from "../components/EditInventoryItem";
import Modal from "../components/Modal";
import { CloudArrowUpIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { getImageUrl } from "../components/utils";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Inventory() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);
    
    const [OpenInventoryForm, setOpenInventoryForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [getUploadInventoryId, setUploadInventoryId] = useState(0);
    const [getEditInventory, setEditInventory] = useState<InventoryEditPayload>({
        type: '',
        description: '',
        size: '',
        imageUrl: '',
        color: '',
        id: 0,
    });

    const [imageFile, setImageFile] = useState<any>();
    const [previewInventoryItemId, setPreviewInventoryItemId] = useState(0);

    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [inventoryData, setInventoryData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0)
    const allUsersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllInventory(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const uploadInventoryImageMutation = useMutation({
        mutationFn: (payload: any) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return uploadImage(payload, getUploadInventoryId)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Inventory image uploaded!",
                    notificationMessage: "The image for the inventory was uploaded successfully."
                }
            })
            queryClient.invalidateQueries(['inventory']);
            setModal(false);
            setImageFile(null);
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while uploading the inventory image."
                }
            })
        }
    });

    const handleUserMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload).then(res => {
            setInventoryData(res.Inventory)
            setDataCount(res.total)
        })
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }
    const { data, isLoading, isError, error } = useAllInventory({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if (data) {
            setInventoryData(data.Inventory)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }


    const handleUploadClick = (event: any, inventoryId: number) => {
        event.preventDefault();
        
        setUploadInventoryId(inventoryId);
        setModal(true);
        setModalType('upload inventory image');
    };

    const handleFileUpload = () => {

        if (imageFile[0] != null) {
            const fileToUpload = imageFile[0];

            const fd = new FormData();
            fd.append('inventoryImage', fileToUpload);

            uploadInventoryImageMutation.mutateAsync(fd);
        }

        
    };

    const handlePreviewClick = (event: any, inventory: any) => {
        event.preventDefault();

        setModal(true);
        setModalType('preview inventory image');

        setPreviewInventoryItemId(inventory.id);
    };

    const handleEditClick = (event: any, inventory: any) => {
        event.preventDefault();

        setOpenEditForm(true);

        const formValues = {
            type: inventory.type,
            description: inventory.description,
            size: inventory.size,
            imageUrl: inventory.imageUrl,
            color: inventory.color,
            id: inventory.id,
          };
      
        setEditInventory(formValues);
    };


    if (isLoading) {
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
            {/* </AdminLayout> */}
        </>)
    }

    if (isError) {
        console.log(error)
        return (<>
            {/* <AdminLayout onChange={handleOnChange}> */}
                <span>error...</span>
            {/* </AdminLayout> */}
        </>)
    }

    return (
        // <AdminLayout onChange={handleOnChange}>

        <>

        {modalType === 'upload inventory image' && (
            <Modal open={modal} setModal={setModal}>
                
                <div className="flex items-center justify-center w-full group relative flex-col overflow-hidden">
                    {!imageFile ? (
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <CloudArrowUpIcon aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" />
                                {/* <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg> */}
                                <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" onChange={(e) => {setImageFile(e.target.files);}} />
                        </label>
                    ) : (
                        <div className="flex flex-1 flex-col space-y-2 p-4">
                            <div className="flex justify-end">
                                <XCircleIcon
                                    className="h-6 w-6 cursor-pointer text-red-500"
                                    onClick={() => setImageFile(null)}
                                />
                            </div>
                            <img
                                alt="uploaded image"
                                src={URL.createObjectURL(imageFile[0])}
                                className="h-64 w-full rounded-lg border-2 border-solid border-gray-100"
                            />
                            <div className="flex justify-center">
                                <button
                                    title="Upload"
                                    type="button"
                                    onClick={() => handleFileUpload()}
                                    className="inline-flex w-32 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                    )}
                    
                </div> 

            </Modal>
        ) 
        }
            


            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Inventory</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all items in the inventory
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => setOpenInventoryForm(true)}
                            >
                                Add Inventory
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    ID
                                                    <span onClick={() => handleOrderChange("id")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Item Type
                                                    <span onClick={() => handleOrderChange("type")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "type" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Description
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Size
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    color
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Image
                                                </div>
                                            </th>
                                            
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {inventoryData && inventoryData.length > 0 && inventoryData.map((item: any) => (
                                            <Fragment key={item.id}>
                                                <tr>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {item.id}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                        {item.type}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.description}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.size}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.color}</td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <div onClick={(event) => handlePreviewClick(event, item)} className={classNames("text-black", item.imageUrl ? "cursor-pointer" : "cursor-default")}>
                                                            {
                                                                item.imageUrl ?
                                                                    <img src={getImageUrl(item.imageUrl)} alt={item.type} className="h-12 w-12" />
                                                                    : "No Image"
                                                            }
                                                            <span className="sr-only">, {item.type}</span>
                                                        </div>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" onClick={(event) => handleUploadClick(event, item.id)} className="text-indigo-600 hover:text-indigo-900">
                                                            Upload<span className="sr-only">, {item.type}</span>
                                                        </a>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" onClick={(event) => handleEditClick(event, item)} className="text-indigo-600 hover:text-indigo-900">
                                                            Edit<span className="sr-only">, {item.type}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                {modalType === 'preview inventory image' &&
                                                    previewInventoryItemId === item.id && (
                                                        <Modal open={modal} setModal={setModal}>
                                                            <a href={item.imageUrl} target="_blank"><img src={getImageUrl(item.imageUrl)} alt="preview img" /></a>
                                                        </Modal>
                                                    )
                                                }
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                {inventoryData && inventoryData.length === 0 && <div className="flex w-full align-center justify-center">
                                            <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No items found</p>
                                        </div>}
                            </div>
                            <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage}/>
                        </div>
                    </div>
                </div>
                <CreateinventoryItem open={OpenInventoryForm} setOpen={setOpenInventoryForm}></CreateinventoryItem>
                <EditInventoryItem open={openEditForm} setOpen={setOpenEditForm} inventoryData={getEditInventory}></EditInventoryItem>
            </div>
        </>
        // </AdminLayout>
    )
}