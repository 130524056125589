import { useState, useEffect, Fragment, useContext } from "react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { useFeaturedTreksAll, toggleFeatured, useAllTreks, createFeaturedTrek, FeaturedPayload, getFeaturedTreksAll } from "../hooks/trek";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { NotificationContext } from "../context/NotificationContext";
import { filteringParams } from "../hooks/user";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Featured() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const trekListData = useAllTreks({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    });
    const trekList = trekListData?.data?.treks;
    const [orderBy, setOrderBy] = useState("id");
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [orderType, setOrderType] = useState("ASC");
    const [formModal, setFormModal] = useState(false);
    const [newFeaturedTrekType, setNewFeaturedTrekType] = useState("");
    const [newFeaturedTrekId, setNewFeaturedTrekId] = useState(0);
    const [availableFeatureTreks, setAvailableFeatureTreks] = useState([]);
    const [availableFooterTreks, setAvailableFooterTreks] = useState([]);
    const [fTreks, setFTreks] = useState([]);
    const { data, isLoading, isFetched, isError } = useFeaturedTreksAll({
        skip,
        take,
        orderBy,
        orderType,
        keyword
    });
    let allTreks = data?.treks;
    const approveSocialFeedsMutation = useMutation({
        mutationFn: (payload: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleFeatured(payload)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['FeaturedTreks'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const createFeaturedTrekMutation = useMutation({
        mutationFn: (payload: FeaturedPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createFeaturedTrek([payload])
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['FeaturedTreks'])
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setFormModal(false)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const allTreksMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
          appLoaderContext?.dispatch({
            type: "SHOW"
          })
          return getFeaturedTreksAll(payload)
        },
        onSuccess: (data) => {
        allTreks = data.treks
        console.log(data.treks)
        console.log(allTreks)
        setFTreks(data.treks)
          appLoaderContext?.dispatch({
            type: "HIDE"
          })
        },
        onError: () => {
          appLoaderContext?.dispatch({
            type: "HIDE"
          })
        }
      });

    useEffect(() => {
        if (allTreks && allTreks.length > 0 && trekList && trekList.length > 0) {
            const featuredTreks = allTreks.filter((at: any) => at.type === 'FEATURED')
            const footerTreks = allTreks.filter((at: any) => at.type === 'FOOTER')
            const featuredTreksIds = featuredTreks.map((at: any) => at.trekId)
            const footerTreksIds = footerTreks.map((at: any) => at.trekId)
            const availableFeatureTreksList = trekList.filter((tl: any) => !featuredTreksIds.includes(tl.id))
            const availableFooterTreksList = trekList.filter((tl: any) => !footerTreksIds.includes(tl.id))
            setFTreks(allTreks)
            setAvailableFeatureTreks(availableFeatureTreksList)
            setAvailableFooterTreks(availableFooterTreksList)
        }
    }, [allTreks, trekList])

    const handleSubmit = async () => {
        const resp = await createFeaturedTrekMutation.mutate({
            trekId: newFeaturedTrekId,
            type: newFeaturedTrekType
        })
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        allTreksMutation.mutateAsync({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword,
          })
    }

    if (isLoading) {
        return (<>
            <span>Loading...</span>
        </>)
    }

    if (isError) {
        return (<>
            <span>error...</span>
        </>)
    }

    return (<div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">Featured/Footer treks</h1>
                <p className="mt-2 text-sm text-gray-700">
                    A list of all Featured and Footer Treks
                </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    onClick={() => setFormModal(true)}
                >
                    Add Featured/Footer Trek
                </button>
            </div>
        </div>
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    <div className="group inline-flex">
                                        ID
                                        <span onClick={() => handleOrderChange("id")} className={classNames(
                                            'ml-2 flex-none rounded cursor-pointer',
                                            orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                        )}>
                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    <div className="group inline-flex">
                                        Trek Id
                                        <span onClick={() => handleOrderChange("trekId")} className={classNames(
                                            'ml-2 flex-none rounded cursor-pointer',
                                            orderBy === "trekId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                        )}>
                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    <div className="group inline-flex">
                                        Trek Name
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div className="group inline-flex">
                                        Type
                                        <span onClick={() => handleOrderChange("type")} className={classNames(
                                            'ml-2 flex-none rounded cursor-pointer',
                                            orderBy === "type" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                        )}>
                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div className="group inline-flex">
                                        Active
                                        <span onClick={() => handleOrderChange("active")} className={classNames(
                                            'ml-2 flex-none rounded cursor-pointer',
                                            orderBy === "active" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                        )}>
                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {fTreks && fTreks.length > 0 && fTreks.map((item: any) => (
                                <tr key={item.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.id}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                        {item.trekId}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                        {item?.trek?.name}
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.type}</td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                        <Switch
                                            checked={item.active}
                                            onChange={(e: any) => approveSocialFeedsMutation.mutateAsync(item.id)}
                                            className={classNames(
                                                item.active ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                className={classNames(
                                                    item.active ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            >
                                                <span
                                                    className={classNames(
                                                        item.active ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                        <path
                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className={classNames(
                                                        item.active ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Switch>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {fTreks && fTreks.length === 0 && <div className="flex w-full align-center justify-center">
                        <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No items found</p>
                    </div>}
                {/* <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage}/> */}
                </div>
                <Transition.Root show={formModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => { setFormModal(false); setNewFeaturedTrekId(0); setNewFeaturedTrekType("") }}>
                        <div className="fixed inset-0" />

                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                            <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                                <div className="h-0 flex-1 overflow-y-auto">
                                                    <div className="bg-slate-700 py-6 px-4 sm:px-6">
                                                        <div className="flex items-center justify-between">
                                                            <Dialog.Title className="text-lg font-medium text-white">New
                                                                Featured/Footer trek</Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className="rounded-md bg-slate-700 text-slate-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                    onClick={() => { setFormModal(false); setNewFeaturedTrekId(0); setNewFeaturedTrekType("") }}
                                                                >
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-1">
                                                            <p className="text-sm text-slate-300">
                                                                Select which trek gets to be added to the list of FEATURED/FOOTER treks.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-between">
                                                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                            <div className="space-y-6 pt-6 pb-5">
                                                                <div>
                                                                    <label className="text-base font-medium text-gray-900">Type</label>
                                                                    <fieldset className="mt-4">
                                                                        <legend className="sr-only">Featured type</legend>
                                                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                            <div className="flex items-center">
                                                                                <input
                                                                                    id="FEATURED"
                                                                                    name="notification-method"
                                                                                    type="radio"
                                                                                    onChange={(e) => setNewFeaturedTrekType(e.target.id)}
                                                                                    defaultChecked={newFeaturedTrekType === 'FEATURED'}
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label htmlFor="featured" className="ml-3 block text-sm font-medium text-gray-700">
                                                                                    Featured Trek
                                                                                </label>
                                                                            </div>
                                                                            <div className="flex items-center">
                                                                                <input
                                                                                    id="FOOTER"
                                                                                    name="notification-method"
                                                                                    type="radio"
                                                                                    onChange={(e) => setNewFeaturedTrekType(e.target.id)}
                                                                                    defaultChecked={newFeaturedTrekType === 'FOOTER'}
                                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                                />
                                                                                <label htmlFor="footer" className="ml-3 block text-sm font-medium text-gray-700">
                                                                                    Footer Trek
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-8">
                                                                            {newFeaturedTrekType === 'FEATURED' && <div>
                                                                                <label htmlFor="featuredTrek" className="block text-sm font-medium text-gray-700">
                                                                                    Select trek to add
                                                                                </label>
                                                                                <select
                                                                                    id="featuredTrek"
                                                                                    name="featuredTrek"
                                                                                    onChange={(e: any) => setNewFeaturedTrekId(e.target.value)}
                                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                                    defaultValue={0}
                                                                                >
                                                                                    <option value={0}>Select</option>
                                                                                    {availableFeatureTreks?.map((ft: any) => <option key={ft.id} value={ft.id}>{ft.name}</option>)}
                                                                                </select>
                                                                            </div>}
                                                                            {newFeaturedTrekType === 'FOOTER' && <div>
                                                                                <label htmlFor="footerTrek" className="block text-sm font-medium text-gray-700">
                                                                                    Select trek to add
                                                                                </label>
                                                                                <select
                                                                                    id="footerTrek"
                                                                                    name="footerTrek"
                                                                                    onChange={(e: any) => setNewFeaturedTrekId(e.target.value)}
                                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                                    defaultValue={0}
                                                                                >
                                                                                    <option value={0}>Select</option>
                                                                                    {availableFooterTreks?.map((ft: any) => <option key={ft.id} value={ft.id}>{ft.name}</option>)}
                                                                                </select>
                                                                            </div>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                    <button
                                                        type="button"
                                                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                                        onClick={() => { setFormModal(false); setNewFeaturedTrekId(0); setNewFeaturedTrekType("") }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={handleSubmit}
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </div>
    </div>)
}
