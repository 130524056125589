import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface ExpeditionPayload {
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface EditExpeditionPayload {
  id: number;
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface FeaturedPayload {
  expeditionId: number;
  type: string;
}

const getExpeditionById = async (expeditionId: number) => {
  const url = '/expeditions/' + expeditionId
  const resp = await apiInstance.get(url)
  return resp.data
}

const getScheduleById = async (expeditionId: number) => {
  const url = '/schedules/expedition/' + expeditionId
  const resp = await apiInstance.get(url)
  return resp.data
}

const createExpedition = async (payload: ExpeditionPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const editExpedition = async (payload: EditExpeditionPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const useExpeditionById = (expeditionId: number) => {
  return useQuery(['expedition', expeditionId], () => getExpeditionById(expeditionId))
}

const useScheduleById = (expeditionId: number) => {
  return useQuery(['schedule', expeditionId], () => getScheduleById(expeditionId))
}

const getAllExpeditions = async (params?: filteringParams) => {
  const url = '/expeditions';
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const useAllExpeditions = (params?: filteringParams) => {
  return useQuery(['expeditions'], () => getAllExpeditions(params))
}

const uploadImage = async (payload: any, expeditionId: number) => {
  const url = '/treks/uploadImage/' + expeditionId;
  return apiInstance.post(url, payload);
}

const getFeaturedExpeditions = async () => {
  const url = '/featured/active'
  const resp = await apiInstance.get(url)
  return resp.data
}

const getFeaturedExpeditionsAll = async (params?: filteringParams) => {
  const url = '/featured'
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const createFeaturedExpedition = async (payload: FeaturedPayload[]) => {
  const url = '/featured/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data
}

const toggleFeatured = async (id: number) => {
  const url = '/featured/toggle';
  const resp = await apiInstance.post(url, id)
  return resp.data;
}

const archiveExpedition = async (id: number) => {
  const url = '/treks/archive/' + id;
  const resp = await apiInstance.put(url)
  return resp.data;
}

const useFeaturedExpeditions = () => {
  return useQuery(['FeaturedExpeditionsActive'], () => getFeaturedExpeditions())
}

const useFeaturedExpeditionsAll = (params?: filteringParams) => {
  return useQuery(['FeaturedExpeditions'], () => getFeaturedExpeditionsAll(params))
}

export { getExpeditionById, useExpeditionById, getAllExpeditions, useAllExpeditions, createExpedition, editExpedition, uploadImage, useScheduleById, useFeaturedExpeditions, useFeaturedExpeditionsAll, getFeaturedExpeditions, toggleFeatured, createFeaturedExpedition, archiveExpedition, getFeaturedExpeditionsAll }
