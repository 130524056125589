import AdminLayout from "../components/AdminLayout";
import { useAllTreks } from "../hooks/trek";
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from "react";

export default function CustomizedTrek() {

    const trekData = useAllTreks()
    const treks = trekData?.data?.treks;
    console.log(treks);

    const [options, setOptions] = useState(['Option 1', 'Option 2', 'Option 3']);

    useEffect( () => {

        const gettreknames = () => {
            const gettrekname : any[] = [];
 
            treks?.map( (t: any) => {
                gettrekname.push(t.name);
                console.log('hi');
            })
            console.log(gettrekname);

            setOptions(gettrekname);
        }

        gettreknames();

    }, [])

    return (
        // <AdminLayout onChange={() => console.log("test")}>
            <div className="container relative mx-auto">
                <div className="flex flex-wrap items-center ">
                    <div className="w-full md:w-1/2 px-4 mr-auto ml-auto">
                        <h1>Footer</h1>

                        <Multiselect 
                            isObject={false}
                            options={options}
                            onRemove={ (event) => { console.log(event) }}
                            onSelect={ (event) => { console.log(event) }}
                            showCheckbox
                        />

                        <button type="submit" title="Submit" className="px-[18px] py-[10px] rounded bg-[#ffc107] mb-[5px] w-full min-h-[50px] leading-8 text-lg cursor-pointer inline-block font-bold uppercase no-underline text-white text-center">Submit</button>
                        
                    </div>

                    <div className="w-full md:w-1/2 px-4 mr-auto ml-auto">
                        <h1>Featured</h1>

                        <Multiselect 
                            isObject={false}
                            options={options}
                            onRemove={ (event) => { console.log(event) }}
                            onSelect={ (event) => { console.log(event) }}
                            showCheckbox
                        />

                    </div>

                </div>
            </div>
        // </AdminLayout>
    )
}