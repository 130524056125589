import { useContext } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { AppLoaderContext } from "../context/AppLoaderContext";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const AppLoader = () => {
  const appLoaderContext = useContext(AppLoaderContext)
  return (
    <div className={classNames("fixed top-0 left-0 right-0 bottom-0 z-[100]", !appLoaderContext?.showLoader && "hidden")}>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-60"></div>
      <div className="flex h-full items-center justify-center">
        <PuffLoader
          color="#FFF"
          loading={appLoaderContext?.showLoader}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}