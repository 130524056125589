import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3BottomLeftIcon,
    BellIcon,
    HomeIcon,
    InboxIcon,
    UsersIcon,
    MapPinIcon,
    TagIcon,
    XMarkIcon,
    BriefcaseIcon,
    CalendarDaysIcon,
    DocumentChartBarIcon
} from '@heroicons/react/24/solid'
import { EnvelopeOpenIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import logo from '../assets/logo.jpeg';

import { Navigate, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { logout } from '../hooks/auth';

interface NavType {
    name: string;
    path: string;
    icon: any;
}

const navigation: NavType[] = [
    { name: 'Dashboard', path: '/', icon: HomeIcon },
    { name: 'Treks', path: '/treks', icon: BriefcaseIcon },
    { name: 'Adventures', path: '/adventures', icon: BriefcaseIcon },
    { name: 'Expeditions', path: '/expeditions', icon: BriefcaseIcon },
    { name: 'Holidays', path: '/holidays', icon: BriefcaseIcon },
    { name: 'Bookings', path: '/bookings', icon: EnvelopeOpenIcon },
    { name: 'Meta', path: '/metaentries', icon: DocumentChartBarIcon },
    { name: 'Inventory', path: '/inventory', icon: TagIcon },
    { name: 'Trek Inventory', path: '/trekinventory', icon: TagIcon },
    { name: 'Coupons', path: '/coupons', icon: UsersIcon },
    { name: 'Users', path: '/users', icon: UsersIcon },
    { name: 'Schedules', path: '/schedules', icon: CalendarDaysIcon },
    { name: 'Featured Treks', path: '/featured', icon: CalendarDaysIcon },
    { name: 'Featured Holidays', path: '/featuredholidays', icon: CalendarDaysIcon },
    { name: 'Social Feeds', path: '/socialfeeds', icon: CalendarDaysIcon },
    { name: 'Travel Cards', path: '/travelcards', icon: CalendarDaysIcon },
    { name: 'Trainers', path: '/trainers', icon: UsersIcon },
    { name: 'Enquiries', path: '/enquiries', icon: UsersIcon },
    { name: 'Location Prices', path: '/locationprices', icon: MapPinIcon },
    { name: 'Certificates', path: '/certificates', icon: DocumentChartBarIcon },
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function AdminLayout() {
    const userContext = useAuthContext();
    const { pathname } = useLocation();

    const navigate = useNavigate()
    const outlet = useOutlet();
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const getActiveTabName = navigation.find(it => it.path === pathname)?.name || navigation[0].name
    const [activeSidebarTab, setActiveSidebarTab] = useState(getActiveTabName)

    const handleTabChange = (item: NavType) => {
        setActiveSidebarTab(item.name)
        navigate(item.path)
    }

    if (!userContext.isUserAuthenticated() || (userContext.isUserAuthenticated() && !userContext.isAdmin())) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-700 pt-5 pb-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex flex-shrink-0 items-center px-4">
                                        <img src="/logo.jpeg" alt="me" width="64" height="64" />
                                    </div>
                                    <div className="mt-5 h-0 flex flex-col justify-between flex-1 overflow-y-auto">
                                        <nav className="space-y-1 px-2">
                                            {navigation.map((item) => (
                                                <div
                                                    key={item.name}
                                                    onClick={() => handleTabChange(item)}
                                                    className={classNames(
                                                        item.name === activeSidebarTab ? 'bg-slate-800 text-white' : 'text-slate-100 hover:bg-slate-600',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                                                    )}
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-slate-300"
                                                        aria-hidden="true" />
                                                    {item.name}
                                                </div>
                                            ))}
                                        </nav>
                                        <button
                                            className="w-full inline-flex items-center justify-center border border-transparent bg-slate-800 p-4 text-sm font-medium text-white shadow-sm hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto"
                                            onClick={() => {
                                                logout()
                                            }}
                                        >
                                            Signout
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="w-14 flex-shrink-0" aria-hidden="true">
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                    <div className="flex flex-grow flex-col overflow-y-auto bg-slate-700 pt-5">
                        <div className="flex flex-shrink-0 items-center px-4">
                            <img src={logo} alt="me" width="48" height="48" />
                        </div>
                        <div className="mt-5 flex flex-1 flex-col">
                            <nav className="flex-1 space-y-1 px-2 pb-4">
                                {navigation.map((item) => (
                                    <div
                                        key={item.name}
                                        onClick={() => handleTabChange(item)}
                                        className={classNames(
                                            item.name === activeSidebarTab ? 'bg-slate-800 text-white' : 'text-slate-100 hover:bg-slate-600',
                                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer'
                                        )}
                                    >
                                        <item.icon className="mr-3 h-6 w-6 flex-shrink-0 text-slate-300" aria-hidden="true" />
                                        {item.name}
                                    </div>
                                ))}
                            </nav>
                            <button
                                className="inline-flex items-center justify-center border border-transparent bg-slate-800 p-4 text-sm font-medium text-white shadow-sm hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                    logout()
                                }}
                            >
                                Signout
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 flex-col md:pl-64">
                    <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow md:hidden">
                        <button
                            type="button"
                            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <main>
                        <div className="p-6">
                            <main>{outlet}</main>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
