import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import AppContainer from './AppContainer';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
  </React.StrictMode>
);