import { Fragment, useContext, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pagination from "../components/Pagination";
import { filteringParams } from "../hooks/socialfeed";
import { createCoupon, CreateCouponPayload, getAllCoupons, useAllCoupons, enableCoupons, disableCoupons, toggleByCouponCode, getByTrekId } from "../hooks/coupons";
import Modal from "../components/Modal";
import { useAllTreks } from "../hooks/trek";
import { AppLoaderContext } from "../context/AppLoaderContext";
import { Switch } from "@headlessui/react";
import { NotificationContext } from "../context/NotificationContext";
import { XCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Coupons() {

    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("ASC");
    const [keyword, setKeyword] = useState("");
    const [couponsData, setCouponsData] = useState([]);
    const [searchTimer, setSearchTimer] = useState<any>(0);
    const [trekId, setTrekId] = useState(0)
    const [trekIdEdit, setTrekIdEdit] = useState(0)
    const [selectedTrekId, setSelectedTrekId] = useState(0)
    const trekdata = useAllTreks({
        take: 0,
        skip: 0,
        orderBy: 'id',
        orderType: 'ASC',
        keyword: ''
    });
    const allTreks = trekdata?.data?.treks;
    const [value, setValue] = useState(allTreks?.[0]?.id);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [validCheck, setValidCheck] = useState(true);

    const [addCoupon, setAddCoupon] = useState<any>({
        amount: 0,
        couponCode: '',
        trekIds: [],
        isValid: true,
    });

    const [editCoupon, setEditCoupon] = useState<any>({
        id: 0,
        amount: 0,
        couponCode: '',
        trekIds: [],
        isValid: true,
    });

    const [editCouponId, setEditCouponId] = useState(0);


    const enableCouponMutation = useMutation({
        mutationFn: () => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return enableCoupons()
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['coupons']);
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });


    const disableCouponMutation = useMutation({
        mutationFn: () => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return disableCoupons()
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['coupons']);
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });


    const handleEnableCoupon = () => {
        enableCouponMutation.mutateAsync()
    }

    const handleDisableCoupon = () => {
        disableCouponMutation.mutateAsync()
    }

    const allUsersMutation = useMutation({
        mutationFn: () => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllCoupons();
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })

    const handleUserMutation = (): void => {
        allUsersMutation.mutateAsync().then(res => {
            setCouponsData(res.coupons);
            setDataCount(res.total);
        })
    };

    const createCouponMutation = useMutation({
        mutationFn: (payload: CreateCouponPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return createCoupon(payload)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Coupon created!",
                    notificationMessage: "The coupon was created successfully."
                }
            })
            setAddCoupon({
                amount: 0,
                couponCode: '',
                trekIds: [],
                isValid: true,
            })
            queryClient.invalidateQueries(['coupons']);
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while creating the coupon."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const toggleByCouponCodeMutation = useMutation({
        mutationFn: ({ couponCode, trekIds } : {couponCode: string, trekIds: number[]}) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleByCouponCode(couponCode, trekIds)
        },
        onSuccess: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            if(selectedTrekId === 0){
                queryClient.invalidateQueries(['coupons']);
            } else {
                couponByTrekIdMutation.mutateAsync(selectedTrekId)
            }
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    const couponByTrekIdMutation = useMutation({
        mutationFn: (trekId: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getByTrekId(trekId)
        },
        onSuccess: (data) => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setCouponsData(data.coupons)
            // setDataCount(data.total);
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });
    
    useEffect(() => {
        if (selectedTrekId === 0) {
            handleUserMutation()
        } else {
            couponByTrekIdMutation.mutateAsync(selectedTrekId)
        }
    }, [selectedTrekId])

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "ASC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleUserMutation()
    }

    const { data, isLoading, isError, error } = useAllCoupons()



    const handleCouponByTrek = (event: any) => {
        setSelectedTrekId(parseInt(event.target.value))
    }

    useEffect(() => {
        if (data && selectedTrekId === 0) {
            setCouponsData(data.coupons);
            setDataCount(data.total);
        }
    }, [data])

    useEffect(() => {
        if (searchTimer > 0) {
            clearTimeout(searchTimer);
        }
        setSearchTimer(setTimeout(() => {
            pageReset()
            return handleUserMutation()
        }, 400));
    }, [keyword])

    function handleOnChange(e: string): void {
        setKeyword(e)
    }

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleUserMutation()
    }

    function pageReset(): void {
        setActivePage(1)
        setSkip(0)
    }

    const handleAddCouponClick = () => {
        setModal(true);
        setModalType('add coupon');
    };

    const handleAddFormChange = (event: { preventDefault: () => void; target: { getAttribute: (arg0: string) => any; value: any; }; }) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;

        const newFormData: any = { ...addCoupon };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;
        setAddCoupon(newFormData);
    };

    const handleTrekSelect = (event: any) => {
        const newFormData: any = { ...addCoupon };
        const trekId = parseInt(event.target.value);
        setTrekId(0)
        if (trekId === 0) return;
        else if (trekId === -1) {
            const treks = allTreks?.map((trek: any) => trek.id);
            const newTreks = treks.filter((t: any) => !checkExists(t))
            newFormData['trekIds'] = newTreks;
        } else {
            let arr: number[] = newFormData['trekIds']
            const found = arr.findIndex((t: number) => t === trekId)
            if (found > -1) {
                arr.splice(found, 1);
                newFormData['trekIds'] = arr;
            } else {
                arr.push(trekId)
                newFormData['trekIds'] = arr;
            }
        }
        setAddCoupon(newFormData);
    };

    function removeTrek(trekId: number) {
        const newFormData: any = { ...addCoupon };
        let arr: number[] = newFormData['trekIds']
        const found = arr.findIndex((t: number) => t === trekId)
        if (found > -1) {
            arr.splice(found, 1);
            newFormData['trekIds'] = arr;
        } else {
            arr.push(trekId)
            newFormData['trekIds'] = arr;
        }
        setAddCoupon(newFormData);
    }

    function removeTrekFromEdit(trekId: number) {
        const newFormData: any = { ...editCoupon };
        let arr: number[] = newFormData['trekIds']
        const found = arr.findIndex((t: number) => t === trekId)
        if (found > -1) {
            arr.splice(found, 1);
            newFormData['trekIds'] = arr;
        } else {
            arr.push(trekId)
            newFormData['trekIds'] = arr;
        }
        setEditCoupon(newFormData);
    }

    const toggleChange = (e: any) => {
        setValidCheck(e.target.checked);
        const newFormData: any = { ...addCoupon };
        newFormData['isValid'] = e.target.checked;
        setAddCoupon(newFormData)
    };

    const handleAddFormSubmit = (event: Event) => {
        event.preventDefault();
        console.log(addCoupon);

        createCouponMutation.mutate({ ...addCoupon });

        setModal(false);
    };

    const handleEditClick = (event: any, coupon: any) => {
        event.preventDefault();

        setModal(true);

        setModalType('edit coupon');
        setEditCouponId(coupon.id);

        const formValues = {
            id: coupon.id,
            amount: coupon.amount,
            couponCode: coupon.couponCode,
            trekIds: coupon.trekIds,
            isValid: coupon.isValid,
        };

        setEditCoupon(formValues);
    };

    const handleEditFormChange = (event: any) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;

        const newFormData: any = { ...editCoupon };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;

        setEditCoupon(newFormData);
    };

    const handleEditTrekSelect = (event: any) => {
        const newFormData: any = { ...editCoupon };
        const trekId = parseInt(event.target.value);
        setTrekIdEdit(0)
        if (trekId === 0) return;
        else if (trekId === -1) {
            const treks = allTreks?.map((trek: any) => trek.id);
            const newTreks = treks.filter((t: any) => !checkExists(t))
            newFormData['trekIds'] = newTreks;
        } else {
            let arr: number[] = newFormData['trekIds']
            const found = arr.findIndex((t: number) => t === trekId)
            if (found > -1) {
                arr.splice(found, 1);
                newFormData['trekIds'] = arr;
            } else {
                arr.push(trekId)
                newFormData['trekIds'] = arr;
            }
        }
        setEditCoupon(newFormData);
        // const newFormData: any = { ...editCoupon };
        // newFormData['trekId'] = parseInt(event.target.value);
        // setEditCoupon(newFormData);
    };

    const toggleEditChange = (e: any) => {
        setValidCheck(e.target.checked);
        const newFormData: any = { ...editCoupon };
        newFormData['isValid'] = e.target.checked;
        setEditCoupon(newFormData)
    };

    const handleEditFormSubmit = (event: Event) => {
        event.preventDefault();

        const editedCoupon = {
            id: editCoupon.id,
            amount: editCoupon.amount,
            couponCode: editCoupon.couponCode,
            trekIds: editCoupon.trekIds,
            isValid: editCoupon.isValid,
        };

        console.log(editedCoupon);

        createCouponMutation.mutate(editCoupon);

        setEditCouponId(0);
        setModal(false);
    };

    function getTrekName(trekId: number): string {
        if(!trekId) return "";
        else {
            const trek = allTreks.find((t: any) => t.id === trekId)
            if(!trek) return "";
            else return trek.name
        }
    }

    function getTrekNames(trekIds: number[]): string {
        if(!trekIds || trekIds.length === 0) return "";
        else {
            let str = "";
            trekIds.map((tId: any, index: number) => {
                const trek = allTreks.find((t: any) => t.id === tId)
                str += trek.name
                if(index !== trekIds.length-1){
                    str += ", "
                }
            })
            return str
        }
    }

    function checkExists(trekId: number): boolean {
        // const idx = couponsData.findIndex((ti: any) => (ti.cou === trekInventory['inventoryId']) && (ti.trekId === trekId))
        // return idx > -1;
        return false;
    }


    if (isLoading) {
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    return (
        <>

            {modalType === 'add coupon' && (
                <Modal open={modal} setModal={setModal}>
                    <div className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow sm:p-6 md:p-8">
                        <h5 className="mb-7 text-xl font-medium text-gray-900">
                            Add Coupon
                        </h5>
                        <div className="group relative z-0 mb-6 w-full">
                            <input
                                type="number"
                                name="amount"
                                id="editamount"
                                onChange={handleAddFormChange}
                                className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent p-2.5 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                                placeholder=" "
                                required
                            />
                            <label
                                htmlFor="editamount"
                                className="absolute top-3 -z-10 ml-3 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-blue-600"
                            >
                                Amount
                            </label>
                        </div>
                        <div className="group relative z-0 mb-6 w-full">
                            <input
                                type="text"
                                name="couponCode"
                                id="editcouponCode"
                                onChange={handleAddFormChange}
                                className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent p-2.5 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                                placeholder=" "
                                required
                            />
                            <label
                                htmlFor="editcouponCode"
                                className="absolute top-3 -z-10 ml-3 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-blue-600"
                            >
                                Coupon Code
                            </label>
                        </div>
                        <div className="group relative z-0 mb-6 w-full">
                            <label htmlFor="editalltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select treks</label>
                            <select id="editalltreks" aria-label="alltreks" name="trekName" value={trekId} onChange={e => handleTrekSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                <option value={0}>Select Treks</option>
                                <option value={-1}>All Treks</option>
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                        {addCoupon.trekIds.length > 0 && <div className="flex flex-wrap mb-6">
                            {addCoupon.trekIds.map((t: any) => <p onClick={() => removeTrek(t)} key={t} className="flex items-center p-2 bg-slate-100 rounded-lg m-1 text-xs cursor-pointer hover:shadow">{getTrekName(t)} <XCircleIcon className="h-4 w-4 ml-2" /></p>)}
                        </div>}
                        {/* <div className="group relative z-0 mb-6 w-full">
                            <label className="relative sm:col-span-2 inline-flex items-center mb-2 cursor-pointer">
                                <input type="checkbox" checked={validCheck} onChange={e => toggleChange(e)} className="sr-only peer" />
                                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Disable this inventory!!</span>
                            </label>
                        </div> */}
                        <button
                            type="button"
                            onClick={(event: any) => handleAddFormSubmit(event)}
                            className="w-full rounded-lg bg-blue-700 p-2.5 px-5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:w-auto"
                        >
                            Add Coupon
                        </button>
                    </div>
                </Modal>
            )}

            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Coupons</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the coupons in your system
                            </p>
                        </div>
                        <div className="flex items-center gap-4">
                            <select
                                id="alltreks"
                                aria-label="alltreks"
                                name="trekName"
                                value={selectedTrekId}
                                onChange={handleCouponByTrek}

                                className="mt-1 block  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value={0}>All Treks</option>
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id} disabled={checkExists(t.id)}>{t.name}</option>)}
                            </select>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                onClick={handleDisableCoupon}
                                className="inline-flex mr-8 items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Disable all coupons
                            </button>
                            <button
                                type="button"
                                onClick={handleEnableCoupon}
                                className="inline-flex mr-8 items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Enable all coupons
                            </button>
                            <button
                                type="button"
                                onClick={handleAddCouponClick}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Add Coupon
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Coupon Code
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Amount
                                                </div>
                                            </th>
                                            {selectedTrekId === 0 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Trek Name
                                                </div>
                                            </th>}
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Active
                                                </div>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {

                                            couponsData && couponsData.length > 0 && couponsData.map((coupon: any) => (
                                                <Fragment key={coupon.couponCode}>
                                                    <tr>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {coupon.couponCode}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{coupon.amount}</td>
                                                        {selectedTrekId === 0 && <td className="whitespace-normal py-4 px-3 text-sm text-gray-500">{getTrekNames(coupon.trekIds)}</td>}
                                                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                            <Switch
                                                                checked={coupon.isValid}
                                                                onChange={(e: any) => toggleByCouponCodeMutation.mutateAsync({ couponCode: coupon.couponCode, trekIds: coupon.trekIds })}
                                                                className={classNames(
                                                                    coupon.isValid ? 'bg-indigo-600' : 'bg-gray-200',
                                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                                )}
                                                            >
                                                                <span className="sr-only">Use setting</span>
                                                                <span
                                                                    className={classNames(
                                                                        coupon.isValid ? 'translate-x-5' : 'translate-x-0',
                                                                        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            coupon.isValid ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                            <path
                                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                                stroke="currentColor"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            coupon.isValid ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </Switch>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#"
                                                                onClick={(event) =>
                                                                    handleEditClick(event, coupon)
                                                                }
                                                                className="text-indigo-600 hover:text-indigo-900 cursor">
                                                                Edit
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    {modalType === 'edit coupon' &&
                                                        editCouponId === coupon.id && (
                                                            <Modal open={modal} setModal={setModal}>
                                                                <div className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow sm:p-6 md:p-8">
                                                                    <h5 className="mb-7 text-xl font-medium text-gray-900">
                                                                        Edit Coupon
                                                                    </h5>
                                                                    <div className="group relative z-0 mb-6 w-full">
                                                                        <input
                                                                            type="number"
                                                                            name="amount"
                                                                            id="amount"
                                                                            onChange={handleEditFormChange}
                                                                            defaultValue={editCoupon.amount}
                                                                            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent p-2.5 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                                                                            placeholder=" "
                                                                            required
                                                                        />
                                                                        <label
                                                                            htmlFor="amount"
                                                                            className="absolute top-3 -z-10 ml-3 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-blue-600"
                                                                        >
                                                                            Amount
                                                                        </label>
                                                                    </div>
                                                                    <div className="group relative z-0 mb-6 w-full">
                                                                        <input
                                                                            type="text"
                                                                            name="couponCode"
                                                                            id="couponCode"
                                                                            onChange={handleEditFormChange}
                                                                            defaultValue={editCoupon.couponCode}
                                                                            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent p-2.5 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                                                                            placeholder=" "
                                                                            required
                                                                        />
                                                                        <label
                                                                            htmlFor="couponCode"
                                                                            className="absolute top-3 -z-10 ml-3 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-blue-600"
                                                                        >
                                                                            Coupon Code
                                                                        </label>
                                                                    </div>
                                                                    <div className="group relative z-0 mb-6 w-full">
                                                                        <label htmlFor="alltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Trek</label>
                                                                        <select id="alltreks" aria-label="alltreks" name="trekName" value={trekIdEdit} onChange={e => handleEditTrekSelect(e)} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                                                            <option value={0}>Select Trek</option>
                                                                            <option value={-1}>All treks</option>
                                                                            {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                                                                        </select>
                                                                    </div>
                                                                {editCoupon.trekIds.length > 0 && <div className="flex flex-wrap mb-6">
                                                                    {editCoupon.trekIds.map((t: any) => <p onClick={() => removeTrekFromEdit(t)} key={t} className="flex items-center p-2 bg-slate-100 rounded-lg m-1 text-xs cursor-pointer hover:shadow">{getTrekName(t)} <XCircleIcon className="h-4 w-4 ml-2" /></p>)}
                                                                </div>}
                                                                    <div className="group relative z-0 mb-6 w-full">
                                                                        <label className="relative sm:col-span-2 inline-flex items-center mb-2 cursor-pointer">
                                                                            <input type="checkbox" defaultChecked={editCoupon.isValid} onChange={e => toggleEditChange(e)} className="sr-only peer" />
                                                                            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Disable this coupon!!</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-4 flex space-x-3 md:mt-6">
                                                                        <a
                                                                            type="button"
                                                                            onClick={(event: any) =>
                                                                                handleEditFormSubmit(event)
                                                                            }
                                                                            className="inline-flex cursor-pointer items-center rounded-lg bg-blue-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                                                                        >
                                                                            Save
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </Modal>
                                                        )}
                                                </Fragment>

                                            ))}
                                    </tbody>
                                </table>
                                {couponsData && couponsData.length === 0 &&
                                    <div>
                                        <span className="flex w-full align-center justify-center">
                                            <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No coupons found</p>
                                        </span>
                                    </div>
                                }
                            </div>
                            {selectedTrekId === 0 && <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />}
                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}