import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface InventoryCreatePayload {
    type: string;
    description: string;
    size: string;
    imageUrl: string;
    color: string;
}

export interface InventoryEditPayload {
  type: string;
  description: string;
  size: string;
  imageUrl: string;
  color: string;
  id: number;
}

export interface ImageUpload {
  imageUrl: string;
}

const getInventoryById = async (inventoryId: number) => {
  const url = '/inventory/' + inventoryId;
  const resp = await apiInstance.get(url);
  return resp.data;
}

const createInventory = async (payload: InventoryCreatePayload) => {
  const url = '/inventory/create';
  return apiInstance.post(url, payload);
}

const editInventory = async (payload: InventoryEditPayload) => {
  const url = '/inventory/create';
  return apiInstance.post(url, payload);
}

const archiveInventory = async (id: number) => {
  const url = '/inventory/archive/' + id;
  const resp = await apiInstance.put(url)
  return resp.data;
}

const useInventoryById = (inventoryId: number) => {
  return useQuery(['inventory', inventoryId], () => getInventoryById(inventoryId));
}

const getAllInventory = async (params: filteringParams) => {
  const url = '/inventory';
  const resp = await apiInstance.get(url, { params });
  return resp.data;
}

const useAllInventory = (params: filteringParams) => {
  return useQuery(['inventory'], () => getAllInventory(params));
}

const uploadImage = async (payload: any, inventoryId: number) => {
  const url = '/inventory/uploadImage/' + inventoryId;
  return apiInstance.post(url, payload);
}

export { getInventoryById, useInventoryById, getAllInventory, useAllInventory, createInventory, editInventory, archiveInventory, uploadImage };

