import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface HolidayPayload {
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  startPoint: string;
  endPoint: string;
  country: string;
  keywords?: string;
  title?: string;
}

export interface EditHolidayPayload {
  id: number;
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  startPoint: string;
  endPoint: string;
  country: string;
  keywords?: string;
  title?: string;
}

export interface FeaturedPayload {
  trekId: number;
  type: string;
}

const getHolidayById = async (holidayId: number) => {
  const url = '/holidays/' + holidayId
  const resp = await apiInstance.get(url)
  return resp.data
}

const getScheduleById = async (holidayId: number) => {
  const url = '/schedules/holiday/' + holidayId
  const resp = await apiInstance.get(url)
  return resp.data
}

const createHoliday = async (payload: HolidayPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const editHoliday = async (payload: EditHolidayPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const useHolidayById = (holidayId: number) => {
  return useQuery(['holiday', holidayId], () => getHolidayById(holidayId))
}

const useScheduleById = (holidayId: number) => {
  return useQuery(['schedule', holidayId], () => getScheduleById(holidayId))
}

const getAllHolidays = async (params?: filteringParams) => {
  const url = '/holidays';
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const useAllHolidays = (params?: filteringParams) => {
  return useQuery(['holidays'], () => getAllHolidays(params))
}

const uploadImage = async (payload: any, holidayId: number) => {
  const url = '/treks/uploadImage/' + holidayId;
  return apiInstance.post(url, payload);
}

const getFeaturedHolidays = async () => {
  const url = '/featured/holiday/active'
  const resp = await apiInstance.get(url)
  return resp.data
}

const getFeaturedHolidaysAll = async (params?: filteringParams) => {
  const url = '/featured/holiday'
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const createFeaturedHoliday = async (payload: FeaturedPayload[]) => {
  const url = '/featured/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data
}

const toggleFeatured = async (id: number) => {
  const url = '/featured/toggle';
  const resp = await apiInstance.post(url, id)
  return resp.data;
}

const archiveHoliday = async (id: number) => {
  const url = '/treks/archive/' + id;
  const resp = await apiInstance.put(url)
  return resp.data;
}

const useFeaturedHolidays = () => {
  return useQuery(['FeaturedHolidaysActive'], () => getFeaturedHolidays())
}

const useFeaturedHolidaysAll = (params?: filteringParams) => {
  return useQuery(['FeaturedHolidays'], () => getFeaturedHolidaysAll(params))
}

export { getHolidayById, useHolidayById, getAllHolidays, useAllHolidays, createHoliday, editHoliday, uploadImage, useScheduleById, useFeaturedHolidays, useFeaturedHolidaysAll, getFeaturedHolidays, toggleFeatured, createFeaturedHoliday, archiveHoliday, getFeaturedHolidaysAll }
