import { useMutation, useQuery } from '@tanstack/react-query'
import apiInstance from '../api-service';
import { filteringParams } from './user';

export interface TrekPayload {
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface EditTrekPayload {
  id: number;
  name: string;
  state: string;
  defaultBookingAmount: number;
  defaultTotalAmount: number;
  location: string;
  duration: number;
  description: string;
  altitude: number;
  bestTime: string;
  pickupPoint: string;
  difficultyLevel: string;
  defaultSlots?: number;
  contactPersonName?: string;
  contactPersonNumber?: string;
  reportingTime?: string;
  reportingLocation?: string;
  type?: string;
  totalTrekDistance?: string;
  sortOrder?: number;
  videoUrl?: string;
  keywords?: string;
  title?: string;
}

export interface FeaturedPayload {
  trekId: number;
  type: string;
}

const getTrekById = async (trekId: number) => {
  const url = '/treks/' + trekId
  const resp = await apiInstance.get(url)
  return resp.data
}

const getScheduleById = async (trekId: number) => {
  const url = '/schedules/trek/' + trekId
  const resp = await apiInstance.get(url)
  return resp.data
}

const createTrek = async (payload: TrekPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const editTrek = async (payload: EditTrekPayload) => {
  const url = '/treks/create'
  return apiInstance.post(url, payload)
}

const useTrekById = (trekId: number) => {
  return useQuery(['trek', trekId], () => getTrekById(trekId))
}

const useScheduleById = (trekId: number) => {
  return useQuery(['schedule', trekId], () => getScheduleById(trekId))
}

const getAllTreks = async (params?: filteringParams) => {
  const url = '/treks';
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const useAllTreks = (params?: filteringParams) => {
  return useQuery(['treks'], () => getAllTreks(params))
}

const uploadImage = async (payload: any, trekId: number) => {
  const url = '/treks/uploadImage/' + trekId;
  return apiInstance.post(url, payload);
}

const getFeaturedTreks = async () => {
  const url = '/featured/active'
  const resp = await apiInstance.get(url)
  return resp.data
}

const getFeaturedTreksAll = async (params?: filteringParams) => {
  const url = '/featured'
  const resp = await apiInstance.get(url, { params })
  return resp.data
}

const createFeaturedTrek = async (payload: FeaturedPayload[]) => {
  const url = '/featured/create'
  const resp = await apiInstance.post(url, payload)
  return resp.data
}

const toggleFeatured = async (id: number) => {
  const url = '/featured/toggle';
  const resp = await apiInstance.post(url, id)
  return resp.data;
}

const archiveTrek = async (id: number) => {
  const url = '/treks/archive/' + id;
  const resp = await apiInstance.put(url)
  return resp.data;
}

const useFeaturedTreks = () => {
  return useQuery(['FeaturedTreksActive'], () => getFeaturedTreks())
}

const useFeaturedTreksAll = (params?: filteringParams) => {
  return useQuery(['FeaturedTreks'], () => getFeaturedTreksAll(params))
}

export { getTrekById, useTrekById, getAllTreks, useAllTreks, createTrek, editTrek, uploadImage, useScheduleById, useFeaturedTreks, useFeaturedTreksAll, getFeaturedTreks, toggleFeatured, createFeaturedTrek, archiveTrek, getFeaturedTreksAll }
