import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditTrekPayload } from '../hooks/trek';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';
import { locationPricesPayload, createLocationPrice } from '../hooks/locationPrices';

const defaultFormValues = {
  id: 0,
  name: '',
  priceDifferenceTotal: 0,
  priceDifferenceBooking: 0,
  trekId: 0
}

export default function EditLocationPrices({ open, setOpen, allTreks, locationData }: { open: boolean, setOpen: (arg: boolean) => void, allTreks: EditTrekPayload[], locationData: locationPricesPayload }) {
  const queryClient = useQueryClient();
  const appLoaderContext = useContext(AppLoaderContext);
  const notificationContext = useContext(NotificationContext);

  const [formError, setFormError] = useState<boolean>(false)
  const [formErrorMessage, setFormErrorMessage] = useState<string>('')
  const [locationForm, setLocationForm] = useState<locationPricesPayload>({
    id: locationData?.id,
    name: locationData?.name,
    priceDifferenceTotal: locationData?.priceDifferenceTotal,
    priceDifferenceBooking: locationData?.priceDifferenceBooking,
    trekId: locationData?.trekId
  })

  useEffect(() => {
    setLocationForm(locationData);
  }, [locationData]);

  const createLocationPriceMutation = useMutation({
    mutationFn: (payload: locationPricesPayload) => {
      appLoaderContext?.dispatch({
        type: "SHOW"
      })
      return createLocationPrice(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['LocationPrices'])
      setLocationForm(defaultFormValues)
      setOpen(false)
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "SUCCESSFUL",
          notificationTitle: "Location added!",
          notificationMessage: "The location for trek was added."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    },
    onError: () => {
      notificationContext?.dispatch({
        type: "SHOW",
        payload: {
          notificationType: "ERROR",
          notificationTitle: "Oh no!",
          notificationMessage: "There was an error while adding the location for the trek."
        }
      })
      appLoaderContext?.dispatch({
        type: "HIDE"
      })
    }
  })

  const handleFormChange = ({ key, value }: { key: string, value: string }) => {
    if (["priceDifferenceTotal", "priceDifferenceBooking", "trekId"].includes(key)) {
      setLocationForm({ ...locationForm, [key]: parseInt(value) })
    } else {
      setLocationForm({ ...locationForm, [key]: value })
    }
    if (formError) {
      setFormError(false);
      setFormErrorMessage('');
    }
  }

  const handleSubmit = async () => {
    createLocationPriceMutation.mutate({ ...locationForm })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">New Location Price</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Fill out the info to add a location and the price difference it makes to a trek.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                                Location name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text" name="name" id="name"
                                  value={locationForm?.name}
                                  placeholder="Location Name"
                                  onChange={(evt) => handleFormChange({ key: 'name', value: evt.target.value })}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                Trek
                              </label>
                              <select
                                id="location"
                                name="location"
                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                defaultValue={locationForm['trekId']}
                                onChange={(evt) => handleFormChange({ key: 'trekId', value: evt.target.value })}
                              >
                                <option disabled value={0}>Select</option>
                                {allTreks?.map(trek => <option key={trek.id} value={trek.id}>{trek.name}</option>)}
                              </select>
                            </div>
                            <div>
                              <label htmlFor="totalAmount" className="block text-sm font-medium text-gray-700">
                                Price difference (total)
                              </label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">₹</span>
                                </div>
                                <input
                                  type="number"
                                  name="totalAmount"
                                  id="totalAmount"
                                  className="block w-full rounded-md border-gray-300 px-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={locationForm?.priceDifferenceTotal}
                                  onChange={(evt) => handleFormChange({ key: 'priceDifferenceTotal', value: (evt.target.value) })}
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="totalAmount" className="block text-sm font-medium text-gray-700">
                                Price difference (booking)
                              </label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">₹</span>
                                </div>
                                <input
                                  type="number"
                                  name="totalAmount"
                                  id="totalAmount"
                                  className="block w-full rounded-md border-gray-300 px-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={locationForm?.priceDifferenceBooking}
                                  onChange={(evt) => handleFormChange({ key: 'priceDifferenceBooking', value: (evt.target.value) })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {formError && <div className='pr-2 text-xs font-medium text-red-700'>
                        {formErrorMessage}
                      </div>}
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => { setLocationForm(defaultFormValues); return setOpen(false) }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmit}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
