import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editTrainer, TrainerEditPayload } from '../hooks/trainers';
import { AppLoaderContext } from '../context/AppLoaderContext';
import { NotificationContext } from '../context/NotificationContext';

export default function EditTrek({ open, setOpen, trainerData}: { trainerData: any, open: boolean, setOpen: (arg: boolean) => void }) {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const notificationContext = useContext(NotificationContext);

    const editTrainerMutation = useMutation({
        mutationFn: (payload: TrainerEditPayload) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return editTrainer(payload)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['trainers']);
            setOpen(false);
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "SUCCESSFUL",
                    notificationTitle: "Trainer edited!",
                    notificationMessage: "The trainer info was edited successfully."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        },
        onError: () => {
            notificationContext?.dispatch({
                type: "SHOW",
                payload: {
                    notificationType: "ERROR",
                    notificationTitle: "Oh no!",
                    notificationMessage: "There was an error while editing the trainer information."
                }
            })
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    });

    useEffect(() => {
        setTrainerForm(trainerData);
    }, [trainerData]);

    const [trainerForm, setTrainerForm] = useState({
            id: trainerData.id,
            firstName: trainerData.firstName,
            lastName: trainerData.lastName,
            certificate: trainerData.certificate,
            bio: trainerData.bio,
            age: trainerData.age,
            imageUrl: trainerData.imageUrl,
            designation: trainerData.designation,
            sortOrder: trainerData.sortOrder,
    });

   
    const handleFormChange = (event: any) => {   
        event.preventDefault();

        let fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;

        const newFormData: any = { ...trainerForm };
        newFormData[fieldName as keyof typeof newFormData] = fieldValue;

        setTrainerForm(newFormData);

    };
  
    const handleEditSubmit = (event: any) => {
        event.preventDefault();

        const editedInventory = {
            id: trainerForm.id,
            firstName: trainerForm.firstName,
            lastName: trainerForm.lastName,
            certificate: trainerForm.certificate,
            bio: trainerForm.bio,
            age: trainerForm.age,
            imageUrl: trainerForm.imageUrl,
            designation: trainerForm.designation,
            sortOrder: trainerForm.sortOrder,
        };

        console.log(editedInventory);
        editTrainerMutation.mutate({ ...editedInventory });
        setOpen(false);
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">         
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                
                                <Dialog.Title className="text-lg font-medium text-white">{trainerForm.firstName + ' ' + trainerForm.lastName}</Dialog.Title>
                                
                                <div className="ml-3 flex h-7 items-center">
                                    <button
                                    type="button"
                                    className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={() => setOpen(false)}
                                    >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                </div>                         
                                <div className="mt-1">
                                <p className="text-sm text-indigo-300">
                                    Edit the information below.
                                </p>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                <div className="space-y-6 pt-6 pb-5">
                                    <div>
                                        <label htmlFor="firstName"
                                            className="block text-sm font-medium text-gray-900">
                                                First Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text" name="firstName" id="firstName"                     
                                                defaultValue={trainerForm?.firstName}
                                                onChange={handleFormChange}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                    <label htmlFor="lastName"
                                        className="block text-sm font-medium text-gray-900">
                                            Last Name
                                    </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="lastName" id="lastName"
                                                                    defaultValue={trainerForm?.lastName}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>                        
                                                        <div>
                                                            <label htmlFor="certificate"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Certificate
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="certificate" id="certificate"
                                                                    defaultValue={trainerForm?.certificate}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="bio"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Bio
                                                            </label>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    id="bio" name="bio" rows={4}
                                                                    defaultValue={trainerForm?.bio}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="age"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Age
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="age" id="age"
                                                                    defaultValue={trainerForm?.age}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="designation"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Designation
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="designation" id="designation"
                                                                    defaultValue={trainerForm?.designation}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="sortOrder"
                                                                   className="block text-sm font-medium text-gray-900">
                                                                Sort order
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text" name="sortOrder" id="sortOrder"
                                                                    defaultValue={trainerForm?.sortOrder}
                                                                    onChange={handleFormChange}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                            type="button"
                                onClick={(event: any) => handleEditSubmit(event)}
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                            </div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>               
                    </div>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
