import { useContext, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Pagination from "../components/Pagination";
import { filteringParams } from "../hooks/socialfeed";
import { useAllLocation, getAllLocations, getLocationsByTrekId, toggleLocation, locationPricesPayload } from "../hooks/locationPrices";
import Modal from "../components/Modal";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useAllTreks } from "../hooks/trek";
import { AppLoaderContext } from "../context/AppLoaderContext";
import CreateLocationPrices from "../components/CreateLocationPrices";
import { Switch } from "@headlessui/react";
import EditLocationPrices from "../components/EditLocationPrices";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function LocationPrices() {
    const queryClient = useQueryClient();
    const appLoaderContext = useContext(AppLoaderContext);
    const [dataCount, setDataCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [selectedTrekId, setSelectedTrekId] = useState(0);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [orderBy, setOrderBy] = useState("id");
    const [orderType, setOrderType] = useState("DESC");
    const [keyword, setKeyword] = useState("");
    const [locationPricesData, setLocationPricesData] = useState([]);
    const [editLocationPrice, setEditLocationPrice] = useState<locationPricesPayload>({
        id: 0,
        name: '',
        priceDifferenceTotal: 0,
        priceDifferenceBooking: 0,
        trekId: 0
    });
    const [openCreateForm, setOpenCreateForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const allUsersMutation = useMutation({
        mutationFn: (payload: filteringParams) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getAllLocations(payload)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setLocationPricesData(data.locationPrices)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const locationPriceTrekMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return getLocationsByTrekId(id)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            setLocationPricesData(data.locationPrices)
            setDataCount(data.total)
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const toggleLocationMutation = useMutation({
        mutationFn: (id: number) => {
            appLoaderContext?.dispatch({
                type: "SHOW"
            })
            return toggleLocation(id)
        },
        onSuccess(data) {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
            queryClient.invalidateQueries(['LocationPrices'])
        },
        onError: () => {
            appLoaderContext?.dispatch({
                type: "HIDE"
            })
        }
    })
    const [modal, setModal] = useState(false)
    const handleTrekPriceMutation = (payload: filteringParams): void => {
        allUsersMutation.mutateAsync(payload)
    }
    const trekData = useAllTreks({
        skip: 0,
        take: 0,
        orderBy: "id",
        orderType: "ASC",
        keyword: ''
    });
    const allTreks = trekData?.data?.treks;

    const [selectedReview, setSelectedReview] = useState<any>();

    const handleClose = () => {
        setModal(false)
    }

    const handleOrderChange = (orderByString: string): void => {
        let newOT = "DESC"
        let newOB = orderByString
        if (orderBy === orderByString) {
            orderType === "ASC" ? setOrderType("DESC") : setOrderType("ASC")
            orderType === "ASC" ? newOT = "DESC" : newOT = "ASC"
        } else {
            setOrderBy(orderByString)
            newOB = orderByString
        }
        handleTrekPriceMutation({
            take,
            skip,
            orderBy: newOB,
            orderType: newOT,
            keyword
        })
    }

    const { data, isLoading, isError, error } = useAllLocation({
        take,
        skip,
        orderBy,
        orderType,
        keyword
    })

    useEffect(() => {
        if (data && selectedTrekId === 0) {
            setLocationPricesData(data.locationPrices)
            setDataCount(data.total)
        }
    }, [data])

    useEffect(() => {
        if (selectedTrekId === 0) {
            handleTrekPriceMutation({
                take,
                skip,
                orderBy,
                orderType,
                keyword
            })
        } else {
            locationPriceTrekMutation.mutateAsync(selectedTrekId)
        }
    }, [selectedTrekId])

    function handlePageChange(page: number): void {
        setSkip(take * (page - 1))
        handleTrekPriceMutation({
            take,
            skip: take * (page - 1),
            orderBy,
            orderType,
            keyword
        })
    }

    const handleEditClick = (event: any, location: locationPricesPayload) => {
        event.preventDefault();
        setOpenEditForm(true);
        const formValues = {
            id: location.id,
            name: location.name,
            priceDifferenceTotal: location.priceDifferenceTotal,
            priceDifferenceBooking: location.priceDifferenceBooking,
            trekId: location.trekId
          };
      
          setEditLocationPrice(formValues);
    };

    function getTrekName(trekId: number): string {
        const trek = allTreks?.find((t: any) => t.id === trekId);
        return trek?.name;
    }

    if (isLoading) {
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    if (isError) {
        console.log(error)
        return (
            <>
                {/* <AdminLayout onChange={handleOnChange}> */}
                <span>Loading...</span>
                {/* </AdminLayout> */}
            </>
        )
    }

    return (
        <>
            <div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Location Prices</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the locations for treks and the price difference they make
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <label htmlFor="alltreks" className="block mb-2 text-sm font-medium text-gray-900 ">Select Trek</label>
                            <select id="alltreks" aria-label="alltreks" name="trekName" onChange={(e: any) => setSelectedTrekId(parseInt(e.target.value))} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 ">
                                <option value={0}>All Treks</option>
                                {allTreks?.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                        <button type="submit" onClick={() => setOpenCreateForm(true)} className="inline-flex rounded items-center px-5 py-2.5 ml-4 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                            Add Location Prices
                        </button>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                <div className="group inline-flex">
                                                    Id
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("id")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "id" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            {selectedTrekId === 0 && <>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Trek Id
                                                        {selectedTrekId === 0 && <span onClick={() => handleOrderChange("trekId")} className={classNames(
                                                            'ml-2 flex-none rounded cursor-pointer',
                                                            orderBy === "trekId" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>
                                                            {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                        </span>}
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <div className="group inline-flex">
                                                        Trek name
                                                    </div>
                                                </th>
                                            </>}
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Location name
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("name")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "name" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Price difference (total)
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("priceDifferenceTotal")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "priceDifferenceTotal" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Price difference (booking)
                                                    {selectedTrekId === 0 && <span onClick={() => handleOrderChange("priceDifferenceBooking")} className={classNames(
                                                        'ml-2 flex-none rounded cursor-pointer',
                                                        orderBy === "priceDifferenceBooking" ? "bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible text-gray-400 group-hover:visible group-focus:visible"
                                                    )}>
                                                        {orderType === "ASC" ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                                                    </span>}
                                                </div>
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                <div className="group inline-flex">
                                                    Active
                                                </div>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">View</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {locationPricesData && locationPricesData.length > 0 && locationPricesData.map((location: any) => (
                                            <tr key={location.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {location.id}
                                                </td>
                                                {selectedTrekId === 0 && <><td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{location.trekId}</td>
                                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{getTrekName(location.trekId)}</td></>}
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{location.name}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{location.priceDifferenceTotal}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{location.priceDifferenceBooking}</td>
                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                                    <Switch
                                                        checked={!location.disabled}
                                                        onChange={(e: any) => toggleLocationMutation.mutateAsync(location.id)}
                                                        className={classNames(
                                                            !location.disabled ? 'bg-indigo-600' : 'bg-gray-200',
                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                                        )}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            className={classNames(
                                                                !location.disabled ? 'translate-x-5' : 'translate-x-0',
                                                                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    !location.disabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                    <path
                                                                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <span
                                                                className={classNames(
                                                                    !location.disabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </Switch>
                                                </td>
                                                <td onClick={(e) => handleEditClick(e, location)} className="cursor-pointer relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-indigo-600 hover:text-indigo-900">
                                                    Edit
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {locationPricesData && locationPricesData.length === 0 && <div>
                                    <span className="flex w-full align-center justify-center">
                                        <p className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">No trek locations found</p>
                                    </span>
                                </div>}
                            </div>
                            <Pagination dataCount={dataCount} activePage={activePage} dataPerPage={take} handlePageChange={handlePageChange} setActivePage={setActivePage} />
                        </div>
                    </div>
                </div>
            </div>
            <CreateLocationPrices open={openCreateForm} setOpen={setOpenCreateForm} allTreks={allTreks}></CreateLocationPrices>
            <EditLocationPrices open={openEditForm} setOpen={setOpenEditForm} allTreks={allTreks} locationData={editLocationPrice}></EditLocationPrices>
        </>
    )
}